import router from '@/router'
import { ElLoading } from 'element-plus'
import { HotService } from '@/utils/const'
import { Router } from 'vue-router'

const Entrys: { [key: string]: any } = {
  micro_client: {
    development: `//localhost:8120/subapp/micro-client/`,
    testing: `//${location.host}/subapp/micro-client/`,
    production: `//${location.host}/subapp/micro-client/`,
    localTest: `//${location.host}/subapp/micro-client/`
  },
  micro_sale: {
    development: `//localhost:8130/subapp/micro-sale/`,
    testing: `//${location.host}/subapp/micro-sale/`,
    production: `//${location.host}/subapp/micro-sale/`,
    localTest: `//${location.host}/subapp/micro-sale/`
  }
}

interface App {
  loader: any
  name: string
  entry: string
  container: string
  activeRule: string
  props: {
    parentRouter: Router
    hotService?: typeof HotService
    loginInfo?: StoreState.ILoginInfo
  }
}

let loadingInstance: any
const loader = (loading: boolean) => {
  if (loading) {
    loadingInstance =
      loadingInstance ||
      ElLoading.service({
        target: '#frame',
        text: '正在加载系统，请稍后...',
        customClass: 'load-sys'
      })
  } else {
    loadingInstance.close()
  }
}

const apps: App[] = [
  /**
   * name: 微应用名称 - 具有唯一性
   * entry: 微应用入口 - 通过该地址加载微应用，这里我们使用 config 配置
   * container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
   * activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
   */
  {
    loader,
    name: 'micro_client_app',
    entry: `${Entrys['micro_client'][window.env as string]}`,
    container: '#frame',
    activeRule: '/client',
    props: {
      parentRouter: router,
      hotService: HotService
    }
  }, // 客户端微应用
  {
    loader,
    name: 'micro_sale_app',
    entry: `${Entrys['micro_sale'][window.env as string]}`,
    container: '#frame',
    activeRule: '/sale',
    props: {
      parentRouter: router
    }
  } // 销售端微应用
]

export default apps
