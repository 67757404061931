export default {
  zh: {
    text1: '通过科技',
    text2: '为客户提供具有',
    text3: '“性价比”',
    text4: '的翻译服务',
    text5: '质量稳定',
    text6: '价格低',
    text7: '响应及时',
    text8: '百度、技转创投、界石、德商、赛创、新译等投资',
    text9: '入选腾讯AI加速器',
    text10: '2017年7月',
    text11: '入选百度AI加速器',
    text12: '2018年8月',
    text13: '入选普华永道新经济加速器',
    text14: '2019年12月',
    text15: '翻译服务用途',
    text16: '根据翻译用途提供经济有效的服务',
    text17: '自己阅读',
    text18: '适用于日常沟通写作、个人阅读理解、普通参考资料等',
    text19: '可读性',
    text20: '元/千字起',
    text21: '立即下单',
    text22: '商务场合',
    text23: '适用于商务领域、正式场合、招投标，例：技术文档、标书、产品手册、出国留学/移民材料、各类简介等',
    text24: '准确性',
    text25: '专业性',
    text26: '专业领域应用',
    text27: '适用于专业领域、重要场合。例：机械工程、电力工程、法律合同、土建工程、铁路建设、水利工程等',
    text28: '流畅性',
    text29: '医药注册、申报、标准',
    text30: '例：医学翻译、医药翻译、医疗器械翻译、注册申报资料翻译、GMP认证材料翻译、产品说明书翻译、医学及医药相关文献翻译',
    text31: '地道性',
    text32: '产品UI/营销/专利/发表',
    text33: '例：软件UI、游戏UI、网站UI、APP、小程序、发表专利、专著等',
    text34: '软件UI',
    text35: '游戏UI',
    text36: '专利专著',
    text37: '翻译服务范围',
    text38: '支持65种语言，多领域人工专业翻译',
    text39: '如何提供具有性价比的翻译？',
    text40: '科技，创造不同',
    text41: '成本低',
    text42: '实现从客户下单到交付全翻译生产链条自动化；',
    text43: '自动创建项目，根据项目要求自动精准匹配合适译员，自动分配审校、排版，降低人力成本',
    text44: '质量保障',
    text45: '自研翻译平台提供机翻检测、低错检查、语言质量保证等工具，辅助译员完成质量检测',
    text46: '服务体验优',
    text47: '翻译生产流程全透明；线上下单及获取译文，随时跟踪翻译进度',
    text48: '译员多',
    text49: '平台有严格的译员筛选管理机制，积累了3000+高质量的活跃译员',
    text50: '速度快',
    text51: '1000字仅需6小时',
    text52: '人力成本高',
    text53: '完全依赖人工；',
    text54: '人工项目分析->人工分配翻译->人工协调审校->人工协调排版->人工安排质量检测->交付',
    text55: '深度依赖人工的专业能力及工作态度',
    text56: '依赖销售反馈进度，等待耗时长，焦虑',
    text57: '100人',
    text58: '1000字需48小时',
    text59: '软件本地化',
    text60: '网站本地化、UI、帮助文档、市场营销文案',
    text61: '工程',
    text62: '土木、水利、建筑、管道、化工、标书',
    text63: '计算机',
    text64: '网络、信息安全、物联网、人工智能、云计算',
    text65: '其它',
    text66: '战略战术、地形地理、航空航天、装备、核工程',
    text67: '交通运输',
    text68: '公路运输、铁路运输、水路运输、航空运输',
    text69: '医学',
    text70: '卫生保健、基础医学、临床医学、口腔医学、中医、药学、医学技术、护理学、医疗器械',
    text71: '社科人文',
    text72: '文学、教育、管理、历史、心理学、新闻、市场营销',
    text73: '大文娱',
    text74: '游戏、网文、漫画、动漫、影视剧',
    text75: '公共服务',
    text76: '证件翻译、政府文件、景区景点',
    text77: '政治&法律',
    text78: '政治与行政、外交、国际政治、法律、合同',
    text79: '经济金融',
    text80: '经济、财政、税收、贸易、投资、保险',
    text81: '机械&仪器',
    text82: '机械、汽车、仪表、仪器',
    text83: '能源&发电',
    text84: '煤、石油、天然气、电力、新能源',
    text85: '电子&电气',
    text86: '电子、电路、通信、控制、信号、自动化',
    text87: '地质&矿业&冶金',
    text88: '地质、矿业、冶金',
    text89: '纺织&轻工&手工',
    text90: '纺织、服装、包装、印刷、印染、家具、皮革、钟表、食品',
    text91: '环境科学&安全科学',
    text92: 'HSE、污水处理、垃圾处理、节能减排、环评、安全工程',
    text93: '农业',
    text94: '植物保护、农产品、园艺、林业、畜牧、水产、渔业、动物科学',
    text95: '体育',
    text96: '运动场地与设备、体育运动技术、田径、体操、球类运动、武术、水上运动、文体活动',
    text97: '艺术',
    text98: '音乐、舞蹈、戏剧、影视、美术、设计',
    text99: '合作的品牌客户',
    text100: '过去一年，合作过',
    text101: '客户',
    text102: '服务案例',
    text103: '百度',
    text104: '智齿科技',
    text105: '浩欧博',
    text106: '在所有供应商中，优译翻译的速度快，准确无误，流畅自然，符合我们的需求。他们的专业素质和服务态度都让我们非常满意，期待未来继续合作。',
    text107: '响应及时',
    text108: '译文质量好',
    text109: '性价比高',
    text110: '服务态度好',
    text111: '案例研究',
    text112:
      '之前跟其他翻译公司合作，他们对本地化完全不懂，跟优译合作后，优译会提供各种本地化的需求表，我们按照表来提交待翻译内容，翻译准确、专业且高效，让我们也学到了很多本地化方面的知识。',
    text113: '翻译准确',
    text114: '专业高效',
    text115: '交稿速度快',
    text116: '服务态度好',
    text117:
      '专业性和响应性都非常好，其强大的多语言处理能力让我们受益匪浅，除了传统的文档翻译支持，还可与我们的内部系统进行技术对接，实现待翻译内容的及时获取和交付，为我们提供了极大的便利。',
    text118: '翻译专业',
    text119: '很地道',
    text120: '性价比高',
    text121: '服务态度好',
    text122: '精选翻译专家',
    text123: '汇集各语种多领域翻译专家，为您提供专业准确的翻译服务！',
    text124: '荣誉资质',
    text125: '优译网是行业的基础建设平台，赋能整个行业',
    text126: '咨询服务',
    text127: '关于我们',
    text128: '公司简介',
    text129: '发展历程',
    text130: '企业文化',
    text131: '企业荣誉',
    text132: '联系我们',
    text133: '点击咨询客服',
    text134: '电话',
    text135: '手机',
    text136: '微信扫一扫咨询',
    text137: '新媒体运营',
    text138: '印尼语',
    text139: '印度尼西亚中国华裔，',
    text140:
      '母语为印尼语，现定居中国，本科就读于苏州大学汉语语言文学专业，浙江大学对外汉语专业硕士，毕业至今为传神语联翻译公司、语言桥、译国译民等多家翻译公司提供翻译服务，主要领域为新媒体和运营。',
    text141: '中国医学科学院',
    text142: '英语',
    text143: '四川大学华西医学院',
    text144:
      '毕业，现于复旦大学上海医学院任教，主要研究方向为医药生物技术，熟悉药品生产检验流程和医药信息，在《柳叶刀》和国外期刊发表多篇医学英语文章，翻译过临床试验文件、药品说明、诊断研究报告等。',
    text145: '外贸商务',
    text146: '日语N1',
    text147: '公派日本留学，',
    text148:
      '毕业后在日本三菱商事株式会社从事外贸工作，后修改为日本国籍，有日语一级证书，曾与日本伊藤忠商事株式会社、丸红株式会社、三井物产株式会社交流合作，主要翻译领域为中日外贸翻译。',
    text149: '字幕翻译',
    text150: '泰语',
    text151:
      '一直从事字幕、新闻、网站的翻译工作，曾为人人影视字幕组、BTS字幕组、FST字幕组、天府泰剧字幕组、THE字幕组成员，参与翻译泰国版“香蜜沉沉烬如霜”、“长安十二时辰”、“漂亮的李慧珍”等影视字幕。',
    text152: '经济金融',
    text153: '法语',
    text154: '法国巴黎政治大学应用经济学本科',
    text155:
      '毕业，天津财经大学应用经济学硕士毕业，对外经济贸易大学经济贸易学博士毕业，留学期间学习汉语课程，通过汉语水平考试六级，现就职于法国证券交易所，主要从事经济金融相关的中法翻译。',
    text156: '法律翻译',
    text157: '阿拉伯语',
    text158: '埃及开罗大学法学',
    text159:
      '学士，中国政法大学法学硕士，曾于开罗大学孔子学院和北京外国语大学学习中文，通过汉语水平考试六级，现就职于埃及律师事务所，为公司和工厂提供法律援助和法律翻译服务，有8年法律翻译经验。',
    text160: '工程机械',
    text161: '俄语母语',
    text162: '俄罗斯国籍',
    text163:
      '译员，莫斯科国立大学汉语语言文学本科毕业，武汉大学土木工程专业硕士毕业，2006年开始从事翻译行业，至今为翻译局、学校、公司、出版社提供翻译服务，主要翻译领域为工程机械。',
    text164: '漫画翻译',
    text165: '韩语TOPIK6级',
    text166: '本科就读于中国传媒大学动画设计',
    text167:
      '专业，硕士就读于韩国首尔大学韩语专业，通过韩语CATTI二级笔译，韩语TOPIK6级，韩国留学期间在DR Movie、Daewon Media、AKOM等动画公司从事漫画设计并兼职漫画翻译，二次元爱好者。',
    text168: '企业信用等级证书',
    text169: '质量管理体系认证证书',
    text170: '知识产权管理体系认证证书',
    text171: '信息技术服务管理体系认证证书',
    text172: '信息安全管理体系认证证书',
    text173: '高新企业证书',
    text174: '成都市翻译协会副会长单位',
    text175: '中国翻译协会翻译服务委员会委员单位',
    text176: '中国翻译协会会员证书',
    text177: '百度AI加速器第三期训练营毕业证书',
    only_customer: '我是您的专属客服',
    free_quote: '扫码免费获取报价',
    good_quote: '免费获取优质报价',
    phone: '咨询热线',
    hot_service1: '热门服务',
    hot_service2: '论文翻译',
    hot_service3: '专注于为学生、学者和专业人士提供高质量的论文翻译服务，熟练掌握各种学科领域的术语和语言风格。我们还提供快速、准确和经济实惠的服务。',
    hot_service4: '简历翻译',
    hot_service5: '无论您是在寻找国际工作机会，还是需要向外企申请职位，我们都能为您提供高质量的简历翻译服务，准确理解和传达您的工作经历、技能和成就。',
    hot_service6: '留学申请材料翻译',
    hot_service7:
      '无论是学术成绩单、推荐信、个人陈述还是其他申请材料，我们都能准确地翻译成目标语言，注重细节，确保准确无误，让您的申请材料更具专业性和说服力。',
    hot_service8: '价格低',
    hot_service9: '“性价比”',
    hot_service10: '通过科技，为客户提供具有',
    hot_service101: '的翻译服务',
    hot_service11: '质量保障',
    hot_service12: '速度快',
    hot_service13: '响应及时',
    hot_service14: '智能化翻译生产流程，',
    hot_service15: '大大简化了翻译流程的管理',
    hot_service16: '相较于传统翻译公司，我们的文件分发、回收和检查完全用机器人替代了项目经理',
    hot_service17: '流程自动化',
    hot_service18: '降低管理成本',
    hot_service19: '降本提效',
    hot_service20: '速度快',
    hot_service21: '自研和训练的垂直机翻引擎，',
    hot_service22: '译文质量能达到外语专业译员水平',
    hot_service23: '通过科技，提供专业的、准确的且高性价比的翻译服务！',
    hot_service24: 'AI翻译',
    hot_service25: '人工审校',
    hot_service26: '人工润色',
    text178: '成都市国资委、界石、德商、赛创、新译等投资',
    text179: '百度、成都市国资委、界石、德商、赛创、新译等投资'
  },
  en: {
    text1: 'Through technology',
    text2: 'Provide customers with',
    text3: 'Cost performance ratio',
    text4: 'Translation services for',
    text5: 'be even quality',
    text6: 'Low price',
    text7: 'Timely response',
    text8: 'Investments from Baidu, Technology to Venture Capital, Jieshi, Deshang, Saichuang, Xinyi, etc',
    text9: 'Selected for Tencent AI Accelerator',
    text10: 'July 2017',
    text11: 'Selected for Baidu AI Accelerator',
    text12: 'August 2018',
    text13: 'Selected for PwC New Economy Accelerator',
    text14: 'December 2019',
    text15: 'Purpose of translation services',
    text16: 'Provide cost-effective services based on translation purposes',
    text17: 'Read and use on your own',
    text18: 'Basically accurate and fluent, able to understand the main idea of the article only by reading the translation, such as news reading',
    text19: 'Readability',
    text20: 'Starting from yuan/thousand words',
    text21: 'Place an order immediately',
    text22: 'Regular customer reading and usage',
    text23:
      'Accurate and fluent, the translation can fully express the original information, without grammar errors, low errors, or omissions of key information. For example, bidding documents, manuals, product manuals',
    text24: 'accuracy',
    text25: 'Professionalism',
    text26: 'Key customer reading and usage',
    text27:
      'The translation can fully express the meaning of the original text and comply with industry expression habits. For example, technical manuals, contracts, product materials, etc. released to the public',
    text28: 'Fluency ',
    text29: 'Medical registration and application',
    text30: 'For example: medical registration application, medical device registration application',
    text31: 'idiomaticity ',
    text32: 'Product UI, marketing, patents, publications',
    text33: 'For example: software UI, game UI, website UI, published patents, monographs',
    text34: 'Software UI',
    text35: 'Game UI',
    text36: 'Patent monograph',
    text37: 'Translation Service Scope',
    text38: 'Supports 65 languages, multi domain manual professional translation',
    text39: 'How to provide cost-effective translation?',
    text40: 'Technology, Creating Differences',
    text41: 'Low cost',
    text42: 'Realize full translation production chain automation from customer order placement to delivery;',
    text43:
      'Automatically create projects, accurately match suitable translators according to project requirements, automatically allocate proofreading and typesetting, and reduce labor costs',
    text44: 'Quality assurance',
    text45:
      'The self-developed translation platform provides tools for low error checking, polishing, and other effects to assist translators in completing quality inspections',
    text46: 'Excellent service experience',
    text47: 'The translation production process is fully transparent; Place orders online and obtain translations, track translation progress at any time',
    text48: 'Many translators',
    text49: 'The platform has a strict translator selection and management mechanism, accumulating over 3000 high-quality active translators',
    text50: 'Fast speed',
    text51: '1000 words in just 6 hours',
    text52: 'High labor costs',
    text53: 'Completely relying on manual labor;',
    text54:
      'Manual project analysis ->Manual allocation of translation ->Manual coordination and proofreading ->Manual coordination and typesetting ->Manual arrangement of quality inspection ->Delivery',
    text55: 'Deep reliance on manual expertise and work attitude',
    text56: 'Relying on sales feedback progress, long waiting time, and anxiety',
    text57: '100 people',
    text58: '1000 words takes 48 hours',
    text59: 'Software Localization ',
    text60: 'Website localization, UI, help documents, marketing copy',
    text61: 'engineering',
    text62: 'Civil engineering, water conservancy, construction, pipeline, chemical engineering, bidding documents',
    text63: 'computer',
    text64: 'Network, Information Security, Internet of Things, Artificial Intelligence, Cloud Computing',
    text65: 'other',
    text66: 'Strategic and tactical, terrain and geography, aerospace, equipment, nuclear engineering',
    text67: 'Transportation',
    text68: 'Road transportation, railway transportation, waterway transportation, air transportation',
    text69: 'medicine',
    text70: 'Healthcare, Basic Medicine, Clinical Medicine, Stomatology, Traditional Chinese Medicine, Pharmacy, Medical Technology, Nursing, Medical Devices',
    text71: 'Social Sciences Humanities',
    text72: 'Literature, Education, Management, History, Psychology, Journalism, Marketing',
    text73: 'Da Wen Yu',
    text74: 'Games, online articles, comics, anime, film and television dramas',
    text75: 'public service',
    text76: 'Document translation, government documents, scenic spots and attractions',
    text77: 'Politics&Law',
    text78: 'Politics and Administration, Diplomacy, International Politics, Law, Contracts',
    text79: 'Economic and financial',
    text80: 'Economy, Finance, Taxation, Trade, Investment, Insurance',
    text81: 'Machinery&Instruments',
    text82: 'Machinery, automobiles, instruments, instruments',
    text83: 'Energy&Power Generation',
    text84: 'Coal, oil, natural gas, electricity, new energy',
    text85: 'Electronics&Electrical',
    text86: 'Electronics, Circuit, Communication, Control, Signal, Automation',
    text87: 'Geology&Mining&Metallurgy',
    text88: 'Geology, Mining, Metallurgy',
    text89: 'Textile&Light Industry&Handicraft',
    text90: 'Textile, clothing, packaging, printing, dyeing, furniture, leather, watches, food',
    text91: 'Environmental Science&Safety Science',
    text92: 'HSE, sewage treatment, garbage treatment, energy conservation and emission reduction, environmental impact assessment, safety engineering',
    text93: 'agriculture',
    text94: 'Plant protection, agricultural products, horticulture, forestry, animal husbandry, aquaculture, fisheries, animal science',
    text95: 'sports',
    text96: 'Sports venues and equipment, sports techniques, athletics, gymnastics, ball games, martial arts, water sports, cultural and sports activities',
    text97: 'Art',
    text98: 'Music, dance, drama, film and television, art, design',
    text99: 'Cooperative brand customers',
    text100: 'In the past year, we have worked together',
    text101: 'customer',
    text102: 'Service Cases',
    text103: 'Baidu',
    text104: 'Wisdom Tooth Technology',
    text105: 'Haooubo',
    text106:
      "Among all suppliers, Youyi's translation is fast, accurate, fluent, and natural, meeting our needs. We are very satisfied with their professional quality and service attitude, and look forward to continuing our cooperation in the future.",
    text107: 'Timely response',
    text108: 'Good translation quality',
    text109: 'High cost-effectiveness',
    text110: 'Good service attitude',
    text111: 'case study ',
    text112:
      'Previously, we worked with other translation companies, but they had no understanding of localization. After working with Youyi, Youyi provided various localization requirements tables. We provided translation content according to the table, which was accurate, professional, and efficient, allowing us to learn a lot of localization knowledge.',
    text113: 'Accurate translation',
    text114: 'Professional and efficient',
    text115: 'Fast submission speed',
    text116: 'Good service attitude',
    text117:
      'Both professionalism and responsiveness are excellent, and its powerful multilingual processing capabilities have greatly benefited us. In addition to traditional document translation support, it can also be technically integrated with our internal systems to achieve timely acquisition and delivery of the content to be translated, providing us with great convenience.',
    text118: 'Translation major',
    text119: 'Very authentic',
    text120: 'High cost-effectiveness',
    text121: 'Good service attitude',
    text122: 'Selected Translation Experts',
    text123: 'Gather translation experts from various languages and fields to provide you with professional and accurate translation services!',
    text124: 'Honorary qualifications',
    text125: 'Youyi Network is the infrastructure platform of the industry, empowering the entire industry',
    text126: 'consulting service',
    text127: 'About us',
    text128: 'Company Introduction',
    text129: 'development history',
    text130: 'corporate culture',
    text131: 'Corporate Honors',
    text132: 'contact us',
    text133: 'Click to consult customer service',
    text134: 'telephone',
    text135: 'mobile phone',
    text136: 'WeChat Scan for Consultation',
    text137: 'New Media Operations',
    text138: 'Indonesian',
    text139: 'Indonesian Chinese descent,',
    text140:
      "My mother tongue is Indonesian, and I am currently residing in China. I studied Chinese Language and Literature at Suzhou University and obtained a Master's degree in Chinese as a Foreign Language at Zhejiang University. Since graduation, I have provided translation services to multiple translation companies such as Chuanshen Yulian Translation Company, Language Bridge, and Translate to China. My main areas are new media and operations.",
    text141: 'Chinese Academy of Medical Sciences',
    text142: 'English',
    text143: 'West China Medical College of Sichuan University',
    text144:
      'Graduated, currently teaching at Shanghai Medical College, Fudan University. My main research direction is pharmaceutical biotechnology, familiar with drug production and inspection processes and medical information. I have published multiple medical English articles in the Lancet and foreign journals, translated clinical trial documents, drug instructions, diagnostic research reports, etc.',
    text145: 'Foreign trade and commerce',
    text146: 'Japanese N1',
    text147: 'Sending Japanese students to study abroad,',
    text148:
      'After graduation, I worked in foreign trade at Mitsubishi Corporation in Japan, and later changed my nationality to Japanese. I have obtained a Japanese Level 1 certificate and have had exchanges and cooperation with Itochu Corporation, Marubeni Corporation, and Mitsui Corporation. My main translation field is Sino Japanese foreign trade translation.',
    text149: 'subtitle translation ',
    text150: 'Thai',
    text151:
      'I have been engaged in the translation of subtitles, news, and websites. I have been a member of the subtitles group of Renren Film and Television, BTS subtitles group, FST subtitles group, Tianfu Thai drama subtitles group, and THE subtitles group, and have participated in the translation of Thai versions of film and television subtitles such as "Fragrant Honey Sinks Like Frost", "Chang\'an Twelve Hours", and "Beautiful Li Huizhen".',
    text152: 'Economic and financial',
    text153: 'French',
    text154: "Bachelor's degree in Applied Economics at the University of Paris, France",
    text155:
      "Graduated with a Master's degree in Applied Economics from Tianjin University of Finance and Economics, and a PhD in Economics and Trade from the University of International Business and Economics. During my study abroad, I studied Chinese and passed the Chinese Proficiency Test Band 6. Currently, I work at the French Stock Exchange, mainly engaged in Sino French translation related to economics and finance.",
    text156: 'legal translation',
    text157: 'Arabic',
    text158: 'Law at Cairo University, Egypt',
    text159:
      "Bachelor's degree, Master's degree in Law from China University of Political Science and Law, studied Chinese at the Confucius Institute at Cairo University and Beijing International Studies University, passed the Chinese Proficiency Test Band 6. Currently working at an Egyptian law firm, providing legal aid and legal translation services to companies and factories, with 8 years of legal translation experience.",
    text160: 'construction machinery',
    text161: 'Native Russian language',
    text162: 'Russian nationality',
    text163:
      "Translator, graduated with a Bachelor's degree in Chinese Language and Literature from Moscow State University and a Master's degree in Civil Engineering from Wuhan University. Since 2006, he has been engaged in the translation industry and has provided translation services to translation bureaus, schools, companies, and publishing houses. His main translation field is engineering machinery.",
    text164: 'Manga translation',
    text165: 'Korean TOPIK6 level',
    text166: 'Undergraduate student in Animation Design at Communication University of China',
    text167:
      'Major: Master majored in Korean at Seoul University in South Korea, passed Korean CATTI Level II translation and Korean TOPIK6 level. During his study in South Korea, he was engaged in cartoon design and part-time cartoon translation in DR Movie, Daewon Media, AKOM and other animation companies, and was a anime enthusiast.',
    text168: 'Enterprise Credit Rating Certificate',
    text169: 'Quality Management System Certification Certificate',
    text170: 'Intellectual Property Management System Certification',
    text171: 'Information Technology Service Management System Certification',
    text172: 'Information Security Management System Certification Certificate',
    text173: 'High tech enterprise certificate',
    text174: 'Vice President Unit of Chengdu Translation Association',
    text175: 'Member unit of the Translation Service Committee of the China Translation Association',
    text176: 'Membership Certificate of China Translation Association',
    text177: 'Graduation Certificate of Baidu AI Accelerator Third Training Camp',
    only_customer: 'I am your exclusive customer service',
    free_quote: 'Scan the QR code to obtain a free quote',
    good_quote: 'Get high-quality quotes for free',
    phone: 'Consultation hotline',
    hot_service1: 'Popular services',
    hot_service2: 'Paper translation',
    hot_service3:
      'Focusing on providing high-quality paper translation services for students, scholars, and professionals, proficient in various terminology and language styles in various subject areas. We also provide fast, accurate, and cost-effective services',
    hot_service4: 'Resume translation',
    hot_service5:
      'Whether you are looking for international job opportunities or applying for a position with a foreign company, we can provide high-quality resume translation services to accurately understand and convey your work experience, skills, and achievements.',
    hot_service6: 'Translation of study abroad application materials',
    hot_service7:
      "Whether it's academic transcripts, recommendation letters, personal statements, or other application materials, we can accurately translate them into the target language, pay attention to details, ensure accuracy, and make your application materials more professional and persuasive.",
    hot_service8: 'Low price',
    hot_service9: '“Cost performance”',
    hot_service10: 'Through technology, provide customers with',
    hot_service101: 'Translation service for',
    hot_service11: 'Quality assurance',
    hot_service12: 'Fast',
    hot_service13: 'Timely response',
    hot_service14: 'Intelligent translation production process,',
    hot_service15: 'Significantly simplifies the management of translation processes',
    hot_service16:
      'Compared to traditional translation companies, our document distribution, recycling, and inspection have completely replaced project managers with robots',
    hot_service17: 'Process Automation',
    hot_service18: 'Reduce management costs',
    hot_service19: 'Cost reduction and efficiency improvement',
    hot_service20: 'Fast speed',
    hot_service21: 'Self developed and trained vertical flipping engine,',
    hot_service22: 'The translation quality can reach the level of a foreign language professional translator',
    hot_service23: 'Through technology, we provide professional, accurate, and cost-effective translation services!',
    hot_service24: 'AI translation',
    hot_service25: 'Manual proofreading',
    hot_service26: 'Manual polishing',
    text178: 'Investment from Chengdu State owned Assets Supervision and Administration Commission, Jieshi, Deshang, Saichuang, Xinyi, etc',
    text179: 'Investment from Baidu, Chengdu State owned Assets Supervision and Administration Commission, Jieshi, Deshang, Saichuang, Xinyi, etc'
  },
  ja: {
    text1: 'テクノロジーを通じて',
    text2: 'お客様に所有権を提供',
    text3: '「性価比」',
    text4: 'の翻訳サービス',
    text5: '品質が安定している',
    text6: '低価格',
    text7: 'タイムリーに対応',
    text8: '百度、技転創投、界石、徳商、賽創、新訳などの投資',
    text9: 'テンセントAIアクセラレータに選出',
    text10: '2017年7月',
    text11: '百度AI加速器に入選',
    text12: '2018年8月',
    text13: '普華永道新経済加速器に入選',
    text14: '2019年12月',
    text15: '翻訳サービスの用途',
    text16: '翻訳用途に応じた経済的で効果的なサービスを提供',
    text17: '自分で読んで使う',
    text18: '基本的に正確で、流暢で、訳文を読むだけで文章の大意を理解することができて、例えばニュースを読むことができます',
    text19: '読み取り可能性',
    text20: '元/千字から',
    text21: '今すぐ注文',
    text22: '一般的なお客様の読み取り使用方法',
    text23:
      '正確で、流暢で、訳文は原文の情報を全面的に表現することができて、文法の誤り、低い誤りと肝心な情報の漏れがありません。例えば入札書、明細書、製品マニュアル',
    text24: '正確性',
    text25: '専門性',
    text26: '重点顧客の読書使用',
    text27: '訳文は原文の意味を完全に表現することができ、業界の表現習慣に合っている。例えば、対外的に発表された技術マニュアル、契約書、製品資料など',
    text28: 'スムーズ性',
    text29: '医薬登録・届出類',
    text30: '例：医薬登録届出、医療機器登録届出',
    text31: '正真正銘',
    text32: '製品UI、マーケティング、特許、発表類',
    text33: '例：ソフトウェアUI、ゲームUI、WebサイトUI、公開特許、専門書',
    text34: 'ソフトウェアUI',
    text35: 'ゲームUI',
    text36: '専利専著',
    text37: '翻訳サービスの範囲',
    text38: '65言語をサポートし、多分野の人工専門翻訳',
    text39: 'どのようにして性価格比の翻訳を提供しますか。',
    text40: 'テクノロジー、創造の違い',
    text41: '低コスト',
    text42: '顧客の注文から納品までの翻訳生産チェーンの自動化を実現する、',
    text43:
      'プロジェクトを自動的に作成し、プロジェクトの要求に応じて適切な翻訳者を自動的に正確にマッチングさせ、審査、レイアウトを自動的に割り当て、人件費を削減する',
    text44: '品質保障',
    text45: '自己研究翻訳プラットフォームは低誤り検査、潤色などの効果ツールを提供し、翻訳者の品質検査の完成を支援する',
    text46: 'サービス体験のメリット',
    text47: '翻訳生産プロセスは完全に透明である、オンラインで注文し、翻訳文を取得し、翻訳の進行状況を随時追跡する',
    text48: '通訳が多い',
    text49: 'プラットフォームには厳格な翻訳者スクリーニング管理メカニズムがあり、3000+高品質のアクティブな翻訳者を蓄積している',
    text50: '足が速い',
    text51: '1000ワードで6時間',
    text52: '人件費が高い',
    text53: '完全に人工に依存する、',
    text54: '手動プロジェクト分析->手動割当翻訳->手動調整レビュー->手動調整レイアウト->手動割当品質検査->納入',
    text55: '人工に深く依存する専門能力と仕事態度',
    text56: '販売フィードバックの進捗に依存し、待ち時間が長く、焦り',
    text57: '100人',
    text58: '1000字で48時間',
    text59: 'ソフトウェアのローカライズ',
    text60: 'Webサイトのローカライズ、UI、ヘルプ文書、マーケティング文書',
    text61: 'プロジェクト',
    text62: '土木、水利、建築、配管、化学工業、標識書',
    text63: 'コンピュータ',
    text64: 'ネットワーク、情報セキュリティ、IoT、人工知能、クラウドコンピューティング',
    text65: 'その他',
    text66: '戦略戦術、地形地理、航空宇宙、装備、核プロジェクト',
    text67: 'こうつうゆそう',
    text68: '道路輸送、鉄道輸送、水路輸送、航空輸送',
    text69: '医学',
    text70: '衛生保健、基礎医学、臨床医学、口腔医学、漢方医、薬学、医学技術、看護学、医療機器',
    text71: '社会科学人文',
    text72: '文学、教育、管理、歴史、心理学、ニュース、マーケティング',
    text73: '大娯楽',
    text74: 'ゲーム、ネットテキスト、漫画、アニメ、映画・ドラマ',
    text75: '公共サービス',
    text76: '証明書翻訳、政府文書、観光地の観光地',
    text77: '政治&法律',
    text78: '政治と行政、外交、国際政治、法律、契約',
    text79: 'けいざい金融',
    text80: '経済、財政、税収、貿易、投資、保険',
    text81: '機械&計器',
    text82: '機械、自動車、計器、計器',
    text83: 'エネルギー&発電',
    text84: '石炭、石油、天然ガス、電力、新エネルギー',
    text85: '電子&電気',
    text86: '電子、回路、通信、制御、信号、自動化',
    text87: '地質&鉱業&冶金',
    text88: '地質、鉱業、冶金',
    text89: '紡績&軽工業&手仕事',
    text90: '紡績、服装、包装、印刷、捺染、家具、皮革、時計、食品',
    text91: '環境科学&安全科学',
    text92: 'HSE、汚水処理、ゴミ処理、省エネ・排出削減、環境アセスメント、安全工事',
    text93: '農業',
    text94: '植物保護、農産物、園芸、林業、牧畜、水産、漁業、動物科学',
    text95: 'スポーツ',
    text96: '運動場と設備、スポーツ技術、陸上、体操、球技、武術、水上運動、文体活動',
    text97: 'アート',
    text98: '音楽、ダンス、演劇、映画、美術、デザイン',
    text99: '提携ブランドのお客様',
    text100: '過去1年間、協力してきた',
    text101: 'お客様',
    text102: 'サービス事例',
    text103: '百度',
    text104: '親知らず科学技術',
    text105: '浩欧博',
    text106:
      'すべてのベンダーの中で、優訳翻訳の速度は速く、正確で間違いなく、スムーズで自然で、私たちのニーズに合っています。彼らの専門的な素質とサービス態度は私たちを非常に満足させ、将来の協力を期待しています。',
    text107: 'タイムリーに対応',
    text108: '訳文の質がよい',
    text109: 'せいかひが高い',
    text110: 'サービスがよい',
    text111: 'ケーススタディ',
    text112: 'これまで他の翻訳会社と協力してきましたが、彼らはローカライズについて全く理解していませんでした。',
    text113: '翻訳が正確である',
    text114: '専門的で効率的',
    text115: '入稿速度が速い',
    text116: 'サービスがよい',
    text117:
      '専門性と応答性に優れ、強力な多言語処理能力により、従来のドキュメント翻訳サポートに加え、社内システムとの技術的な連携、翻訳対象コンテンツのタイムリーな入手と配信を可能にし、利便性を提供しています。',
    text118: '翻訳専攻',
    text119: '生粋である',
    text120: 'せいかひが高い',
    text121: 'サービスがよい',
    text122: '翻訳のエキスパートを厳選',
    text123: '各言語の多分野翻訳専門家を集めて、専門的で正確な翻訳サービスを提供します！',
    text124: '名誉資質',
    text125: '優訳網は業界の基礎建設プラットフォームであり、業界全体にエネルギーを供給する',
    text126: 'コンサルティングサービス',
    text127: '私たちについて',
    text128: '会社概要',
    text129: '発展過程',
    text130: '企業文化',
    text131: '企業の名誉',
    text132: '連絡先',
    text133: 'クリックしてカスタマーサービスに問い合わせる',
    text134: '電話番号',
    text135: '携帯電話',
    text136: '微信スキャンコンサルティング',
    text137: '新しいメディア運営',
    text138: 'インドネシア語',
    text139: 'インドネシア中国系、',
    text140:
      '母国語はインドネシア語で、現在は中国に定住しており、本科は蘇州大学中国語言語文学科、浙江大学対外中国語専攻修士、卒業してから現在までに伝神語聯翻訳会社、言語橋、翻訳国訳民など多くの翻訳会社に翻訳サービスを提供しており、主な分野は新メディアと運営である。',
    text141: '中国医学科学院',
    text142: '英語',
    text143: '四川大学華西医学院',
    text144:
      '卒業、現在復旦大学上海医学院で教鞭を執っており、主な研究方向は医薬生物技術であり、薬品生産検査プロセスと医薬情報を熟知しており、『柳葉刀』と国外の定期刊行物に複数の医学英語文章を発表し、臨床試験文書、薬品説明、診断研究報告などを翻訳したことがある。',
    text145: '対外貿易ビジネス',
    text146: '日本語N 1',
    text147: '公派日本留学、',
    text148:
      '卒業後、日本の三菱商事株式会社で対外貿易の仕事に従事し、後に日本国籍に改正し、日本語一級証明書があり、日本の伊藤忠商事株式会社、丸紅株式会社、三井物産株式会社と交流協力したことがあり、主な翻訳分野は中日対外貿易翻訳である。',
    text149: '字幕翻訳',
    text150: 'タイ語',
    text151:
      '字幕、ニュース、ウェブサイトの翻訳の仕事をしてきた。かつては誰もが映画・テレビの字幕グループ、BTS字幕グループ、FST字幕グループ、天府泰劇字幕グループ、THE字幕グループのメンバーで、タイ版「香蜜沈殿灰如霜」、「長安十二時」、「きれいな李慧珍」などの映画・テレビの字幕の翻訳に参加した。',
    text152: 'けいざい金融',
    text153: 'フランス語',
    text154: 'フランス・パリ政治大学応用経済学学部',
    text155:
      '卒業、天津財経大学応用経済学修士卒業、対外経済貿易大学経済貿易学博士卒業、留学中に中国語課程を学び、中国語レベル試験6級に合格し、現在はフランス証券取引所に就職し、主に経済金融関連の中国語翻訳に従事している。',
    text156: '法律翻訳',
    text157: 'アラビア語',
    text158: 'エジプトカイロ大学法学',
    text159:
      '学士、中国政法大学法学修士、カイロ大学孔子学院と北京外国語大学で中国語を学び、中国語レベル試験6級に合格し、現在エジプトの弁護士事務所に就職し、会社と工場に法律援助と法律翻訳サービスを提供し、8年間の法律翻訳経験がある。',
    text160: 'エンジニアリングマシン',
    text161: 'ロシア語母語',
    text162: 'ロシア国籍',
    text163:
      '翻訳者、モスクワ国立大学中国語言語文学学部卒業、武漢大学土木工学専攻修士卒業、2006年から翻訳業に従事し、現在まで翻訳局、学校、会社、出版社に翻訳サービスを提供しており、主な翻訳分野は工学機械である。',
    text164: '漫画の翻訳',
    text165: '韓国語TOPIK 6級',
    text166: '本科は中国メディア大学のアニメーションデザインを専攻している',
    text167:
      '専門、修士は韓国ソウル大学韓国語学科に進学し、韓国語CATTI二級翻訳、韓国語TOPIK 6級を通じて、韓国留学中にDR Movie、Daewon Media、AKOMなどのアニメ会社で漫画のデザインと兼職漫画翻訳、二次元愛好家。',
    text168: '企業信用等級証明書',
    text169: '品質管理システム認証証明書',
    text170: '知的財産権管理システム認証証明書',
    text171: '情報技術サービス管理システム認証証明書',
    text172: '情報セキュリティ管理システム認証証明書',
    text173: 'ハイテク企業証書',
    text174: '成都市翻訳協会副会長機関',
    text175: '中国翻訳協会翻訳サービス委員会委員単位',
    text176: '中国翻訳協会会員証明書',
    text177: '百度AI加速器第3期訓練キャンプ卒業証書',
    only_customer: '私はあなたの専属カスタマーサービスです',
    free_quote: 'スキャンコードは無料で見積もりを得る',
    good_quote: '無料で良質な見積もりを得る',
    phone: '相談ホットライン',
    hot_service1: '人気サービス',
    hot_service2: '論文翻訳',
    hot_service3:
      '学生、学者、専門家に質の高い論文翻訳サービスを提供し、さまざまな学科分野の用語と言語スタイルを熟練させることに集中している。また、迅速で正確で経済的なサービスも提供しています。',
    hot_service4: '履歴書翻訳',
    hot_service5:
      '国際的な仕事の機会を探しているのか、外資系企業に職を申請する必要があるのかにかかわらず、私たちはあなたの職務経歴、技能、成果を正確に理解し、伝えるために、質の高い履歴書翻訳サービスを提供することができます。',
    hot_service6: '留学申請資料翻訳',
    hot_service7:
      '学術成績表、推薦状、個人陳述、その他の申請資料にかかわらず、私たちは目標言語に正確に翻訳し、詳細を重視し、正確さを確保し、あなたの申請資料により専門性と説得力を持たせることができます。',
    hot_service8: '価格が安い',
    hot_service9: '“性価比”',
    hot_service10: 'テクノロジーを通じて、顧客に有を提供する',
    hot_service101: 'の翻訳サービス',
    hot_service11: '品質保障',
    hot_service12: '速度が速い',
    hot_service13: '応答がタイムリー',
    hot_service14: 'インテリジェント化翻訳生産プロセス、',
    hot_service15: '翻訳プロセスの管理を大幅にシンプル化',
    hot_service16: '従来の翻訳会社に比べて、ファイルの配布、回収、検査はプロジェクトマネージャの代わりにロボットを使用しています',
    hot_service17: 'プロセス自動化',
    hot_service18: '管理コストの削減',
    hot_service19: '降本提効',
    hot_service20: '速度が速い',
    hot_service21: '自己研究と訓練の垂直機転覆エンジン、',
    hot_service22: '翻訳文の質は外国語専門の翻訳者のレベルに達することができる',
    hot_service23: 'テクノロジーを通じて、専門的で正確で高価格の翻訳サービスを提供します！',
    hot_service24: 'AI翻訳',
    hot_service25: '手動審査',
    hot_service26: '人工着色',
    text178: '成都市国資委、界石、徳商、賽創、新訳などの投資',
    text179: '百度、成都市国資委、界石、徳商、賽創、新訳などの投資'
  },
  ko: {
    text1: '기술을 통해',
    text2: '고객에게 제공',
    text3: '"성가비"',
    text4: '번역 서비스',
    text5: '품질 안정',
    text6: '가격이 낮다',
    text7: '적시에 대응',
    text8: '바이두, 기술전환창투, 계석, 덕상, 싸이창, 신역 등 투자',
    text9: '텐센트 AI 가속기 선정',
    text10: '2017년 7월',
    text11: '바이두 AI 가속기 선정',
    text12: '2018년 8월',
    text13: '보화영도 신경제 가속기에 입선하다',
    text14: '2019년 12월',
    text15: '번역 서비스',
    text16: '번역 용도에 따른 경제적인 서비스 제공',
    text17: '스스로 읽기 사용',
    text18: '기본적으로 정확하고 원활하며, 번역문만 보면 문장의 대의를 이해할 수 있다. 예를 들면 뉴스 읽기',
    text19: '가독성',
    text20: '원/천자부터',
    text21: '지금 주문',
    text22: '일반 고객 읽기 사용',
    text23:
      '정확하고 원활하며 번역문은 원문의 정보를 전면적으로 표현할 수 있으며 문법 오류, 낮은 오류 및 핵심 정보의 누락이 없다.예를 들어 입찰서, 설명서, 제품 설명서',
    text24: '정확성',
    text25: '전문성',
    text26: '주요 고객 읽기 및 사용',
    text27: '번역문은 원문의 뜻을 완전히 표현할 수 있을 뿐만 아니라 업계의 표현 습관에도 부합된다.예를 들어 대외적으로 발표한 기술 수첩, 계약, 제품 자료 등',
    text28: '유창성',
    text29: '의약등록, 신고류',
    text30: '예: 의약등록신고, 의료기기등록신고',
    text31: '지하성',
    text32: '제품 UI, 마케팅, 특허, 발표 클래스',
    text33: '예: 소프트웨어 UI, 게임 UI, 웹 사이트 UI, 특허 발표, 저서',
    text34: '소프트웨어 UI',
    text35: '게임 UI',
    text36: '특허 전문 저서.',
    text37: '번역 서비스 범위',
    text38: '65개 언어, 다분야 전문 번역 지원',
    text39: '성가비를 갖춘 번역은 어떻게 제공합니까?',
    text40: '기술, 창조 다름',
    text41: '저렴한 비용',
    text42: '고객 주문에서 전체 번역 생산 체인 납품까지 자동화',
    text43: '자동으로 프로젝트를 만들고, 프로젝트 요구에 따라 자동으로 적합한 번역원을 정확하게 일치시키고, 자동으로 심사, 조판을 분배하여 인건비를 낮춘다',
    text44: '품질 보장',
    text45: '자체 연구 번역 플랫폼은 낮은 오류 검사, 윤색 등 효과 도구를 제공하여 번역원이 품질 검사를 완성하도록 보조한다',
    text46: '우수한 서비스 환경',
    text47: '번역 생산 프로세스가 완전히 투명합니다.온라인 주문 및 번역문 수령, 수시로 번역 진도 추적',
    text48: '통역사가 많다',
    text49: '플랫폼은 엄격한 번역자 선별 관리 메커니즘을 가지고 있으며, 3000 + 고품질의 활발한 번역자를 축적했다',
    text50: '속도가 빠르다',
    text51: '1000자에 6시간 소요',
    text52: '높은 인건비',
    text53: '전적으로 인공에 의존한다;',
    text54: '인공 프로젝트 분석 -> 인공 분배 번역 -> 인공 협조 심사 -> 인공 협조 조판 -> 인공 배정 품질 검측 -> 교부',
    text55: '수공에 깊이 의존하는 전문 능력과 업무 태도',
    text56: '영업 피드백 진행률에 의존, 긴 대기 시간, 불안',
    text57: '100명',
    text58: '1000자는 48시간 소요',
    text59: '소프트웨어 현지화',
    text60: '사이트 현지화, UI, 도움말 문서, 마케팅 문서',
    text61: '엔지니어링',
    text62: '토목, 수리, 건축, 파이프, 화학공업, 입찰서',
    text63: '컴퓨터',
    text64: '네트워크, 정보 보안, 사물인터넷, 인공지능, 클라우드 컴퓨팅',
    text65: '기타',
    text66: '전략전술, 지형지리, 항공우주, 장비, 핵공정',
    text67: '교통 운수',
    text68: '도로 운송, 철도 운송, 수로 운송, 항공 운송',
    text69: '의학',
    text70: '보건, 기초의학, 임상의학, 구강의학, 중의학, 약학, 의학기술, 간호학, 의료기기',
    text71: '사회과학 인문학',
    text72: '문학, 교육, 관리, 역사, 심리학, 뉴스, 마케팅',
    text73: '큰 레크리에이션',
    text74: '게임, 웹드라마, 만화, 애니메이션, 드라마',
    text75: '공공 서비스',
    text76: '증명서 번역, 정부 문서, 관광지',
    text77: '정치 & 법률',
    text78: '정치 및 행정, 외교, 국제 정치, 법률, 계약',
    text79: '경제금융',
    text80: '경제, 재정, 세금, 무역, 투자, 보험',
    text81: '기계&기기',
    text82: '기계, 자동차, 계기, 계기',
    text83: '에너지 & 발전',
    text84: '석탄, 석유, 가스, 전력, 새로운 에너지',
    text85: '전자 & 전기',
    text86: '전자, 회로, 통신, 제어, 신호, 자동화',
    text87: '지질&광업&야금',
    text88: '지질, 광업, 야금',
    text89: '방직&경공&수공',
    text90: '섬유, 의류, 포장, 인쇄, 날염, 가구, 가죽, 시계, 식품',
    text91: '환경 과학 & 안전 과학',
    text92: 'HSE, 하수처리, 쓰레기처리, 에너지절약 및 오염물방출감소, 환경영향평가, 안전공정',
    text93: '농업',
    text94: '식물보호, 농산물, 원예, 임업, 목축, 수산, 어업, 동물과학',
    text95: '스포츠',
    text96: '운동장과 설비, 체육운동기술, 륙상, 체조, 구류운동, 무술, 수상운동, 문체활동',
    text97: '예술',
    text98: '음악, 무용, 연극, 영화, 미술, 디자인',
    text99: '제휴 브랜드 고객',
    text100: '지난 1년 동안 함께 일했죠',
    text101: '고객',
    text102: '서비스 사례',
    text103: '바이두',
    text104: '사랑니 과학기술',
    text105: '호오보',
    text106:
      '모든 공급업체에서 우역번역은 속도가 빠르고 정확하며 유창하고 자연스러우며 우리의 요구에 부합된다.그들의 전문자질과 봉사태도는 모두 우리를 매우 만족시키고 앞으로 계속 협력하기를 기대한다.',
    text107: '적시에 대응',
    text108: '번역문의 질이 좋다.',
    text109: '성비 가 높다',
    text110: '서비스 태도가 좋다',
    text111: '사례 연구',
    text112:
      '이전에 다른 번역회사와 합작했을 때, 그들은 현지화에 대해 전혀 알지 못했다. 우역과 합작한 후, 우역은 각종 현지화 수요표를 제공할 것이다. 우리는 표에 따라 번역할 내용을 제출한다. 번역이 정확하고 전문적이며 효율적이다. 우리로 하여금 많은 현지화 방면의 지식을 배우게 했다.',
    text113: '번역이 정확하다',
    text114: '전문적인 효율성',
    text115: '원고 제출 속도가 빠르다.',
    text116: '서비스 태도가 좋다',
    text117:
      '전문성과 호응성이 모두 매우 좋으며, 강력한 다국어 처리 능력은 우리로 하여금 적지 않은 혜택을 받게 한다. 전통적인 문서 번역 지원 외에도 우리의 내부 시스템과 기술 도킹을 진행할 수 있고, 번역 대기 내용의 적시 획득과 인도를 실현할 수 있어 우리에게 매우 큰 편리를 제공한다.',
    text118: '번역 전공',
    text119: '진짜',
    text120: '성비 가 높다',
    text121: '서비스 태도가 좋다',
    text122: '번역 전문가를 엄선하다.',
    text123: '각 언어의 다양한 분야의 번역 전문가를 모아 전문적이고 정확한 번역 서비스를 제공합니다!',
    text124: '명예 자질',
    text125: '우역망은 업계의 기초 건설 플랫폼으로, 전체 업계에 능력을 부여한다.',
    text126: '컨설팅 서비스',
    text127: '우리에 대해',
    text128: '회사 소개',
    text129: '발전 과정',
    text130: '기업 문화',
    text131: '기업 명예',
    text132: '연락처',
    text133: '고객 서비스에 문의하려면 클릭하십시오.',
    text134: '전화기',
    text135: '핸드폰',
    text136: '위챗 스캔 컨설팅',
    text137: '뉴미디어 운영',
    text138: '인도네시아어',
    text139: '인도네시아 중국계',
    text140:
      '모국어는 인도네시아어로서 현재 중국에 정착하고있으며 본과는 소주대학 한어언어문학전공, 절강대학 대외한어전공석사에 재학중이며 졸업후 지금까지 전신어련번역회사, 언어교, 역국역민 등 여러 번역회사에 번역서비스를 제공하고있으며 주요분야는 뉴미디어와 운영이다.',
    text141: '중국의학과학원',
    text142: '영어',
    text143: '사천대학 화서의학대학',
    text144:
      '졸업, 현재 푸단대학 상하이의학원에서 교편을 잡고 있으며, 주요 연구 방향은 의약생물기술이며, 약품생산검사절차와 의약정보를 숙지하고,"유엽도"와 국외정기간행물에 여러 편의 의학영어문장을 발표하였으며, 임상시험문건, 약품설명, 진단연구보고 등을 번역한 적이 있다.',
    text145: '대외무역상무',
    text146: '일본어 N1',
    text147: '공파 일본 유학,',
    text148:
      '졸업 후 일본 미쓰비시상사주식회사에서 대외무역업무에 종사하다가 일본국적으로 개정되었으며 일본어 1급증서가 있으며 일본 이토충상사주식회사, 완홍주식회사, 미쓰이물산주식회사와 교류합작한 적이 있으며 주요 번역분야는 중일대외무역번역이다.',
    text149: '자막 번역',
    text150: '태국어',
    text151:
      "자막, 뉴스, 사이트의 번역 업무에 종사해 왔으며, 사람마다 영상 자막팀, BTS 자막팀, FST 자막팀, 톈푸타이극 자막팀, THE 자막팀 구성원을 위해 태국판'향밀침침재여상','장안 12시진','예쁜 이혜진'등 영상 자막 번역에 참여했다.",
    text152: '경제금융',
    text153: '프랑스어',
    text154: '프랑스 파리 정치 대학 응용 경제학 본과',
    text155:
      '졸업, 천진재경대학 응용경제학석사졸업, 대외경제무역대학 경제무역학박사졸업, 류학기간에 한어과정을 학습하고 한어수준시험 6급을 통과했으며 현재 프랑스증권거래소에 취직하고있으며 주로 경제금융과 관련된 중법번역에 종사하고있다.',
    text156: '법률 번역',
    text157: '아랍어',
    text158: '이집트 카이로 대학 법학',
    text159:
      '학사, 중국정법대학 법학석사, 까히라대학 공자학원과 북경외국어대학에서 중국어를 배웠으며 한어수준시험 6급을 통과했으며 현재 애급변호사사무소에 취직하여 회사와 공장에 법률원조와 법률번역서비스를 제공하고있으며 8년간의 법률번역경험이 있다.',
    text160: '공정기계',
    text161: '러시아어 모국어',
    text162: '러시아 국적',
    text163:
      '번역원, 모스크바국립대학 한어언어문학본과 졸업, 무한대학 토목공정학과 석사졸업, 2006년부터 번역업종에 종사하기 시작했으며 지금까지 번역국, 학교, 회사, 출판사에 번역서비스를 제공하고있으며 주요번역분야는 공정기계이다.',
    text164: '만화 번역',
    text165: '한국어 TOPIK6급',
    text166: '본과 는 중국 미디어 대학 애니메이션 디자인 에 재학 하고 있다',
    text167:
      '전공, 석사는 한국 서울대학교 한국어학과에 재학 중입니다. 한국어 CATTI 2급 번역, 한국어 TOPIK 6급, 한국 유학 기간 DR Movie, Daewon Media, AKOM 등 애니메이션 회사에서 만화 디자인과 만화 번역을 겸직했습니다. 2차원 애호가입니다.',
    text168: '기업 신용 등급 증서',
    text169: '품질 관리 체계 인증 증서',
    text170: '지적 재산권 관리 체계 인증 증서',
    text171: '정보 기술 서비스 관리 체계 인증 증서',
    text172: '정보 보안 관리 시스템 인증 인증서',
    text173: '하이테크 엔터프라이즈 인증서',
    text174: '성도시번역협회 부회장 단위',
    text175: '중국번역협회 번역서비스위원회 위원단위',
    text176: '중국 번역 협회 회원 증서',
    text177: '바이두 AI 가속기 제3기 훈련소 졸업장',
    only_customer: '나는 당신의 전용 고객센터입니다',
    free_quote: '코드 스캔 무료 견적 얻기',
    good_quote: '양질의 견적 무료 받기',
    phone: '상담 핫라인',
    hot_service1: '핫 서비스',
    hot_service2: '논문번역',
    hot_service3:
      '학생, 학자 및 전문가에게 고품질의 논문 번역 서비스를 제공하는 데 집중하고 다양한 학문 분야의 용어와 언어 스타일을 숙달합니다.또한 신속하고 정확하며 경제적인 서비스를 제공합니다.',
    hot_service4: '이력서 번역',
    hot_service5:
      '당신이 국제 일자리를 찾고 있든 외국 기업에 일자리를 신청해야 하든, 우리는 당신에게 고품질의 이력서 번역 서비스를 제공하여 당신의 업무 경력, 기능과 성과를 정확하게 이해하고 전달할 수 있습니다.',
    hot_service6: '유학 신청 서류 번역',
    hot_service7:
      '학술 성적표, 추천서, 개인 진술서든 다른 신청 서류든 우리는 모두 정확하게 목표 언어로 번역할 수 있고 세부 사항을 중시하며 정확성을 확보하고 당신의 신청 자료를 더욱 전문성과 설득력을 가지게 할 수 있습니다.',
    hot_service8: '가격 낮음',
    hot_service9: '“성가비”',
    hot_service10: '테크놀로지를 통해 고객에게 제공 가능',
    hot_service101: '번역 서비스',
    hot_service11: '품질보장',
    hot_service12: '속도가 빠르다',
    hot_service13: '즉시 응답',
    hot_service14: '지능형 번역 생산 프로세스',
    hot_service15: '번역 프로세스 관리 대폭 간소화',
    hot_service16: '전통적인 번역 회사에 비해 파일 배포, 회수 및 검사는 프로젝트 관리자를 로봇으로 대체했습니다.',
    hot_service17: '프로세스 자동화',
    hot_service18: '관리 비용 절감',
    hot_service19: '밑그림 효과',
    hot_service20: '속도 빠름',
    hot_service21: '자체 연구 및 훈련된 수직 뒤집기 엔진',
    hot_service22: '번역문의 질은 외국어 전문 번역사 수준에 도달할 수 있습니다',
    hot_service23: '과학기술을 통해 전문적이고 정확하며 높은 가격대의 번역 서비스를 제공합니다!',
    hot_service24: 'AI 번역',
    hot_service25: '인공 검정',
    hot_service26: '인공윤색',
    text178: '성도시 국자위, 계석, 덕상, 싸이창, 신역 등 투자',
    text179: '바이두, 청두시 국자위, 계석, 덕상, 싸이창, 신역 등 투자'
  },
  de: {
    text1: 'Durch Technologie',
    text2: 'Kunden mit',
    text3: 'Kosteneffizienzverhältnis',
    text4: 'Übersetzungsdienstleistungen für',
    text5: 'Gleichbleibende Qualität',
    text6: 'Niedriger Preis',
    text7: 'Rechtzeitige Reaktion',
    text8: 'Investitionen von Baidu, Technologie zu Venture Capital, Jieshi, Deshang, Saichuang, Xinyi, etc',
    text9: 'Ausgewählt für Tencent AI Accelerator',
    text10: 'Juli 2017',
    text11: 'Ausgewählt für Baidu AI Accelerator',
    text12: 'August 2018',
    text13: 'Ausgewählt für PwC New Economy Accelerator',
    text14: 'Dezember 2019',
    text15: 'Zweck der Übersetzungsdienstleistungen',
    text16: 'Bereitstellung kostengünstiger Dienstleistungen basierend auf Übersetzungszwecken',
    text17: 'Lesen und selbst verwenden',
    text18:
      'Grundsätzlich genau und fließend, in der Lage, die Hauptidee des Artikels nur durch Lesen der Übersetzung zu verstehen, wie zum Beispiel Nachrichten lesen',
    text19: 'Lesbarkeit',
    text20: 'Ausgehend von Yuan/tausend Wörter',
    text21: 'Sofort bestellen',
    text22: 'Regelmäßige Kundenlektüre und -nutzung',
    text23:
      'Genau und fließend kann die Übersetzung die Originalinformationen vollständig ausdrücken, ohne Grammatikfehler, geringe Fehler oder Auslassungen wichtiger Informationen. Zum Beispiel Ausschreibungsunterlagen, Handbücher, Produkthandbücher',
    text24: 'Genauigkeit',
    text25: 'Professionalität',
    text26: 'Lesen und Nutzen der wichtigsten Kunden',
    text27:
      'Die Übersetzung kann die Bedeutung des Originaltextes vollständig ausdrücken und entspricht den Ausdrucksgewohnheiten der Branche. Zum Beispiel technische Handbücher, Verträge, Produktmaterialien usw. für die Öffentlichkeit freigegeben',
    text28: 'Fließfähigkeit',
    text29: 'Medizinische Registrierung und Anmeldung',
    text30: 'Zum Beispiel: Antrag auf medizinische Registrierung, Antrag auf Registrierung von Medizinprodukten',
    text31: 'Idiomatik',
    text32: 'Produkt UI, Marketing, Patente, Publikationen',
    text33: 'Zum Beispiel: Software UI, Spiel UI, Website UI, veröffentlichte Patente, Monographien',
    text34: 'Software UI',
    text35: 'Spieloberfläche',
    text36: 'Patentmonographie',
    text37: 'Geltungsbereich des Übersetzungsdienstes',
    text38: 'Unterstützt 65 Sprachen, Multi Domain manuelle professionelle Übersetzung',
    text39: 'Wie kann ich eine kostengünstige Übersetzung anbieten?',
    text40: 'Technologie, Unterschiede schaffen',
    text41: 'Geringe Kosten',
    text42: 'Realisieren Sie eine vollständige Automatisierung der Übersetzungsproduktionskette von der Auftragserteilung bis zur Lieferung;',
    text43:
      'Projekte automatisch erstellen, geeignete Übersetzer entsprechend den Projektanforderungen exakt abgleichen, Korrekturlesen und Satz automatisch zuweisen und Arbeitskosten senken',
    text44: 'Qualitätssicherung',
    text45:
      'Die selbst entwickelte Übersetzungsplattform bietet Tools zur Fehlerminimierung, Polieren und anderen Effekten, um Übersetzer bei der Durchführung von Qualitätskontrollen zu unterstützen',
    text46: 'Exzellente Serviceerfahrung',
    text47:
      'Der Übersetzungsprozess ist vollständig transparent; Bestellen Sie online und erhalten Sie Übersetzungen, verfolgen Sie den Übersetzungsfortschritt jederzeit',
    text48: 'Viele Übersetzer',
    text49:
      'Die Plattform verfügt über einen strengen Mechanismus zur Auswahl und Verwaltung von Übersetzern, der über 3000 hochwertige aktive Übersetzer sammelt.',
    text50: 'Schnelle Geschwindigkeit',
    text51: '1000 Wörter in nur sechs Stunden',
    text52: 'Hohe Arbeitskosten',
    text53: 'Vollständig auf manuelle Arbeit angewiesen;',
    text54:
      'Manuelle Projektanalyse ->Manuelle Übersetzung ->Manuelle Koordination und Korrekturlesen ->Manuelle Koordination und Schriftsatz ->Manuelle Anordnung der Qualitätsprüfung ->Lieferung',
    text55: 'Tiefe Abhängigkeit von manueller Expertise und Arbeitseinstellung',
    text56: 'Verlassen Sie sich auf den Fortschritt des Vertriebsfeedbacks, lange Wartezeiten und Angst',
    text57: '100 Personen',
    text58: '1000 Wörter dauern 48 Stunden',
    text59: 'Softwarelokalisierung',
    text60: 'Website-Lokalisierung, UI, Hilfedokumente, Marketing-Kopie',
    text61: 'Engineering',
    text62: 'Tiefbau, Wasserwirtschaft, Bau, Rohrleitung, Chemietechnik, Ausschreibungsunterlagen',
    text63: 'Computer',
    text64: 'Netzwerk, Informationssicherheit, Internet der Dinge, Künstliche Intelligenz, Cloud Computing',
    text65: 'andere',
    text66: 'Strategisch und taktisch, Gelände und Geographie, Luft- und Raumfahrt, Ausrüstung, Nukleartechnik',
    text67: 'Transport',
    text68: 'Straßenverkehr, Eisenbahnverkehr, Schifffahrt, Luftverkehr',
    text69: 'Medizin',
    text70:
      'Gesundheitswesen, Grundmedizin, Klinische Medizin, Stomatologie, Traditionelle Chinesische Medizin, Pharmazie, Medizintechnik, Krankenpflege, Medizinprodukte',
    text71: 'Sozialwissenschaften Geisteswissenschaften',
    text72: 'Literatur, Bildung, Management, Geschichte, Psychologie, Journalismus, Marketing',
    text73: 'Da Wen Yu.',
    text74: 'Spiele, Online-Artikel, Comics, Anime, Film und Fernsehen Dramen',
    text75: 'öffentlicher Dienst',
    text76: 'Übersetzung von Dokumenten, Regierungsdokumente, malerische Orte und Attraktionen',
    text77: 'Politik&Recht',
    text78: 'Politik und Verwaltung, Diplomatie, Internationale Politik, Recht, Verträge',
    text79: 'Wirtschaftliche und finanzielle',
    text80: 'Wirtschaft, Finanzen, Steuern, Handel, Investitionen, Versicherungen',
    text81: 'Maschinen und Instrumente',
    text82: 'Maschinen, Automobile, Instrumente, Instrumente',
    text83: 'Energie- und Stromerzeugung',
    text84: 'Kohle, Öl, Erdgas, Elektrizität, neue Energie',
    text85: 'Elektronik&Elektrik',
    text86: 'Elektronik, Schaltung, Kommunikation, Steuerung, Signal, Automatisierung',
    text87: 'Geologie, Bergbau und Metallurgie',
    text88: 'Geologie, Bergbau, Metallurgie',
    text89: 'Textil&Leichtindustrie&Handwerk',
    text90: 'Textilien, Bekleidung, Verpackung, Druck, Färben, Möbel, Leder, Uhren, Lebensmittel',
    text91: 'Umweltwissenschaft und Sicherheitswissenschaft',
    text92: 'HSE, Abwasserbehandlung, Abfallbehandlung, Energieeinsparung und Emissionsreduktion, Umweltverträglichkeitsprüfung, Sicherheitstechnik',
    text93: 'Landwirtschaft',
    text94: 'Pflanzenschutz, Agrarprodukte, Gartenbau, Forstwirtschaft, Tierhaltung, Aquakultur, Fischerei, Tierwissenschaften',
    text95: 'Sport',
    text96: 'Sportstätten und -geräte, Sporttechniken, Leichtathletik, Gymnastik, Ballspiele, Kampfkunst, Wassersport, Kultur- und Sportaktivitäten',
    text97: 'Kunst',
    text98: 'Musik, Tanz, Schauspiel, Film und Fernsehen, Kunst, Design',
    text99: 'Genossenschaftliche Markenkunden',
    text100: 'Im vergangenen Jahr haben wir zusammen gearbeitet',
    text101: 'Kunde',
    text102: 'Servicefälle',
    text103: 'Baidu',
    text104: 'Wisdom Tooth Technology',
    text105: 'Haooubo',
    text106:
      'Unter allen Lieferanten ist Youyis Übersetzung schnell, präzise, fließend und natürlich und erfüllt unsere Bedürfnisse. Wir sind mit ihrer professionellen Qualität und Service-Einstellung sehr zufrieden und freuen uns auf die Fortsetzung unserer Zusammenarbeit in der Zukunft.',
    text107: 'Rechtzeitige Reaktion',
    text108: 'Gute Übersetzungsqualität',
    text109: 'Hohe Wirtschaftlichkeit',
    text110: 'Gute Serviceeinstellung',
    text111: 'Fallstudie',
    text112:
      'Zuvor arbeiteten wir mit anderen Übersetzungsunternehmen zusammen, aber diese hatten kein Verständnis von Lokalisierung. Nachdem wir mit Youyi gearbeitet hatten, stellte Youyi verschiedene Lokalisierungsanforderungstabellen zur Verfügung. Wir stellten Übersetzungsinhalte entsprechend der Tabelle bereit, die akkurat, professionell und effizient war, so dass wir viel Lokalisierungswissen erlernen konnten.',
    text113: 'Genaue Übersetzung',
    text114: 'Professionell und effizient',
    text115: 'Schnelle Übermittlungsgeschwindigkeit',
    text116: 'Gute Serviceeinstellung',
    text117:
      'Sowohl Professionalität als auch Reaktionsfähigkeit sind hervorragend, und die leistungsstarken mehrsprachigen Verarbeitungsfähigkeiten haben uns sehr geholfen. Neben der traditionellen Unterstützung bei der Übersetzung von Dokumenten kann es auch technisch in unsere internen Systeme integriert werden, um eine rechtzeitige Erfassung und Lieferung der zu übersetzenden Inhalte zu erreichen, was uns einen hohen Komfort bietet.',
    text118: 'Hauptfach Übersetzung',
    text119: 'Sehr authentisch',
    text120: 'Hohe Wirtschaftlichkeit',
    text121: 'Gute Serviceeinstellung',
    text122: 'Ausgewählte Übersetzer',
    text123:
      'Holen Sie Übersetzungsexperten aus verschiedenen Sprachen und Bereichen zusammen, um Ihnen professionelle und genaue Übersetzungsdienste zu bieten!',
    text124: 'Ehrenqualifikationen',
    text125: 'Youyi Network ist die Infrastrukturplattform der Branche, die die gesamte Branche befähigt',
    text126: 'Beratung',
    text127: 'Über uns',
    text128: 'Einführung des Unternehmens',
    text129: 'Entwicklungsgeschichte',
    text130: 'Unternehmenskultur',
    text131: 'Corporate Honors',
    text132: 'Kontaktieren Sie uns',
    text133: 'Klicken Sie hier, um den Kundenservice zu konsultieren',
    text134: 'Telefon',
    text135: 'Handy',
    text136: 'WeChat Scan für Konsultation',
    text137: 'Operationen für neue Medien',
    text138: 'Indonesisch',
    text139: 'indonesische chinesische Abstammung,',
    text140:
      'Meine Muttersprache ist Indonesisch, und ich wohne derzeit in China. Ich studierte Chinesische Sprache und Literatur an der Universität Suzhou und erwarb einen Master in Chinesisch als Fremdsprache an der Zhejiang Universität. Seit meinem Abschluss erbrachte ich Übersetzungsdienstleistungen für mehrere Übersetzungsunternehmen wie Chuanshen Yulian Translation Company, Language Bridge und Translate to China. Meine Hauptbereiche sind neue Medien und Operationen.',
    text141: 'Chinesische Akademie der Medizinischen Wissenschaften',
    text142: 'Englisch',
    text143: 'West China Medical College of Sichuan University',
    text144:
      'Ich habe mehrere medizinische englische Artikel in Lancet und ausländischen Zeitschriften veröffentlicht, übersetzte klinische Studiendokumente, Arzneimittelanweisungen, diagnostische Forschungsberichte, etc.',
    text145: 'Außenhandel und Handel',
    text146: 'Japanisch N1',
    text147: 'Japanische Studenten ins Ausland schicken,',
    text148:
      'Nach meinem Abschluss arbeitete ich im Außenhandel bei Mitsubishi Corporation in Japan und änderte später meine Nationalität auf Japanisch. Ich habe ein japanisches Level 1 Zertifikat erhalten und hatte Austausch und Zusammenarbeit mit Itochu Corporation, Marubeni Corporation und Mitsui Corporation. Mein Hauptübersetzungsbereich ist Chinesisch Japanisch Außenhandel Übersetzung.',
    text149: 'Übersetzung von Untertiteln',
    text150: 'Thailändisch',
    text151:
      'Ich habe mich mit der Übersetzung von Untertiteln, Nachrichten und Websites beschäftigt. Ich war Mitglied der Untertitelgruppe von Renren Film und Fernsehen, BTS Untertitelgruppe, FST Untertitelgruppe, Tianfu Thai Drama Untertitelgruppe und THE Untertitelgruppe und habe an der Übersetzung von thailändischen Versionen von Film- und Fernsehuntertiteln wie "Duftende Honigspülen wie Frost", "Chang\'an Zwölf Stunden" und "Schöne Li Huizhen" teilgenommen.',
    text152: 'Wirtschaftliche und finanzielle',
    text153: 'Französisch',
    text154: 'Bachelor in Angewandte Ökonomie an der Universität Paris, Frankreich',
    text155:
      'Ich habe einen Master-Abschluss in Angewandter Ökonomie von der Tianjin University of Finance and Economics und einen Doktortitel in Wirtschaft und Handel von der Universität für Internationale Wirtschaft und Wirtschaft. Während meines Auslandsstudiums habe ich Chinesisch studiert und das chinesische Proficiency Test Band 6 bestanden. Derzeit arbeite ich an der französischen Börse und beschäftige mich hauptsächlich mit der chinesischen Übersetzung in Bezug auf Wirtschaft und Finanzen.',
    text156: 'juristische Übersetzung',
    text157: 'Arabisch',
    text158: 'Recht an der Universität Kairo, Ägypten',
    text159:
      'Bachelor-Abschluss, Master-Abschluss in Rechtswissenschaften von der Chinesischen Universität für Politikwissenschaft und Recht, studierte Chinesisch am Konfuzius-Institut der Universität Kairo und der Beijing International Studies University, bestanden das Chinese Proficiency Test Band 6. Derzeit arbeitet er in einer ägyptischen Anwaltskanzlei, die Rechtshilfe und juristische Übersetzungsdienstleistungen für Unternehmen und Fabriken bietet, mit 8-jähriger juristischer Übersetzungserfahrung.',
    text160: 'Baumaschinen',
    text161: 'Russische Muttersprache',
    text162: 'Russische Staatsangehörigkeit',
    text163:
      'Übersetzer, mit einem Bachelor-Abschluss in chinesischer Sprache und Literatur von der Staatlichen Universität Moskau und einem Master-Abschluss in Bauingenieurwesen von der Universität Wuhan. Seit 2006 ist er in der Übersetzungsbranche tätig und erbringt Übersetzungsdienstleistungen für Übersetzungsbüros, Schulen, Unternehmen und Verlage. Sein Hauptübersetzungsbereich ist Maschinenbau.',
    text164: 'Manga Übersetzung',
    text165: 'Koreanische TOPIK6-Ebene',
    text166: 'Undergraduate Student in Animation Design an der Communication University of China',
    text167:
      'Hauptfach: Master in Koreanisch an der Seoul University in Südkorea, bestanden Koreanisch CATTI Level II Übersetzung und Koreanisch TOPIK6 Niveau. Während seines Studiums in Südkorea beschäftigte er sich mit Cartoon Design und Teilzeit Cartoon Übersetzung in DR Movie, Daewon Media, AKOM und anderen Animationsfirmen und war ein Anime-Enthusiast.',
    text168: 'Ratingzertifikat für Unternehmen',
    text169: 'Zertifizierung des Qualitätsmanagementsystems',
    text170: 'Zertifizierung des Managementsystems für geistiges Eigentum',
    text171: 'Zertifizierung von Informationstechnologie Service Management System',
    text172: 'Zertifikat zur Zertifizierung von Informationssicherheitsmanagementsystemen',
    text173: 'High-Tech-Unternehmenszertifikat',
    text174: 'Vice President Referat des Chengdu Translation Association',
    text175: 'Mitglied des Übersetzungsdienstkomitees des Chinesischen Übersetzungsbüros',
    text176: 'Mitgliedszertifikat der China Translation Association',
    text177: 'Abschlusszertifikat des dritten Trainingscamps Baidu AI Accelerator',
    only_customer: 'Ich bin Ihr exklusiver Kundenservice',
    free_quote: 'Scannen Sie den QR-Code, um ein kostenloses Angebot zu erhalten',
    good_quote: 'Erhalten Sie qualitativ hochwertige Angebote kostenlos',
    phone: 'Beratungshotline',
    hot_service1: 'Beliebte Dienste',
    hot_service2: 'Papierübersetzung',
    hot_service3:
      'Wir konzentrieren uns auf die Bereitstellung hochwertiger Papierübersetzungsdienstleistungen für Studenten, Wissenschaftler und Fachleute, die mit verschiedenen Terminologien und Sprachstilen in verschiedenen Fachgebieten vertraut sind. Wir bieten auch schnelle, genaue und kostengünstige Dienstleistungen',
    hot_service4: 'Übersetzung fortsetzen',
    hot_service5:
      'Egal, ob Sie nach internationalen Stellenangeboten suchen oder sich für eine Stelle bei einem ausländischen Unternehmen bewerben, wir können qualitativ hochwertige Lebenslaufübersetzungen anbieten, um Ihre Arbeitserfahrung, Fähigkeiten und Leistungen genau zu verstehen und zu vermitteln.',
    hot_service6: 'Übersetzung von Bewerbungsunterlagen für Auslandsstudien',
    hot_service7:
      'Ob akademische Transkripte, Empfehlungsschreiben, persönliche Statements oder andere Bewerbungsunterlagen – wir können sie akkurat in die Zielsprache übersetzen, auf Details achten, Genauigkeit gewährleisten und Ihre Bewerbungsunterlagen professioneller und überzeugender gestalten.',
    hot_service8: 'Niedriger Preis',
    hot_service9: '“Kostenleistung”',
    hot_service10: 'Bieten Sie Kunden durch Technologie',
    hot_service101: 'Übersetzungsdienst für',
    hot_service11: 'Qualitätssicherung',
    hot_service12: 'Fast',
    hot_service13: 'Rechtzeitige Reaktion',
    hot_service14: 'Intelligenter Übersetzungsprozess',
    hot_service15: 'Vereinfacht die Verwaltung von Übersetzungsprozessen erheblich',
    hot_service16:
      'Im Vergleich zu herkömmlichen Übersetzungsunternehmen haben unsere Dokumentenverteilung, -recycling und -inspektion Projektmanager komplett durch Roboter ersetzt.',
    hot_service17: 'Prozessautomatisierung',
    hot_service18: 'Verringerung der Verwaltungskosten',
    hot_service19: 'Kostensenkung und Effizienzsteigerung',
    hot_service20: 'Schnelle Geschwindigkeit',
    hot_service21: 'Selbstentwickelter und geschulter vertikaler Drehmotor,',
    hot_service22: 'Die Übersetzungsqualität kann das Niveau eines professionellen Fremdsprachenübersetzers erreichen',
    hot_service23: 'Durch Technologie bieten wir professionelle, genaue und kostengünstige Übersetzungsdienste!',
    hot_service24: 'KI-Übersetzung',
    hot_service25: 'Manuelles Korrekturlesen',
    hot_service26: 'Manuelles Polieren',
    text178: 'Investition von Chengdu State Assets Supervision and Administration Commission, Jieshi, Deshang, Saichuang, Xinyi, etc.',
    text179: 'Investition von Baidu, Chengdu State Owned Assets Supervision and Administration Commission, Jieshi, Deshang, Saichuang, Xinyi, etc.'
  },
  fr: {
    text1: 'Par la technologie',
    text2: 'Fournir aux clients avec',
    text3: '« rapport qualité / prix »',
    text4: 'Services de traduction',
    text5: 'Qualité stable',
    text6: 'Prix bas',
    text7: 'Réponse en temps opportun',
    text8: 'Baidu, Technological Transfer capital, bornes, German Business, saichung, Nouvelle traduction et autres investissements',
    text9: "Sélectionné pour l'accélérateur Tencent ai",
    text10: 'Juillet 2017',
    text11: 'Sélectionné pour Baidu ai Accelerator',
    text12: 'Août 2018',
    text13: 'Sélectionné pour le PWC New Economy Accelerator',
    text14: 'Décembre 2019',
    text15: 'Utilisation des services de traduction',
    text16: 'Des services rentables selon l’usage de la traduction',
    text17: 'Lisez vous - même pour utiliser',
    text18:
      "Précise et fluide, la traduction exprime pleinement le message original, sans erreurs grammaticales, erreurs mineures et omissions d'informations clés. Par exemple, les offres, les instructions, les manuels de produits",
    text19: 'Lisibilité',
    text20: 'A partir de yuan / mille mots',
    text21: 'Passez votre commande maintenant',
    text22: 'Utilisation de la lecture par les clients ordinaires',
    text23:
      "Précise et fluide, la traduction exprime pleinement le message original, sans erreurs grammaticales, erreurs mineures et omissions d'informations clés. Par exemple, les offres, les instructions, les manuels de produits",
    text24: 'Précision',
    text25: 'Professionnalisme',
    text26: "Clients ciblés lire l'utilisation",
    text27:
      "La traduction exprime parfaitement le sens de l'original et est conforme aux habitudes d'expression de l'industrie. Par exemple, manuels techniques publiés à l'étranger, contrats, informations sur les produits, etc.",
    text28: 'Fluidité',
    text29: 'Enregistrement médical, classe de déclaration',
    text30: "Exemples: Déclaration d'enregistrement d'un médicament, Déclaration d'enregistrement d'un dispositif médical",
    text31: 'Authenticité',
    text32: 'Produits ui, marketing, brevets, catégorie publication',
    text33:
      'Par exemple: interface utilisateur du logiciel, interface utilisateur du jeu, interface utilisateur du site Web, publication de brevets, monographies',
    text34: 'Logiciel UI',
    text35: 'UI du jeu',
    text36: 'Monographies sur les brevets',
    text37: 'Gamme de services de traduction',
    text38: 'Prend en charge 65 langues, traduction professionnelle humaine Multi - domaines',
    text39: 'Comment fournir une traduction rentable?',
    text40: 'La technologie, faire la différence',
    text41: 'Faible coût',
    text42: 'Automatiser la chaîne de production de la traduction complète, de la commande du client à la livraison;',
    text43:
      "Création automatique de projets, correspondance automatique et précise des traducteurs appropriés en fonction des exigences du projet, affectation automatique des réviseurs, typographie, réduction des coûts de main - d'œuvre",
    text44: 'Assurance qualité',
    text45:
      "La plate - forme de traduction auto - étudiée fournit des outils d'effet tels que la vérification à faible erreur, la retouche et d'autres, les traducteurs auxiliaires complètent la détection de la qualité",
    text46: 'Excellente expérience de service',
    text47:
      'Processus de production de traduction entièrement transparent; Passez des commandes et obtenez des traductions en ligne, suivez les progrès de la traduction à tout moment',
    text48: 'Nombreux traducteurs',
    text49:
      "La plate - forme dispose d'un mécanisme de gestion rigoureux de la sélection des traducteurs, accumulant plus de 3 000 traducteurs actifs de haute qualité",
    text50: 'Vitesse rapide',
    text51: '1000 mots en seulement 6 heures',
    text52: 'Coût humain élevé',
    text53: 'Dépendance totale à l’humain;',
    text54:
      'Analyse manuelle du projet - > Traduction manuellement assignée - > révision manuellement coordonnée - > typographie manuellement coordonnée - > contrôle de qualité manuellement arrangé - > Livraison',
    text55: "Capacité professionnelle et attitude de travail profondément dépendantes de l'homme",
    text56: 'Compter sur la progression des commentaires sur les ventes, attendre longtemps, être anxieux',
    text57: '100 personnes',
    text58: '48 heures pour 1000 mots',
    text59: 'Localisation de logiciels',
    text60: "Localisation de site Web, interface utilisateur, documentation d'aide, rédaction Marketing",
    text61: 'Ingénierie',
    text62: 'Civil, hydraulique, construction, Pipeline, chimique, soumission',
    text63: 'Ordinateur',
    text64: "Réseaux, sécurité de l'information, Internet des objets, intelligence artificielle, Cloud Computing",
    text65: 'Autres',
    text66: 'Tactique stratégique, géographie topographique, aérospatiale, équipement, génie nucléaire',
    text67: 'Transports',
    text68: 'Transport routier, transport ferroviaire, transport par voie navigable, transport aérien',
    text69: 'Médecine',
    text70:
      'Soins de santé, médecine de base, médecine clinique, stomatologie, médecine chinoise, pharmacie, technologie médicale, sciences infirmières, dispositifs médicaux',
    text71: 'Sciences humaines',
    text72: 'Littérature, éducation, gestion, histoire, psychologie, journalisme, marketing',
    text73: 'Le Grand divertissement',
    text74: 'Jeux, webtextes, mangas, anime, films et séries TV',
    text75: 'Services publics',
    text76: 'Traduction de documents, documents gouvernementaux, sites touristiques',
    text77: 'Politique & juridique',
    text78: 'Politique et administration, diplomatie, politique internationale, droit, contrats',
    text79: 'Economie Finance',
    text80: 'Économie, finances, impôts, commerce, investissement, assurance',
    text81: 'Machines et instruments',
    text82: 'Mécanique, automobile, instrumentation, instruments',
    text83: "Énergie & production d'électricité",
    text84: 'Charbon, pétrole, gaz, électricité, nouvelles énergies',
    text85: 'Électronique & ÉLECTRIQUE',
    text86: 'Électronique, circuits, communication, contrôle, signalisation, automatisation',
    text87: 'Géologie & mines & métallurgie',
    text88: 'Géologie, mines, métallurgie',
    text89: 'Textile & travail léger & artisanal',
    text90: 'Textile, habillement, emballage, impression, teinture, meubles, cuir, Horlogerie, alimentation',
    text91: "Sciences de l'environnement et de la sécurité",
    text92:
      "HSE, traitement des eaux usées, traitement des déchets, économie d'énergie et réduction des émissions, évaluation circulaire, ingénierie de la sécurité",
    text93: 'Agriculture',
    text94: 'Protection des plantes, produits agricoles, horticulture, foresterie, élevage, aquaculture, pêche, sciences animales',
    text95: 'Sports',
    text96:
      'Terrains et équipements sportifs, techniques sportives, athlétisme, gymnastique, jeux de balle, arts martiaux, sports nautiques, activités stylistiques',
    text97: 'Art',
    text98: 'Musique, danse, théâtre, cinéma et télévision, beaux - arts, Design',
    text99: 'Les marques clientes qui collaborent',
    text100: 'Au cours de la dernière année, nous avons collaboré',
    text101: 'Le client',
    text102: 'Cas de service',
    text103: 'Baidu',
    text104: 'Technologie des dents de sagesse',
    text105: "Hao'Obo",
    text106:
      "Chez tous les fournisseurs, la traduction optimisée est rapide, précise, fluide et naturelle et répond à nos besoins. Leurs qualités professionnelles et leur attitude de service nous ont donné une grande satisfaction et nous attendons avec impatience de continuer à coopérer à l'avenir.",
    text107: 'Réponse en temps opportun',
    text108: 'Bonne qualité de traduction',
    text109: 'Rapport qualité - prix élevé',
    text110: 'Bonne attitude de service',
    text111: 'Études de cas',
    text112:
      "Avant de travailler avec d'autres sociétés de traduction, ils ne comprennent pas du tout la localisation, après la coopération avec l'optimisation, l'optimisation fournira divers tableaux de besoins de localisation, nous suivons le tableau pour présenter le contenu à traduire, la traduction est précise, professionnelle et efficace, nous avons également appris beaucoup de choses sur La localisation.",
    text113: 'Traduction précise',
    text114: 'Professionnel et efficace',
    text115: 'Livraison rapide',
    text116: 'Bonne attitude de service',
    text117:
      "Le professionnalisme et la réactivité sont excellents, et nous bénéficions d'une grande facilité grâce à sa puissance de traitement multilingue, qui, en plus du support traditionnel de traduction de documents, permet l'interfaçage technique avec nos systèmes internes, permettant l'acquisition et la livraison en temps opportun du contenu à traduire.",
    text118: 'Spécialité Traduction',
    text119: 'Très local',
    text120: 'Rapport qualité - prix élevé',
    text121: 'Bonne attitude de service',
    text122: 'Experts en traduction sélectionnés',
    text123: 'Rassemblez des experts en traduction multilingues pour vous offrir des services de traduction professionnels et précis!',
    text124: 'Qualifications honorifiques',
    text125: "Le réseau d'optimisation est la plate - forme d'infrastructure de l'industrie, permettant à l'ensemble de l'industrie",
    text126: 'Services consultatifs',
    text127: 'À propos de nous',
    text128: 'Profil de la société',
    text129: 'Le parcours de développement',
    text130: "Culture d'entreprise",
    text131: "Honneur d'entreprise",
    text132: 'Contactez - nous',
    text133: 'Cliquez sur consulter le service client',
    text134: 'Téléphone',
    text135: 'Téléphone portable',
    text136: 'Wechat balaye la consultation',
    text137: 'Opérations nouveaux médias',
    text138: 'Indonésien',
    text139: "D'origine chinoise indonésienne,",
    text140:
      "La langue maternelle est l'indonésien, maintenant installé en Chine, l'Université a étudié à l'Université de Suzhou avec la langue chinoise et la littérature majeure, l'Université de Zhejiang avec une maîtrise professionnelle en chinois externe, diplômé jusqu'à présent pour diverses entreprises de traduction telles que Missionary Language Union Translation Company, language Bridge, Translator national Translator et d'autres, les principaux domaines sont les nouveaux médias et les opérations.",
    text141: 'Académie chinoise des sciences médicales',
    text142: 'Anglais',
    text143: 'Faculté de médecine Huaxi, Université du Sichuan',
    text144:
      "Diplômé, enseigne maintenant à la faculté de médecine de Shanghai de l'Université Fudan, la direction principale de la recherche est la biotechnologie pharmaceutique, se familiarise avec le processus d'inspection de la production pharmaceutique et l'information pharmaceutique, publie de nombreux articles en anglais médical dans The Lancet et des revues étrangères, a traduit des documents d'essais cliniques, des instructions sur les médicaments, des rapports de recherche de diagnostic, etc.",
    text145: 'Commerce extérieur',
    text146: 'Japonais N1',
    text147: 'Etudier au Japon,',
    text148:
      "Après l'obtention de son diplôme a travaillé dans le commerce extérieur au Japon Mitsubishi Corporation, plus tard modifié à la nationalité japonaise, a un certificat de première classe en japonais, a échangé et coopéré avec ITOCHU Corporation of Japan, Marubeni Corporation, Mitsui Property Corporation, le principal domaine de traduction est la traduction du commerce extérieur Chine - Japon.",
    text149: 'Traduction de sous - titres',
    text150: 'Thaï',
    text151:
      'Toujours engagé dans la traduction de sous - titres, nouvelles, sites Web, a travaillé pour le Groupe de sous - titres de film et de télévision pour tout le monde, Groupe de sous - titres BTS, Groupe de sous - titres FST, Groupe de sous - titres théâtraux de Tianfu, membre du Groupe de sous - titres The, a participé à la traduction de la version thaïlandaise de sous - titres de film et de télévision tels que "Miel d\'encens coule comme du givre", "Chang\'an Twelve time", "Belle Li Hui Zhen".',
    text152: 'Economie Finance',
    text153: 'Français',
    text154: "Université d'économie appliquée, Sciences Po Paris, France",
    text155:
      "Diplômé d'un master en économie appliquée de l'Université de Finance et d'économie de Tianjin, diplômé d'un doctorat en sciences économiques et commerciales de l'Université d'économie et de commerce extérieur, a suivi des cours de chinois pendant ses études à l'étranger, a passé l'examen de maîtrise de la langue chinoise de niveau VI, est maintenant en fonction à la bourse française et travaille principalement dans la traduction chinoise et française liée à l'économie et à la finance.",
    text156: 'Traduction juridique',
    text157: 'Arabe',
    text158: 'Droit, Université du Caire, Égypte',
    text159:
      "Bachelor, LL.M. de l'Université chinoise de sciences politiques et de droit, a étudié le chinois à l'Institut Confucius de l'Université du Caire et à l'Université des langues étrangères de Beijing, a passé le niveau VI de l'examen de compétence en chinois, est maintenant employé dans un cabinet d'avocats égyptien, fournit une assistance juridique et des services de traduction juridique aux entreprises et aux usines, a 8 ans d'expérience en traduction juridique.",
    text160: 'Machines de construction',
    text161: 'Langue maternelle russe',
    text162: 'Nationalité russe',
    text163:
      "Traducteur, diplômé de l'Université de langue et littérature chinoises de l'Université d'État de Moscou, diplômé d'un Master spécialisé en génie civil de l'Université de Wuhan, a commencé à travailler dans l'industrie de la traduction en 2006 et fournit des services de traduction pour les bureaux de traduction, les écoles, les entreprises, les maisons d'édition, le principal domaine de la traduction est les machines de construction.",
    text164: 'Traduction de manga',
    text165: 'Coréen topik6 niveau',
    text166: "Étudiant à l'Université des médias de Chine Animation Design",
    text167:
      "Professional, Master a étudié à l'Université de Séoul en Corée du Sud avec une spécialisation en coréen, par le biais de la traduction CATTI niveau 2 en coréen, niveau topik6 en coréen, pendant les études à l'étranger en Corée du Sud a travaillé dans la conception de manga et la traduction de manga à temps partiel dans Dr movie, daewon Media, akom et d'autres sociétés d'animation, amateurs de dualité.",
    text168: 'Certificat de niveau de crédit pour les entreprises',
    text169: 'Certificat de certification du système de gestion de la qualité',
    text170: 'Certificat de certification du système de gestion de la propriété intellectuelle',
    text171: "Certificat de certification du système de gestion des services de technologie de l'information",
    text172: "Certificat de certification du système de gestion de la sécurité de l'information",
    text173: 'Certificat de haute nouvelle entreprise',
    text174: "Unité du Vice - Président de l'Association des traducteurs de Chengdu",
    text175: "Unité des membres du Comité des services de traduction de l'Association chinoise des traducteurs",
    text176: "Certificat d'adhésion à l'Association chinoise des traducteurs",
    text177: "Certificat de fin d'études du troisième camp d'entraînement de Baidu ai Accelerator",
    only_customer: 'Je suis votre service client exclusif',
    free_quote: 'balayer le Code pour obtenir un devis gratuitement',
    good_quote: 'obtenez un devis de qualité gratuitement',
    phone: 'Consulting hotline',
    hot_service1: 'services populaires',
    hot_service2: 'traduction de thèse',
    hot_service3:
      'se concentre sur la fourniture de services de traduction de thèse de haute qualité pour les étudiants, les universitaires et les professionnels, avec une maîtrise de la terminologie et des styles linguistiques dans divers domaines. Nous offrons également un service rapide, précis et abordable.',
    hot_service4: 'traduction de CV',
    hot_service5:
      "que vous soyez à la recherche d'une offre d'emploi internationale ou que vous ayez besoin de postuler pour un poste à l'étranger, nous pouvons vous fournir un service de traduction de CV de haute qualité qui comprend et communique avec précision votre expérience de travail, vos compétences et vos réalisations.",
    hot_service6: "traduction de matériel de demande d'études à l'étranger",
    hot_service7:
      'qu’il s’agisse de relevés de notes académiques, de lettres de recommandation, de déclarations personnelles ou d’autres documents de candidature, nous traduisons avec précision dans la langue cible, en accordant une attention particulière aux détails, en veillant à ce qu’ils soient exacts et sans erreur, ce qui rend vos documents de candidature plus professionnels et convaincants.',
    hot_service8: 'prix bas',
    hot_service9: '“rapport qualité / prix”',
    hot_service10: 'grâce à la technologie, offrir aux clients',
    hot_service101: 'le Service de traduction de',
    hot_service11: 'assurance qualité',
    hot_service12: 'rapide',
    hot_service13: 'répondre à temps',
    hot_service14: 'processus de production de traduction intelligents',
    hot_service15: 'simplifie considérablement la gestion du processus de traduction ',
    hot_service16:
      'par rapport aux entreprises de traduction traditionnelles, nous avons remplacé les chefs de projet par des robots pour la distribution, le recyclage et l’inspection des documents ',
    hot_service17: 'automatisation des processus ',
    hot_service18: 'réduire les coûts administratifs ',
    hot_service19: 'efficacité réduite ',
    hot_service20: 'rapide ',
    hot_service21: "moteur de retournement vertical pour l'auto - étude et la formation",
    hot_service22: 'la qualité de la traduction est à la hauteur de celle des traducteurs professionnels en langues étrangères ',
    hot_service23: 'grâce à la technologie, des services de traduction professionnels, précis et rentables! ',
    hot_service24: 'traduction ai ',
    hot_service25: 'réviseur humain ',
    hot_service26: 'retouche artificielle',
    text178: 'Chengdu State Capital Commission, borne frontière, commerce allemand, saichung, xintranslation et autres investissements',
    text179: 'Baidu, Chengdu State Capital Commission, borne frontière, commerçant allemand, saichung, Xin Translation et autres investissements'
  },
  ru: {
    text1: 'С помощью технологий',
    text2: 'Предоставление клиентам с',
    text3: '"Соотношение цены и качества"',
    text4: 'Переводческие услуги',
    text5: 'Стабильность качества',
    text6: 'Низкие цены',
    text7: 'Своевременный ответ',
    text8: 'Baidu, Technology Transport Investment, пограничные столбы, Deutsche Business, Sai Chuang, новый перевод и другие инвестиции',
    text9: 'Выберите ускоритель AI',
    text10: 'Июль 2017 года',
    text11: 'Выберите 100 ускорителей.',
    text12: 'Август 2018 года',
    text13: 'Выбран новый экономический ускоритель PwC',
    text14: 'Декабрь 2019 года',
    text15: 'Использование услуг по письменному переводу',
    text16: 'Предоставление экономически эффективных услуг в соответствии с целями перевода',
    text17: 'Читайте сами',
    text18: 'Базовая точность, плавность, только просмотр перевода может понять смысл статьи, например, чтение новостей ',
    text19: 'Читаемость',
    text20: 'От доллара / тысячи слов',
    text21: 'Заказать немедленно',
    text22: 'Обычные клиенты читают',
    text23:
      'Точный, плавный, перевод может полностью выразить исходную информацию, нет грамматических ошибок, ошибок низкого уровня и упущений ключевой информации.  Например, тендерные заявки, спецификации, руководства по продукции ',
    text24: 'Точность',
    text25: 'Профессионализм ',
    text26: 'Ключевые клиенты читают',
    text27:
      'Перевод полностью выражает смысл оригинала и соответствует отраслевым привычкам выражения.  Например, опубликованные технические руководства, контракты, информация о продукции и т.д. ',
    text28: 'плавность',
    text29: 'Реестр лекарственных средств, категория декларирования ',
    text30: 'Например: заявление о регистрации лекарств, заявление о регистрации медицинского оборудования ',
    text31: 'Подлинность',
    text32: 'Продукт UI, маркетинг, патенты, публикации класса ',
    text33: 'Например: программный UI, игровой UI, UI сайта, публикация патентов, монография ',
    text34: 'UI Программное обеспечение ',
    text35: 'Игры UI',
    text36: 'Монографии по патентам ',
    text37: 'Объем услуг по письменному переводу',
    text38: 'Поддержка 65 языков, многопрофильный искусственный профессиональный перевод',
    text39: 'Как обеспечить рентабельный перевод?',
    text40: 'Технологии, создающие разные',
    text41: 'Низкие затраты',
    text42: 'Автоматизация производственной цепочки от заказа заказчика до поставки полного перевода;',
    text43:
      'Автоматическое создание проекта, автоматическое и точное соответствие подходящего переводчика в соответствии с требованиями проекта, автоматическое распределение редакторов, типография, снижение затрат на рабочую силу',
    text44: 'Обеспечение качества',
    text45:
      'Самоисследовательская платформа перевода предоставляет инструменты для проверки ошибок, смазки и других эффектов, чтобы помочь переводчикам завершить проверку качества',
    text46: 'Превосходный опыт обслуживания',
    text47: 'Производственный процесс перевода полностью прозрачен; Онлайн - заказ и получение перевода, отслеживание хода перевода в любое время',
    text48: 'Переводчиков много.',
    text49: 'Платформа имеет строгий механизм управления отбором переводчиков, который накапливает 3000 + высококачественных активных переводчиков',
    text50: 'Быстрее!',
    text51: 'Тысяча слов за 6 часов.',
    text52: 'Высокие затраты на рабочую силу',
    text53: 'Полная зависимость от человека;',
    text54:
      'Анализ проектов вручную - > Письменный перевод по назначению - > Координация редактирования вручную - > Скоординированная типография вручную - > Проверка качества вручную - > Доставка',
    text55: 'Глубокая зависимость от профессионализма и отношения к работе',
    text56: 'Зависимость от прогресса обратной связи продаж, ожидание занимает много времени, беспокойство',
    text57: '100 человек',
    text58: 'Тысяча слов за 48 часов.',
    text59: 'Локализация программного обеспечения',
    text60: 'Локализация сайта, UI, справочные документы, тексты маркетинга',
    text61: 'Инженерные работы',
    text62: 'Гражданское строительство, водное хозяйство, строительство, трубопроводы, химическая промышленность, тендерные заявки',
    text63: 'Компьютеры',
    text64: 'Сеть, информационная безопасность, Интернет вещей, искусственный интеллект, облачные вычисления',
    text65: 'Прочее',
    text66: 'Стратегическая тактика, топография, аэрокосмическая техника, оборудование, ядерная инженерия',
    text67: 'Транспорт',
    text68: 'Автодорожный транспорт, железнодорожный транспорт, водный транспорт, воздушный транспорт',
    text69: 'Медицина',
    text70:
      'Здравоохранение, базовая медицина, клиническая медицина, стоматология, китайская медицина, фармацевтика, медицинские технологии, сестринское дело, медицинские инструменты',
    text71: 'Гуманитарные науки',
    text72: 'Литература, образование, менеджмент, история, психология, новости, маркетинг',
    text73: 'Большой праздник',
    text74: 'Игры, онлайн - тексты, комиксы, аниме, кино - и телевизионные драмы',
    text75: 'Государственные службы',
    text76: 'Перевод документов, правительственные документы, достопримечательности',
    text77: 'Политика и право',
    text78: 'Политика и администрация, дипломатия, международная политика, право, контракты',
    text79: 'Экономика и финансы',
    text80: 'Экономика, финансы, налогообложение, торговля, инвестиции, страхование',
    text81: 'Машины и приборы',
    text82: 'Машины, автомобили, приборы, приборы',
    text83: 'Энергетика и производство электроэнергии',
    text84: 'Уголь, нефть, газ, электричество, новые источники энергии',
    text85: 'Электроника и электричество',
    text86: 'Электроника, схемы, связь, управление, сигналы, автоматизация',
    text87: 'Геология и горнодобывающая промышленность и металлургия',
    text88: 'Геология, горнодобывающая промышленность, металлургия',
    text89: 'Текстильная и легкая промышленность и ручная работа',
    text90: 'Текстильные изделия, одежда, упаковка, печать, печать и крашение, мебель, кожа, часы, продукты питания',
    text91: 'Экологические науки и безопасность',
    text92: 'HSE, очистка сточных вод, удаление отходов, энергосбережение и сокращение выбросов, ОВОС, техника безопасности',
    text93: 'Сельское хозяйство',
    text94: 'Защита растений, сельскохозяйственная продукция, садоводство, лесное хозяйство, животноводство, аквакультура, рыболовство, наука о животных',
    text95: 'Спорт',
    text96:
      'Спортивные площадки и оборудование, спортивные технологии, легкая атлетика, гимнастика, спорт, боевые искусства, водные виды спорта, спортивные мероприятия',
    text97: 'Искусство',
    text98: 'Музыка, танцы, театр, кино и телевидение, изобразительное искусство, дизайн',
    text99: 'Партнерские бренды клиентов',
    text100: 'В прошлом году мы сотрудничали',
    text101: 'Клиенты',
    text102: 'Случаи оказания услуг',
    text103: 'Baidu',
    text104: 'Технология зубов мудрости',
    text105: 'Хообо',
    text106:
      'Среди всех поставщиков, Utranslate Перевод быстрый, точный, плавный и естественный, в соответствии с нашими потребностями.  Мы очень довольны их профессионализмом и сервисным отношением и с нетерпением ждем продолжения сотрудничества в будущем.',
    text107: 'Своевременный ответ',
    text108: 'Качество перевода хорошее.',
    text109: 'Высокая рентабельность',
    text110: 'Хорошее отношение к службе',
    text111: 'Тематические исследования',
    text112:
      'Ранее в сотрудничестве с другими переводческими компаниями, они совершенно не понимают локализации, после работы с Utranslate, Utranslate предоставит различные таблицы потребностей локализации, мы следуем таблицам, чтобы объяснить содержание перевода, точный, профессиональный и эффективный перевод, так что мы также узнали много знаний о локализации.',
    text113: 'Перевод точный.',
    text114: 'Профессионально эффективный',
    text115: 'Быстрая доставка.',
    text116: 'Хорошее отношение к службе',
    text117:
      'Профессионализм и отзывчивость очень хороши, и его мощная многоязычная обработка приносит нам большую пользу, в дополнение к традиционной поддержке перевода документов, а также техническую стыковку с нашими внутренними системами, чтобы обеспечить своевременный доступ и доставку переводимого контента, что делает нас чрезвычайно удобными.',
    text118: 'Переводчик',
    text119: 'Очень.',
    text120: 'Высокая рентабельность',
    text121: 'Хорошее отношение к службе',
    text122: 'Избранные переводчики',
    text123: 'Соберите специалистов по многоязычному переводу на всех языках, чтобы предоставить вам профессиональные и точные услуги перевода!',
    text124: 'Почетная квалификация',
    text125: 'Интернет - это инфраструктурная платформа для отрасли, которая дает возможность всей отрасли',
    text126: 'Консультативные услуги',
    text127: 'О нас',
    text128: 'Краткая информация о компании',
    text129: 'История развития',
    text130: 'Корпоративная культура',
    text131: 'Корпоративная честь',
    text132: 'Свяжитесь с нами',
    text133: 'Нажмите на консультацию',
    text134: 'Телефон',
    text135: 'Мобильные телефоны',
    text136: 'Советы по микрокредитованию.',
    text137: 'Новые медиа - операции',
    text138: 'Индонезийский язык',
    text139: 'Индонезийские китайцы,',
    text140:
      'Родной язык - индонезийский, в настоящее время поселился в Китае, бакалавр учится в Сучжоуском университете по специальности китайский язык и литература, Чжэцзянский университет по специальности китайский язык за рубежом, выпускник до сих пор предоставляет услуги по переводу для ряда переводческих компаний, таких как Lingshenlian Translate Company, Language Bridge и China Translate Company, основными областями являются новые медиа и операции.',
    text141: 'Китайская академия медицинских наук',
    text142: 'Английский язык',
    text143: 'Западно - китайский медицинский институт Сычуаньского университета',
    text144:
      'Окончил, в настоящее время преподает в Шанхайском медицинском институте Университета Фудань, основное направление исследований - фармацевтическая биотехнология, знакомый с процессом тестирования производства лекарств и медицинской информации, опубликовал ряд медицинских статей на английском языке в « Ланцет» и зарубежных журналах, перевел документы клинических испытаний, описание лекарств, диагностические исследования и так далее.',
    text145: 'Внешнеторговая торговля',
    text146: 'Японский N1',
    text147: 'Обучение в Японии,',
    text148:
      'После окончания университета работал во внешней торговле в японской компании Mitsubishi Commercial Co., Ltd. а затем был изменен на японское гражданство, имеет сертификат японского уровня, обменивался и сотрудничал с японской компанией Itozhong Commercial Co., Ltd., компанией Mitsui Property Co., Ltd.',
    text149: 'Перевод субтитров',
    text150: 'Тайский язык',
    text151:
      'Занимался субтитрами, новостями, переводом веб - сайта, работал для всех в кино и телевидении субтитров, BTS субтитры, FST субтитры, Tianfu Tai субтитры, члены группы субтитров, участвовали в переводе тайской версии « ароматного пепла, как мороз», « Чанъань двенадцать часов», « красивый Ли Хуэйчжэнь » и других субтитров кино и телевидения.',
    text152: 'Экономика и финансы',
    text153: 'Французский язык',
    text154: 'Бакалавр прикладной экономики, Парижский университет политических наук, Франция',
    text155:
      'Окончил Тяньцзиньский университет финансов и экономики, окончил магистра прикладной экономики, окончил Университет внешней экономики и торговли, окончил докторантуру по экономике и торговле, изучал китайский язык во время учебы за рубежом, сдал экзамен по китайскому языку на уровне 6, в настоящее время работает на Французской фондовой бирже, в основном занимается переводом на китайско - французский язык, связанный с экономикой и финансами.',
    text156: 'Юридический перевод',
    text157: 'Арабский язык',
    text158: 'Каирский университет, Египет',
    text159:
      'Бакалавр права, магистр права, Китайский университет политических наук и права, изучал китайский язык в Институте Конфуция Каирского университета и Пекинском университете иностранных языков, сдал экзамен по китайскому языку шестого уровня, в настоящее время работает в египетской юридической фирме, предоставляет юридическую помощь и услуги юридического перевода для компаний и заводов, имеет 8 - летний опыт юридического перевода.',
    text160: 'Машиностроение',
    text161: 'Русский родной язык',
    text162: 'Российское гражданство',
    text163:
      'Переводчик, выпускник Московского государственного университета по китайскому языку и литературе, выпускник магистра гражданского строительства Уханьского университета, начал заниматься переводческой индустрией в 2006 году и до сих пор предоставляет переводческие услуги бюро перевода, школам, компаниям, издательствам, основным направлением перевода является инженерная техника.',
    text164: 'Перевод комиксов',
    text165: 'Корейский Topik 6 класс',
    text166: 'Учился в Китайском университете средств массовой информации',
    text167:
      'Профессиональный, магистр, обучающийся в Сеульском университете Южной Кореи по корейскому языку, через корейский CATTI второго уровня письменный перевод, корейский TOPIK6, во время учебы в Южной Корее в DR Movie, Daewon Media, AKOM и других анимационных компаниях, занимающихся дизайном комиксов и неполный рабочий день перевод комиксов, вторичные любители.',
    text168: 'Сертификат корпоративного кредитного рейтинга',
    text169: 'Сертификат системы менеджмента качества',
    text170: 'Сертификат системы управления интеллектуальной собственностью',
    text171: 'Сертификат системы управления информационно - техническими услугами',
    text172: 'Сертификат системы управления информационной безопасностью',
    text173: 'Сертификат нового предприятия',
    text174: 'Заместитель председателя Ассоциации переводчиков города Чэнду',
    text175: 'Член Комитета по переводческим услугам Китайской ассоциации переводчиков',
    text176: 'Свидетельство о членстве в Китайской ассоциации переводчиков',
    text177: 'Сертификат об окончании третьего учебного лагеря AI AI Accelerator',
    only_customer: 'Я ваш эксклюзивный клиент',
    free_quote: 'Скачать код бесплатно получить предложение',
    good_quote: 'Бесплатно получать качественные предложения',
    phone: 'Горячая консультация',
    hot_service1: 'Горячие услуги',
    hot_service2: 'Перевод диссертации',
    hot_service3:
      'Сосредоточьтесь на предоставлении студентам, ученым и специалистам высококачественных услуг по переводу диссертаций, владея терминологией и языковыми стилями в различных предметных областях.  Мы также предоставляем быстрые, точные и доступные услуги.',
    hot_service4: 'Перевод резюме',
    hot_service5:
      'Независимо от того, ищете ли вы международную работу или вам нужно подать заявку на работу в иностранном бизнесе, мы можем предоставить вам высококачественные услуги по переводу резюме, чтобы точно понять и передать ваш опыт работы, навыки и достижения',
    hot_service6: 'Перевод материалов заявки на обучение за рубежом',
    hot_service7:
      'Независимо от того, является ли это академической стенограммой, рекомендательным письмом, личным заявлением или другим заявочным материалом, мы можем точно перевести на целевой язык, сосредоточиться на деталях, обеспечить точность и сделать ваш заявочный материал более профессиональным и убедительным',
    hot_service8: 'Низкие цены',
    hot_service9: '“соотношение цена - качество”',
    hot_service10: 'через технологии, чтобы предоставить клиентам с',
    hot_service101: 'Переводческие услуги',
    hot_service11: 'Гарантия качества',
    hot_service12: 'Быстрее',
    hot_service13: 'Ответить вовремя',
    hot_service14: 'Интеллектуальный перевод производственных процессов',
    hot_service15: 'значительно упрощает управление процессом перевода',
    hot_service16:
      'По сравнению с традиционными переводческими компаниями, мы полностью заменили менеджеров проектов роботами для распространения, переработки и проверки файлов',
    hot_service17: 'Автоматизация процессов',
    hot_service18: 'Снижение затрат на управление',
    hot_service19: 'Снижение эффективности',
    hot_service20: 'Быстрее',
    hot_service21: 'Саморазвивающийся и обученный вертикальный двигатель опрокидывания',
    hot_service22: 'Качество перевода достигает уровня профессионального переводчика иностранного языка',
    hot_service23: 'Предоставление профессиональных, точных и рентабельных услуг перевода через технологию! ',
    hot_service24: 'AI Перевод',
    hot_service25: 'Искусственный редактор',
    hot_service26: 'Искусственная ретушировка',
    text178: 'Чэндуский городской комитет по государственным инвестициям, пограничные столбы, немецкий бизнес, Сай Чуан, новый перевод и другие инвестиции',
    text179: 'Baidu, Chengdu City SASAC, пограничный камень, немецкий торговец, Сай Чуан, новый перевод и другие инвестиции'
  },
  th: {
    text1: 'โดยเทคโนโลยี',
    text2: 'เพื่อให้ลูกค้ามี',
    text3: '" ประสิทธิภาพด้านต้นทุน "',
    text4: 'บริการแปลเอกสาร',
    text5: 'คุณภาพมีเสถียรภาพ',
    text6: 'ราคาต่ำ',
    text7: 'การตอบสนองที่รวดเร็ว',
    text8: 'Baidu, Technical Ventures, Bordership, Deutsche, Saichuang, Xintranslation และการลงทุนอื่น ๆ',
    text9: 'ได้รับเลือกให้เป็น Tencent AI Accelerator',
    text10: 'กรกฎาคม 2017',
    text11: 'เลือก Baidu AI Accelerator',
    text12: 'สิงหาคม 2018',
    text13: 'ได้รับเลือกเป็นตัวเร่งเศรษฐกิจใหม่ของ PwC',
    text14: 'ธันวาคม 2019',
    text15: 'บริการแปลเอกสาร',
    text16: 'บริการที่คุ้มค่าตามการแปล',
    text17: 'อ่านใช้เอง',
    text18: 'พื้นฐานถูกต้อง ราบรื่น อ่านแต่คำแปลเข้าใจใจความของบทความ เช่น อ่านข่าว',
    text19: 'ความสามารถในการอ่าน',
    text20: 'หยวน / พันคำ',
    text21: 'สั่งซื้อตอนนี้',
    text22: 'การใช้งานการอ่านสำหรับลูกค้าทั่วไป',
    text23:
      'ถูกต้อง ราบรื่น การแปลสามารถแสดงข้อมูลต้นฉบับได้อย่างเต็มที่ ไม่มีข้อผิดพลาดทางไวยากรณ์ ข้อผิดพลาดต่ําและการละเว้นข้อมูลสําคัญ เช่น การประมูล คู่มือการใช้งาน คู่มือผลิตภัณฑ์',
    text24: 'ความถูกต้อง',
    text25: 'มืออาชีพ เซ็กส์',
    text26: 'เน้นการใช้งานของลูกค้า',
    text27:
      'การแปลภาษาสามารถแสดงความหมายของข้อความต้นฉบับได้อย่างสมบูรณ์และสอดคล้องกับนิสัยการแสดงออกของอุตสาหกรรม เช่น คู่มือทางเทคนิค สัญญา ข้อมูลผลิตภัณฑ์ ฯลฯ ที่ออกสู่ภายนอก',
    text28: 'ความคล่องแคล่ว',
    text29: 'การขึ้นทะเบียนยา, ประกาศคลาส',
    text30: 'ตัวอย่างเช่น: ประกาศขึ้นทะเบียนยา, ประกาศขึ้นทะเบียนเครื่องมือแพทย์',
    text31: 'แท้จริง เซ็กส์',
    text32: 'UI ผลิตภัณฑ์การตลาดสิทธิบัตรการเผยแพร่คลาส',
    text33: 'ตัวอย่างเช่น: ซอฟต์แวร์ UI, เกม UI, เว็บไซต์ UI, เผยแพร่สิทธิบัตร, เอกสาร',
    text34: 'ซอฟต์แวร์ UI',
    text35: 'เกม UI',
    text36: 'เอกสารสิทธิบัตร',
    text37: 'ขอบเขตของบริการแปลภาษา',
    text38: 'รองรับ 65 ภาษา, นักแปลมืออาชีพหลายสาขา',
    text39: 'วิธีการแปลที่มีประสิทธิภาพค่าใช้จ่าย?',
    text40: 'เทคโนโลยีสร้างความแตกต่าง',
    text41: 'ต้นทุนต่ำ',
    text42: 'ตระหนักถึงระบบอัตโนมัติของห่วงโซ่การผลิตแปลเต็มรูปแบบตั้งแต่การสั่งซื้อของลูกค้าจนถึงการจัดส่ง',
    text43: 'สร้างโครงการโดยอัตโนมัติตามความต้องการของโครงการจับคู่นักแปลที่เหมาะสมโดยอัตโนมัติอย่างถูกต้องจัดสรรการตรวจสอบโดยอัตโนมัติการพิมพ์ลดต้นทุนแรงงาน',
    text44: 'การประกันคุณภาพ',
    text45: 'แพลตฟอร์มการแปลงานวิจัยด้วยตนเองให้การตรวจสอบข้อผิดพลาดต่ำการขัดเกลาและเครื่องมือเอฟเฟกต์อื่น ๆ นักแปลเสริมทำการทดสอบคุณภาพ',
    text46: 'ประสบการณ์การบริการที่ยอดเยี่ยม',
    text47: 'กระบวนการผลิตการแปลอย่างโปร่งใส; สั่งซื้อสินค้าออนไลน์และรับการแปลเพื่อติดตามความคืบหน้าการแปลได้ตลอดเวลา',
    text48: 'นักแปลหลายคน',
    text49: 'แพลตฟอร์มมีกลไกการจัดการการคัดกรองล่ามที่เข้มงวดและได้สะสมนักแปลที่มีคุณภาพสูงกว่า 3,000 คน',
    text50: 'ความเร็วที่รวดเร็ว',
    text51: '1000 คำใช้เวลาเพียง 6 ชั่วโมง',
    text52: 'ต้นทุนแรงงานสูง',
    text53: 'พึ่งพาแรงงานมนุษย์ทั้งหมด',
    text54:
      'การวิเคราะห์โครงการด้วยตนเอง -> การแปลจัดสรรด้วยตนเอง -> การตรวจสอบการประสานงานด้วยตนเอง -> การพิมพ์การประสานงานด้วยตนเอง -> การทดสอบคุณภาพการจัดเตรียมด้วยตนเอง -> การจัดส่ง',
    text55: 'ความสามารถทางวิชาชีพและทัศนคติในการทำงานที่ต้องพึ่งพาแรงงานมนุษย์อย่างลึกซึ้ง',
    text56: 'พึ่งพาความคืบหน้าผลตอบรับของยอดขายที่ใช้เวลารอนานและวิตกกังวล',
    text57: '100 คน',
    text58: '1000 คำใช้เวลา 48 ชั่วโมง',
    text59: 'การแปลซอฟต์แวร์',
    text60: 'การแปลเว็บไซต์, UI, เอกสารช่วยเหลือ, Copywriting การตลาด',
    text61: 'วิศวกรรม',
    text62: 'โยธา, การอนุรักษ์น้ำ, การก่อสร้าง, ท่อ, เคมี, การเสนอราคา',
    text63: 'คอมพิวเตอร์',
    text64: 'เครือข่าย, ความปลอดภัยของข้อมูล, IoT, ปัญญาประดิษฐ์, คลาวด์คอมพิวติ้ง',
    text65: 'อื่นๆ',
    text66: 'กลยุทธ์และยุทธศาสตร์, ภูมิศาสตร์ภูมิประเทศ, การบินและอวกาศ, อุปกรณ์, วิศวกรรมนิวเคลียร์',
    text67: 'การขนส่ง',
    text68: 'การขนส่งทางถนน การขนส่งทางราง การขนส่งทางน้ำ การขนส่งทางอากาศ',
    text69: 'การแพทย์',
    text70: 'การดูแลสุขภาพ, การแพทย์พื้นฐาน, การแพทย์คลินิก, การแพทย์ช่องปาก, การแพทย์แผนจีน, เภสัชกรรม, เทคโนโลยีทางการแพทย์, พยาบาลศาสตร์, เครื่องมือแพทย์',
    text71: 'สังคมศาสตร์ มนุษยศาสตร์',
    text72: 'วรรณกรรม, การศึกษา, การจัดการ, ประวัติศาสตร์, จิตวิทยา, ข่าว, การตลาด',
    text73: 'ความบันเทิงขนาดใหญ่',
    text74: 'เกม, ข้อความออนไลน์, การ์ตูน, อะนิเมะ, ภาพยนตร์และโทรทัศน์ซีรีส์',
    text75: 'บริการสาธารณะ',
    text76: 'แปลเอกสาร เอกสารราชการ สถานที่ท่องเที่ยว',
    text77: 'การเมืองและกฎหมาย',
    text78: 'การเมืองและการบริหาร, การต่างประเทศ, การเมืองระหว่างประเทศ, กฎหมาย, สัญญา',
    text79: 'เศรษฐกิจและการเงิน',
    text80: 'ไม่ว่าจะเป็นด้านเศรษฐกิจ การคลัง ภาษี การค้า การลงทุน การประกันภัย',
    text81: 'เครื่องจักรและเครื่องมือ',
    text82: 'เครื่องจักร, รถยนต์, เมตร, เครื่องมือ',
    text83: 'พลังงานและการผลิตไฟฟ้า',
    text84: 'ถ่านหิน, น้ำมัน, ก๊าซธรรมชาติ, ไฟฟ้า, พลังงานใหม่',
    text85: 'อิเล็กทรอนิกส์และไฟฟ้า',
    text86: 'อิเล็กทรอนิกส์, วงจร, การสื่อสาร, การควบคุม, สัญญาณ, ระบบอัตโนมัติ',
    text87: 'ธรณีวิทยาและเหมืองแร่และโลหะวิทยา',
    text88: 'ธรณีวิทยา, เหมืองแร่, โลหะ',
    text89: 'สิ่งทอและอุตสาหกรรมเบาและงานฝีมือ',
    text90: 'สิ่งทอ, เสื้อผ้า, บรรจุภัณฑ์, การพิมพ์, การพิมพ์และการย้อมสี, เฟอร์นิเจอร์, หนัง, นาฬิกา, อาหาร',
    text91: 'วิทยาศาสตร์สิ่งแวดล้อมและวิทยาศาสตร์ความปลอดภัย',
    text92: 'HSE, การบำบัดน้ำเสีย, การบำบัดของเสีย, การประหยัดพลังงานและการลดการปล่อยก๊าซ, EIA, วิศวกรรมความปลอดภัย',
    text93: 'การเกษตร',
    text94: 'การคุ้มครองพืช, สินค้าเกษตร, พืชสวน, ป่าไม้, ปศุสัตว์, สัตว์น้ำ, ประมง, สัตววิทยา',
    text95: 'กีฬา',
    text96: 'สถานที่และอุปกรณ์กีฬา, เทคโนโลยีกีฬา, กรีฑา, ยิมนาสติก, กีฬาบอล, ศิลปะการต่อสู้, กีฬาทางน้ำ, กิจกรรมรูปแบบ',
    text97: 'ศิลปะ',
    text98: 'ดนตรี, การเต้นรำ, ละคร, ภาพยนตร์และโทรทัศน์, วิจิตรศิลป์, การออกแบบ',
    text99: 'ลูกค้าแบรนด์ที่ร่วมมือกัน',
    text100: 'โดยในปีที่ผ่านมาได้รับความร่วมมือเป็นอย่างดี',
    text101: 'ลูกค้า',
    text102: 'กรณีบริการ',
    text103: 'ไป่ตู้',
    text104: 'เทคโนโลยีฟันคุด',
    text105: 'โฮโอโบ',
    text106:
      'ในบรรดาซัพพลายเออร์ทั้งหมดการแปลที่ดีเยี่ยมนั้นรวดเร็วถูกต้องและเป็นธรรมชาติตรงตามความต้องการของเรา คุณภาพระดับมืออาชีพและทัศนคติในการบริการของพวกเขาทำให้เราพอใจมากและมองไปข้างหน้าเพื่อความร่วมมือต่อไปในอนาคต',
    text107: 'การตอบสนองที่รวดเร็ว',
    text108: 'การแปลคุณภาพดี',
    text109: 'ประหยัดค่าใช้จ่าย',
    text110: 'ทัศนคติการบริการที่ดี',
    text111: 'กรณีศึกษา',
    text112:
      'ก่อนหน้านี้ได้ร่วมมือกับบริษัทแปลอื่น ๆ พวกเขาไม่เข้าใจการแปลเป็นภาษาโลคัลไลเซชันเลย หลังจากร่วมมือกับนักแปลที่ยอดเยี่ยมแล้ว นักแปลที่ยอดเยี่ยมจะให้ตารางความต้องการที่แปลเป็นภาษาท้องถิ่นต่าง ๆ เราตามตารางเพื่อส่งมอบเนื้อหาที่แปล การแปลมีความถูกต้อง เป็นมืออาชีพและมีประสิทธิภาพ ทําให้เราได้เรียนรู้มากมายเกี่ยวกับการแปลเป็นภาษาท้องถิ่น',
    text113: 'แปลถูกต้อง',
    text114: 'มืออาชีพและมีประสิทธิภาพ',
    text115: 'จัดส่งที่รวดเร็ว',
    text116: 'ทัศนคติการบริการที่ดี',
    text117:
      'ความเป็นมืออาชีพและการตอบสนองที่ดีมากและความสามารถในการประมวลผลหลายภาษาที่แข็งแกร่งทำให้เราได้รับประโยชน์อย่างมากนอกเหนือจากการสนับสนุนการแปลเอกสารแบบดั้งเดิมแล้วยังมีการเชื่อมต่อทางเทคนิคกับระบบภายในของเราเพื่อให้สามารถเข้าถึงและส่งมอบเนื้อหาที่จะแปลได้ทันเวลาทำให้เราได้รับความสะดวกสบายอย่างมาก',
    text118: 'อาชีพนักแปล',
    text119: 'ของแท้มาก',
    text120: 'ประหยัดค่าใช้จ่าย',
    text121: 'ทัศนคติการบริการที่ดี',
    text122: 'ผู้เชี่ยวชาญด้านการแปลที่ได้รับการคัดเลือก',
    text123: 'รวบรวมผู้เชี่ยวชาญด้านการแปลหลายสาขาจากหลายภาษาเพื่อให้บริการแปลอย่างมืออาชีพและแม่นยำ!',
    text124: 'คุณสมบัติกิตติมศักดิ์',
    text125: 'Youjie.com เป็นแพลตฟอร์มโครงสร้างพื้นฐานของอุตสาหกรรมเพื่อเพิ่มขีดความสามารถให้กับอุตสาหกรรมทั้งหมด',
    text126: 'บริการให้คำปรึกษา',
    text127: 'เกี่ยวกับเรา',
    text128: 'ข้อมูล บริษัท',
    text129: 'หลักสูตรการพัฒนา',
    text130: 'วัฒนธรรมองค์กร',
    text131: 'เกียรติขององค์กร',
    text132: 'ติดต่อเรา',
    text133: 'คลิกสอบถามฝ่ายบริการลูกค้า',
    text134: 'โทรศัพท์',
    text135: 'โทรศัพท์มือถือ',
    text136: 'WeChat กวาดคำปรึกษา',
    text137: 'การดำเนินการสื่อใหม่',
    text138: 'ชาวอินโดนีเซีย',
    text139: 'ชาวอินโดนีเซียเชื้อสายจีน',
    text140:
      'เจ้าของภาษาเป็นภาษาอินโดนีเซียและปัจจุบันตั้งรกรากอยู่ในประเทศจีน มหาวิทยาลัยกำลังศึกษาสาขาวิชาภาษาและวรรณคดีจีนที่มหาวิทยาลัยซูโจวและปริญญาโทสาขาวิชาภาษาจีนต่างประเทศที่มหาวิทยาลัยเจ้อเจียง จบการศึกษาจนถึงปัจจุบันเพื่อให้บริการแปลแก่ บริษัท แปลหลายแห่งเช่น บริษัท แปลภาษาของ Chuangshen Language Bridge นักแปลภาษาและล่าม สาขาหลักคือสื่อใหม่และการดำเนินงาน',
    text141: 'สถาบันวิทยาศาสตร์การแพทย์จีน',
    text142: 'ภาษาอังกฤษ',
    text143: 'วิทยาลัยการแพทย์หัวซี มหาวิทยาลัยเสฉวน',
    text144:
      'จบการศึกษาและตอนนี้เป็นอาจารย์ที่ Shanghai School of Medicine มหาวิทยาลัย Fudan ทิศทางการวิจัยหลักคือเทคโนโลยีชีวภาพทางเภสัชกรรมคุ้นเคยกับกระบวนการตรวจสอบการผลิตยาและข้อมูลทางการแพทย์ตีพิมพ์บทความภาษาอังกฤษทางการแพทย์จำนวนมากในวารสาร Lancet และวารสารต่างประเทศและได้แปลเอกสารการทดลองทางคลินิกคำแนะนำด้านยารายงานการวิจัยการวินิจฉัย ฯลฯ',
    text145: 'ธุรกิจการค้าต่างประเทศ',
    text146: 'ญี่ปุ่น N1',
    text147: 'ศึกษาต่อประเทศญี่ปุ่น',
    text148:
      'หลังจากสำเร็จการศึกษาแล้วไปทำงานต่างประเทศที่บริษัท มิตซูบิชิ คอร์ปอเรชั่น ประเทศญี่ปุ่น และต่อมาได้รับการแก้ไขสัญชาติญี่ปุ่น มีใบประกาศนียบัตรภาษาญี่ปุ่นระดับ 1 และได้แลกเปลี่ยนความร่วมมือกับบริษัท อิโตชู คอร์ปอเรชั่น ประเทศญี่ปุ่น บริษัท มารูเฮ จำกัด บริษัท มิตซุย โปรดักส์ จำกัด สาขาการแปลหลักคือ การแปลการต่างประเทศระหว่างจีนและญี่ปุ่น',
    text149: 'แปลคำบรรยาย',
    text150: 'ภาษาไทย',
    text151:
      'ประกอบอาชีพแปลซับไตเติ้ล ข่าว เว็บไซต์ เคยเป็นสมาชิกกลุ่มซับไตเติ้ลภาพยนตร์และโทรทัศน์สำหรับทุกคน กลุ่มซับไตเติ้ล BTS กลุ่มซับไตเติ้ล FST กลุ่มซับไตเติ้ลละครไทยเทียนฟู กลุ่มซับไตเติ้ล THE ร่วมแปลซับไตเติ้ลภาพยนตร์และโทรทัศน์ เช่น น้ำผึ้งหอมดุจน้ำค้างแข็ง สิบสองชั่วโมงฉางอัน พริตตี้หลี่ฮุ่ยเจิน ในเวอร์ชั่นไทย',
    text152: 'เศรษฐกิจและการเงิน',
    text153: 'ฝรั่งเศส',
    text154: 'ปริญญาตรีเศรษฐศาสตร์ประยุกต์ มหาวิทยาลัยการเมืองปารีส ประเทศฝรั่งเศส',
    text155:
      'สำเร็จการศึกษาระดับปริญญาโทด้านเศรษฐศาสตร์ประยุกต์จากมหาวิทยาลัยการเงินและเศรษฐศาสตร์เทียนจินและปริญญาเอกด้านเศรษฐกิจและการค้าจากมหาวิทยาลัยธุรกิจระหว่างประเทศและเศรษฐศาสตร์ ศึกษาหลักสูตรภาษาจีนในระหว่างการศึกษาต่อต่างประเทศและผ่านการสอบวัดระดับภาษาจีนระดับ 6 ปัจจุบันทำงานในตลาดหลักทรัพย์ฝรั่งเศสซึ่งส่วนใหญ่มีส่วนร่วมในการแปลภาษาจีนและฝรั่งเศสที่เกี่ยวข้องกับเศรษฐกิจและการเงิน',
    text156: 'การแปลทางกฎหมาย',
    text157: 'ภาษาอาหรับ',
    text158: 'คณะนิติศาสตร์ มหาวิทยาลัยไคโร ประเทศอียิปต์',
    text159:
      'ปริญญาตรี นิติศาสตรมหาบัณฑิต มหาวิทยาลัยรัฐศาสตร์และกฎหมายแห่งประเทศจีน เคยศึกษาภาษาจีนที่สถาบันขงจื่อแห่งมหาวิทยาลัยไคโร และมหาวิทยาลัยภาษาต่างประเทศปักกิ่ง ผ่านการสอบวัดระดับภาษาจีนระดับ 6 ปัจจุบันทำงานในสำนักงานกฎหมายอียิปต์ให้ความช่วยเหลือทางกฎหมายและบริการแปลกฎหมายแก่บริษัทและโรงงาน มีประสบการณ์ในการแปลกฎหมายเป็นเวลา 8 ปี',
    text160: 'เครื่องจักรก่อสร้าง',
    text161: 'ภาษารัสเซีย',
    text162: 'สัญชาติรัสเซีย',
    text163:
      'นักแปล จบการศึกษาจากมหาวิทยาลัยแห่งรัฐมอสโก สาขาวิชาภาษาและวรรณคดีจีน และปริญญาโทด้านวิศวกรรมโยธา จากมหาวิทยาลัยหวู่ฮั่น และเริ่มทำงานในอุตสาหกรรมการแปลในปี 2549 จนถึงปัจจุบันให้บริการแปลเอกสารแก่สำนักแปล โรงเรียน บริษัท สำนักพิมพ์ สาขาการแปลหลักคือเครื่องจักรวิศวกรรม',
    text164: 'การแปลการ์ตูน',
    text165: 'ระดับ TOPIK6 ภาษาเกาหลี',
    text166: 'การศึกษาระดับปริญญาตรีด้านการออกแบบแอนิเมชั่นที่มหาวิทยาลัยการสื่อสารแห่งประเทศจีน',
    text167:
      'เอกเอก ปริญญาโท เรียนภาษาเกาหลีที่มหาวิทยาลัยโซล ประเทศเกาหลี ผ่านการแปล CATTI ระดับ 2 ภาษาเกาหลี TOPIK6 ภาษาเกาหลี และทำงานออกแบบการ์ตูนและงานแปลการ์ตูนพาร์ทไทม์ ในบริษัทแอนิเมชั่นต่างๆ เช่น DR Movie, Daewon Media, AKOM ระหว่างศึกษาต่อที่ประเทศเกาหลี,คนรักไบนารีรอง',
    text168: 'ใบรับรองระดับเครดิตองค์กร',
    text169: 'ใบรับรองระบบการจัดการคุณภาพ',
    text170: 'ใบรับรองระบบการจัดการทรัพย์สินทางปัญญา',
    text171: 'ใบรับรองระบบการจัดการบริการด้านเทคโนโลยีสารสนเทศ',
    text172: 'ใบรับรองระบบการจัดการความปลอดภัยของข้อมูล',
    text173: 'ใบรับรององค์กรไฮเทค',
    text174: 'รองประธานสมาคมนักแปลเฉิงตู',
    text175: 'หน่วยสมาชิก คณะกรรมการบริการแปล สมาคมนักแปลภาษาจีน',
    text176: 'ใบรับรองการเป็นสมาชิกของสมาคมนักแปลภาษาจีน',
    text177: 'ประกาศนียบัตรบัณฑิตของ Baidu AI Accelerator เฟส 3 Bootcamp',
    only_customer: 'ฉันเป็นบริการลูกค้าพิเศษของคุณ',
    free_quote: 'สแกนโค้ดเพื่อรับใบเสนอราคาฟรี',
    good_quote: 'รับใบเสนอราคาที่มีคุณภาพฟรี',
    phone: 'สายด่วนให้คำปรึกษา',
    hot_service1: 'บริการยอดนิยม',
    hot_service2: 'แปลวิทยานิพนธ์',
    hot_service3:
      'มุ่งเน้นการให้บริการแปลวิทยานิพนธ์ที่มีคุณภาพสูงแก่นักศึกษานักวิชาการและผู้เชี่ยวชาญที่มีทักษะด้านคำศัพท์และรูปแบบภาษาในสาขาวิชาต่างๆ นอกจากนี้เรายังให้บริการที่รวดเร็วถูกต้องและราคาไม่แพง ',
    hot_service4: 'นักแปล CV',
    hot_service5:
      'ไม่ว่าคุณกำลังมองหาโอกาสในการทำงานในต่างประเทศหรือต้องการสมัครงานกับบริษัทต่างชาติเราสามารถให้บริการแปลเรซูเม่ที่มีคุณภาพสูงที่เข้าใจและถ่ายทอดประสบการณ์การทำงานทักษะและความสำเร็จของคุณได้อย่างถูกต้อง',
    hot_service6: 'แปลเอกสารการสมัครศึกษาต่อต่างประเทศ',
    hot_service7:
      'เราสามารถแปลเป็นภาษาเป้าหมายได้อย่างถูกต้องไม่ว่าจะเป็นใบรับรองผลการศึกษาจดหมายรับรองคำแถลงส่วนบุคคลหรือเอกสารการสมัครอื่น ๆ ใส่ใจในรายละเอียดเพื่อให้แน่ใจว่าถูกต้องทำให้เอกสารการสมัครของคุณเป็นมืออาชีพและน่าเชื่อถือมากขึ้น',
    hot_service8: 'ราคาต่ำ',
    hot_service9: '“ราคาคุ้มค่า”',
    hot_service10: 'ผ่านเทคโนโลยีเพื่อให้ลูกค้ามี ',
    hot_service101: 'บริการแปลภาษาสำหรับ',
    hot_service11: 'รับประกันคุณภาพ',
    hot_service12: 'ความเร็วที่รวดเร็ว',
    hot_service13: 'การตอบสนองทันเวลา',
    hot_service14: 'กระบวนการผลิตการแปลอัจฉริยะ, ',
    hot_service15: 'การจัดการกระบวนการแปลที่ง่ายมาก',
    hot_service16: 'เมื่อเทียบกับ บริษัท แปลแบบดั้งเดิมการแจกจ่ายการรีไซเคิลและการตรวจสอบเอกสารของเราแทนที่ผู้จัดการโครงการด้วยหุ่นยนต์อย่างสมบูรณ์',
    hot_service17: 'กระบวนการอัตโนมัติ',
    hot_service18: 'ลดต้นทุนการจัดการ',
    hot_service19: 'ลดเอฟเฟกต์การยกย่อง',
    hot_service20: 'รวดเร็ว',
    hot_service21: 'เครื่องยนต์พลิกแนวตั้งสำหรับการวิจัยด้วยตนเองและการฝึกอบรม,',
    hot_service22: 'คุณภาพการแปลสามารถเข้าถึงนักแปลภาษาต่างประเทศระดับมืออาชีพ',
    hot_service23: 'ผ่านเทคโนโลยี, ให้บริการแปลมืออาชีพ, ถูกต้องและคุ้มค่าค่าใช้จ่าย!',
    hot_service24: 'การแปล AI',
    hot_service25: 'โรงเรียนสอบเทียม',
    hot_service26: 'ขัดเงาเทียม',
    text178: 'Чэндуский городской комитет по государственным инвестициям, пограничные столбы, немецкие бизнесмены, Сай Чуан, новый перевод и другие инвестиции',
    text179: 'Baidu, Chengdu City SASAC, пограничные столбы, немецкие бизнесмены, Сай Чуан, новый перевод и другие инвестиции'
  },
  es: {
    text1: 'A través de la Ciencia y la tecnología',
    text2: 'Proporcionar a los clientes con',
    text3: '"Relación calidad - precio"',
    text4: 'Servicios de traducción',
    text5: 'Calidad estable',
    text6: 'Precios bajos',
    text7: 'Respuesta oportuna',
    text8: 'Baidu, capital de riesgo de transferencia de tecnología, piedra fronteriza, empresarios alemanes, saichuang, nueva traducción y otras inversiones',
    text9: 'Seleccionado para el acelerador Tencent ai',
    text10: 'Julio 2017',
    text11: 'Seleccionado para el acelerador Baidu ai',
    text12: 'Agosto 2018',
    text13: 'Seleccionado como el acelerador de la nueva economía de PricewaterhouseCoopers',
    text14: 'Diciembre de 2019',
    text15: 'Uso de los servicios de traducción',
    text16: 'Prestación de servicios económicos y eficaces en función del uso de la traducción',
    text17: 'Leer y usar por sí mismo',
    text18: 'Básicamente preciso y fluido, solo leer la traducción puede entender la idea principal del artículo, como la lectura de noticias.',
    text19: 'Legibilidad',
    text20: 'Yuan / mil palabras',
    text21: 'Hacer un pedido de inmediato',
    text22: 'Lectura y uso de clientes comunes',
    text23:
      'Precisa y fluida, la traducción puede expresar completamente la información original, sin errores gramaticales, errores bajos y omisiones de información clave. Por ejemplo, licitaciones, instrucciones, manuales de productos',
    text24: 'Precisión',
    text25: 'Profesionalidad',
    text26: 'Uso de lectura de clientes clave',
    text27:
      'La traducción puede expresar completamente el significado del texto original y ajustarse a los hábitos de expresión de la industria. Por ejemplo, manuales técnicos, contratos, materiales de productos publicados al mundo exterior, etc.',
    text28: 'Fluidez',
    text29: 'Registro y declaración de medicamentos',
    text30: 'Por ejemplo: Declaración de registro médico, Declaración de registro de dispositivos médicos',
    text31: 'Autenticidad',
    text32: 'Productos ui, marketing, patentes, publicaciones',
    text33: 'Por ejemplo: interfaz de usuario de software, interfaz de usuario de juego, interfaz de usuario de sitio web, publicación de patentes, monografía',
    text34: 'Software UI',
    text35: 'Juego UI',
    text36: 'Monografía sobre patentes',
    text37: 'Alcance de los servicios de traducción',
    text38: 'Apoyo a la traducción profesional manual en 65 idiomas y campos múltiples',
    text39: '¿¿ cómo proporcionar una traducción rentable?',
    text40: 'Tecnología, creando diferencias',
    text41: 'Bajo costo',
    text42: 'Realizar la automatización de la cadena de producción de traducción completa desde el pedido del cliente hasta la entrega;',
    text43:
      'Crear automáticamente proyectos, emparejar automáticamente y con precisión a los traductores adecuados de acuerdo con los requisitos del proyecto, asignar automáticamente revisores y tipografía, y reducir los costos humanos.',
    text44: 'Garantía de calidad',
    text45:
      'La Plataforma de traducción de autoinvestigación proporciona herramientas de efecto como inspección de bajo error y pulido para ayudar a los traductores a completar las pruebas de calidad.',
    text46: 'Excelente experiencia de servicio',
    text47:
      'El proceso de producción de traducción es completamente transparente; Hacer pedidos en línea y obtener traducciones, seguir el progreso de la traducción en cualquier momento',
    text48: 'Muchos traductores',
    text49:
      'La plataforma cuenta con un estricto mecanismo de gestión de selección de traductores, acumulando más de 3.000 traductores activos de alta calidad.',
    text50: 'Rápido',
    text51: '1000 palabras tardan solo 6 horas',
    text52: 'Altos costos humanos',
    text53: 'Totalmente dependiente de la mano de obra;',
    text54:
      'Análisis de proyectos manuales - > traducción de asignación manual - > revisión de coordinación manual - > composición tipográfica de coordinación manual - > organización manual de pruebas de calidad - > entrega',
    text55: 'Dependencia profunda de la capacidad profesional y la actitud laboral de la mano de obra',
    text56: 'Depende del progreso de la retroalimentación de ventas, el tiempo de espera es largo, la ansiedad',
    text57: '100 personas',
    text58: '1000 palabras tardan 48 horas',
    text59: 'Localización de software',
    text60: 'Localización de sitios web, ui, documentos de ayuda, copywriting de marketing',
    text61: 'Obras',
    text62: 'Civil, conservación del agua, construcción, tuberías, productos químicos, licitación',
    text63: 'Computadora',
    text64: 'Redes, seguridad de la información, Internet de las cosas, inteligencia artificial, computación en la nube',
    text65: 'Otros',
    text66: 'Táctica estratégica, geografía topográfica, aeroespacial, equipos, ingeniería nuclear',
    text67: 'Transporte',
    text68: 'Transporte por carretera, transporte ferroviario, transporte por vías navegables, transporte aéreo',
    text69: 'Medicina',
    text70:
      'Salud, medicina básica, medicina clínica, estomatología, medicina tradicional china, farmacia, tecnología médica, enfermería, dispositivos médicos',
    text71: 'Ciencias Sociales y Humanidades',
    text72: 'Literatura, educación, gestión, historia, psicología, periodismo, Marketing',
    text73: 'Gran entretenimiento',
    text74: 'Juegos, textos en línea, cómics, animación, películas y dramas de televisión',
    text75: 'Servicio público',
    text76: 'Traducción de documentos, documentos gubernamentales, lugares pintorescos',
    text77: 'Política y derecho',
    text78: 'Política y administración, diplomacia, política internacional, derecho, contratos',
    text79: 'Economía y Finanzas',
    text80: 'Economía, finanzas, impuestos, comercio, inversión, seguros',
    text81: 'Maquinaria e instrumentos',
    text82: 'Maquinaria, automóviles, instrumentos, instrumentos',
    text83: 'Energía y generación de energía',
    text84: 'Carbón, petróleo, gas natural, electricidad, nueva energía',
    text85: 'Electrónica y eléctrica',
    text86: 'Electrónica, circuitos, comunicaciones, control, señalización, automatización',
    text87: 'Geología & minería & metalurgia',
    text88: 'Geología, minería, metalurgia',
    text89: 'Textil & industria ligera & artesanía',
    text90: 'Textiles, prendas de vestir, envases, impresión, impresión y teñido, muebles, cuero, relojes, alimentos',
    text91: 'Ciencias ambientales y Ciencias de la seguridad',
    text92:
      'Hse, tratamiento de aguas residuales, tratamiento de residuos, ahorro de energía y reducción de emisiones, evaluación ambiental, ingeniería de Seguridad',
    text93: 'Agricultura',
    text94: 'Protección vegetal, productos agrícolas, horticultura, silvicultura, ganadería, acuicultura, pesca, ciencias animales',
    text95: 'Deportes',
    text96:
      'Campos y equipos deportivos, técnicas deportivas, atletismo, gimnasia, pelota, artes marciales, deportes acuáticos, actividades culturales y deportivas',
    text97: 'Arte',
    text98: 'Música, danza, teatro, cine y televisión, bellas artes, diseño',
    text99: 'Clientes de marca cooperativos',
    text100: 'En el último año, ha cooperado',
    text101: 'Cliente',
    text102: 'Casos de servicio',
    text103: 'Baidu',
    text104: 'Tecnología de dientes inteligentes',
    text105: 'Haooubo',
    text106:
      'Entre todos los proveedores, la traducción excelente es rápida, precisa, fluida y natural, y satisface nuestras necesidades. Su calidad profesional y actitud de servicio nos satisfacen mucho y esperamos seguir cooperando en el futuro.',
    text107: 'Respuesta oportuna',
    text108: 'La calidad de la traducción es buena',
    text109: 'Buena relación calidad - precio',
    text110: 'Buena actitud de servicio',
    text111: 'Estudios de casos',
    text112:
      'Anteriormente, en cooperación con otras empresas de traducción, no entendían en absoluto la localización. después de cooperar con youyi, Youyi proporcionará varias tablas de necesidades de localización. seguimos la tabla para explicar el contenido de la traducción. la traducción es precisa, profesional y eficiente, lo que también nos permite aprender mucho sobre la localización.',
    text113: 'Traducción precisa',
    text114: 'Profesional y eficiente',
    text115: 'Entrega rápida',
    text116: 'Buena actitud de servicio',
    text117:
      'Tanto la profesionalidad como la respuesta son muy buenas, y su fuerte capacidad de procesamiento multilingüe nos beneficia mucho. además del soporte tradicional de traducción de documentos, también se puede conectar técnicamente con nuestro sistema interno para lograr la adquisición y entrega oportuna de contenido a traducir, lo que nos proporciona una gran comodidad.',
    text118: 'Especialidad en traducción',
    text119: 'Muy auténtico',
    text120: 'Buena relación calidad - precio',
    text121: 'Buena actitud de servicio',
    text122: 'Selección de expertos en traducción',
    text123: '¡¡ reúne a expertos en traducción en varios idiomas y campos para proporcionarle servicios de traducción profesionales y precisos!',
    text124: 'Calificación honorífica',
    text125: 'Youyi.com es la Plataforma de infraestructura de la industria, que empodera a toda la industria.',
    text126: 'Servicios de consultoría',
    text127: 'Sobre nosotros',
    text128: 'Perfil de la empresa',
    text129: 'Proceso de desarrollo',
    text130: 'Cultura corporativa',
    text131: 'Honor corporativo',
    text132: 'Contáctanos',
    text133: 'Haga clic para consultar el servicio al cliente',
    text134: 'Teléfono',
    text135: 'Teléfono móvil',
    text136: 'Wechat escanea la consulta',
    text137: 'Operación de nuevos medios',
    text138: 'Indonesio',
    text139: 'Indonesio y chino,',
    text140:
      'Su lengua materna es el indonesio y ahora se encuentra en china. su licenciatura se especializa en lengua y literatura chinas en la Universidad de Suzhou y su maestría en chino como lengua extranjera en la Universidad de zhejiang. desde su graduación, ha proporcionado servicios de traducción a muchas empresas de traducción, como chuanshen Language Union Translation company, Language Bridge y Translation Country translation.',
    text141: 'Academia China de Ciencias Médicas',
    text142: 'Inglés',
    text143: 'Facultad de Medicina de China occidental de la Universidad de Sichuan',
    text144:
      'Se graduó y ahora enseña en la facultad de Medicina de Shanghai de la Universidad de fudan. su principal dirección de investigación es la biotecnología farmacéutica. está familiarizado con el proceso de producción e inspección de medicamentos y la información médica. ha publicado varios artículos en inglés médico en The Lancet y revistas extranjeras. ha traducido documentos de ensayos clínicos, instrucciones de medicamentos e Informes de investigación de diagnóstico.',
    text145: 'Comercio Exterior',
    text146: 'Japonés N1',
    text147: 'Enviar públicamente a Japón a estudiar en el extranjero,',
    text148:
      'Después de graduarse, trabajó en comercio exterior en Mitsubishi Commercial co., Ltd. de japón, y luego se modificó a nacionalidad japonesa, con un certificado de primer nivel en japonés. ha intercambiado y cooperado con Itochu Commercial co., ltd., Marubeni co., Ltd. y Mitsui Products co., Ltd. de Japón. su principal campo de traducción es la traducción de comercio exterior chino - japonés.',
    text149: 'Traducción subtitulada',
    text150: 'Tailandés',
    text151:
      'Ha estado involucrado en la traducción de subtítulos, noticias y sitios web, y ha sido miembro del Grupo de subtítulos de cine y televisión renren, el Grupo de subtítulos bts, el Grupo de subtítulos fst, el Grupo de subtítulos de drama tailandés tianfu y el Grupo de subtítulos the, y ha participado en la traducción de subtítulos de cine y televisión como la versión tailandesa "xiangmi hunde brasas como escarcha", "chang \'an 12 horas" y "beautiful Li huizhen".',
    text152: 'Economía y Finanzas',
    text153: 'Francés',
    text154: 'Licenciatura en economía aplicada por la Universidad de Ciencias Políticas de parís, Francia',
    text155:
      'Se graduó, se graduó con una maestría en economía aplicada de la Universidad de Finanzas y economía de Tianjin y un doctorado en economía y comercio de la Universidad de negocios internacionales y economía. durante sus estudios en el extranjero, Estudió chino y aprobó el examen de nivel chino de seis niveles. ahora trabaja en la bolsa de valores francesa y se dedica principalmente a la traducción chino - francesa relacionada con la economía y las finanzas.',
    text156: 'Traducción Jurídica',
    text157: 'árabe',
    text158: 'Derecho en la Universidad de el cairo, Egipto',
    text159:
      'Licenciatura, Maestría en derecho por la Universidad de ciencias políticas y derecho de china, Estudió chino en el Instituto Confucio de la Universidad de El Cairo y la Universidad de estudios extranjeros de beijing, aprobó el examen de nivel chino en seis niveles y ahora trabaja en un bufete de abogados egipcio, proporcionando asistencia jurídica y servicios de traducción legal a empresas y fábricas, con ocho años de experiencia en traducción legal.',
    text160: 'Maquinaria de construcción',
    text161: 'Lengua materna rusa',
    text162: 'Nacionalidad rusa',
    text163:
      'Traductor, Licenciado en lengua y literatura china por la Universidad Estatal de moscú, Licenciado en ingeniería civil por la Universidad de wuhan, comenzó a trabajar en la industria de la traducción en 2006 y hasta ahora ha proporcionado servicios de traducción a oficinas de traducción, escuelas, empresas y editoriales, principalmente en maquinaria de construcción.',
    text164: 'Traducción de cómics',
    text165: 'Coreano topik6',
    text166: 'Licenciatura en diseño de animación de la Universidad de comunicación de China',
    text167:
      'Especialidad, Maestría en coreano en la Universidad de seúl, Corea del sur, a través de la traducción coreana catti ii, coreano topik6, durante sus estudios en Corea del sur, se dedicó al diseño de cómics y la traducción de cómics a tiempo parcial en compañías de animación como Dr movie, daewon media y akom, entusiastas de la segunda dimensión.',
    text168: 'Certificado de calificación crediticia empresarial',
    text169: 'Certificado de certificación del sistema de gestión de la calidad',
    text170: 'Certificado de certificación del sistema de gestión de la propiedad intelectual',
    text171: 'Certificado de certificación del sistema de gestión de servicios de tecnología de la información',
    text172: 'Certificado de certificación del sistema de gestión de la seguridad de la información',
    text173: 'Certificado de empresa de alta tecnología',
    text174: 'Vicepresidente de la Asociación de traductores de Chengdu',
    text175: 'Miembro del Comité de servicios de traducción de la Asociación China de traductores',
    text176: 'Certificado de membresía de la Asociación de traductores de China',
    text177: 'Certificado de graduación del tercer campo de entrenamiento del acelerador Baidu ai',
    only_customer: 'soy su servicio al cliente exclusivo',
    free_quote: 'escanear el Código para obtener una oferta gratis',
    good_quote: 'obtener ofertas de alta calidad de forma gratuita',
    phone: 'línea directa de consulta',
    hot_service1: 'servicio popular',
    hot_service2: 'traducción de tesis',
    hot_service3:
      'concéntrese en proporcionar servicios de traducción de tesis de alta calidad a estudiantes, académicos y profesionales, dominando la terminología y el estilo lingüístico en diversas disciplinas. También ofrecemos servicios rápidos, precisos y económicos.',
    hot_service4: 'traducción de currículum',
    hot_service5:
      'ya sea que esté buscando oportunidades de trabajo internacionales o necesite solicitar un puesto en una empresa extranjera, podemos proporcionarle un servicio de traducción de currículum de alta calidad para comprender y transmitir con precisión su experiencia laboral, habilidades y logros.',
    hot_service6: 'traducción de materiales de solicitud para estudiar en el extranjero',
    hot_service7:
      'ya sea una transcripción académica, una carta de recomendación, una declaración personal u otros materiales de solicitud, podemos traducirlos con precisión al idioma objetivo, prestar atención a los detalles, garantizar la precisión y hacer que sus materiales de solicitud sean más profesionales y convincentes.',
    hot_service8: 'bajo precio',
    hot_service9: '“rentable”',
    hot_service10: 'a través de la tecnología, proporcionar a los clientes con',
    hot_service101: 'servicios de traducción',
    hot_service11: 'garantía de calidad',
    hot_service12: 'rápido',
    hot_service13: 'respuesta oportuna',
    hot_service14: 'proceso de producción de traducción inteligente',
    hot_service15: 'simplifica considerablemente la gestión del proceso de traducción',
    hot_service16:
      'en comparación con las empresas de traducción tradicionales, nuestra distribución, reciclaje e inspección de documentos reemplaza completamente a los gerentes de proyectos con robots',
    hot_service17: 'automatización de procesos',
    hot_service18: 'reducir los costos de gestión',
    hot_service19: 'reducir costos y mejorar la eficiencia',
    hot_service20: 'rápido',
    hot_service21: 'motor de vuelco de la máquina vertical autodesarrollado y entrenado',
    hot_service22: 'la calidad de la traducción puede alcanzar el nivel de traductor profesional de idiomas extranjeros',
    hot_service23: '¡ proporcionar servicios de traducción profesionales, precisos y rentables a través de la tecnología!',
    hot_service24: 'ai translation',
    hot_service25: 'revisión manual',
    hot_service26: 'pulido manual',
    text178: 'Inversión del Sasac de chengdu, jieshi, empresarios alemanes, saichuang, nueva traducción, etc.',
    text179: 'Baidu, Sasac de chengdu, jieshi, empresarios alemanes, saichuang, nueva traducción y otras inversiones'
  },
  pt: {
    text1: 'Através da tecnologia',
    text2: 'Fornecer aos clientes',
    text3: 'Rácio de desempenho dos custos',
    text4: 'Serviços de tradução para',
    text5: 'ser de qualidade uniforme',
    text6: 'Preço baixo',
    text7: 'Resposta atempada',
    text8: 'Investimentos de Baidu, Tecnologia para Capital de Risco, Jieshi, Deshang, Saichuang, Xinyi, etc',
    text9: 'Seleccionado para o Acelerador IA Tencent',
    text10: 'Julho de 2017',
    text11: 'Seleccionado para o Acelerador AI Baidu',
    text12: 'Agosto de 2018',
    text13: 'Selecionado para PwC New Economy Accelerator',
    text14: 'Dezembro de 2019',
    text15: 'Objectivo dos serviços de tradução',
    text16: 'Fornecer serviços rentáveis baseados em fins de tradução',
    text17: 'Leia e use por conta própria',
    text18: 'Basicamente preciso e fluente, capaz de entender a ideia principal do artigo apenas lendo a tradução, como leitura de notícias',
    text19: 'Readability',
    text20: 'A partir de yuan/mil palavras',
    text21: 'Faça uma encomenda imediatamente',
    text22: 'Leitura e utilização regulares dos clientes',
    text23:
      'Exata e fluente, a tradução pode expressar totalmente as informações originais, sem erros gramaticais, erros baixos ou omissões de informações-chave. Por exemplo, documentos de licitação, manuais, manuais de produto',
    text24: 'precisão',
    text25: 'Profissionalismo',
    text26: 'Leitura e utilização dos principais clientes',
    text27:
      'A tradução pode expressar plenamente o significado do texto original e cumprir com os hábitos de expressão da indústria. Por exemplo, manuais técnicos, contratos, materiais de produtos, etc., divulgados ao público',
    text28: 'Fluência',
    text29: 'Registo médico e pedido',
    text30: 'Por exemplo: pedido de registo médico, pedido de registo de dispositivos médicos',
    text31: 'idiomaticidade',
    text32: 'UI do produto, marketing, patentes, publicações',
    text33: 'Por exemplo: interface do software, interface do jogo, interface do site, patentes publicadas, monografias',
    text34: 'Interface do Software',
    text35: 'Interface do Jogo',
    text36: 'Monografia da patente',
    text37: 'Âmbito do serviço de tradução',
    text38: 'Suporta 65 idiomas, multi domínio manual tradução profissional',
    text39: 'Como fornecer uma tradução rentável?',
    text40: 'Tecnologia, Criando Diferenças',
    text41: 'Baixo custo',
    text42: 'Realizar a automação completa da cadeia de produção de tradução desde a colocação do pedido do cliente até a entrega;',
    text43:
      'Crie projetos automaticamente, combine com precisão tradutores adequados de acordo com os requisitos do projeto, aloque automaticamente revisão e digitação e reduza os custos trabalhistas',
    text44: 'Garantia da qualidade',
    text45:
      'A plataforma de tradução auto-desenvolvida fornece ferramentas para verificação de erros baixos, polimento e outros efeitos para ajudar os tradutores a concluir inspeções de qualidade',
    text46: 'Excelente experiência de serviço',
    text47:
      'O processo de produção de tradução é totalmente transparente; Faça pedidos on-line e obtenha traduções, acompanhe o progresso da tradução a qualquer momento',
    text48: 'Muitos tradutores',
    text49: 'A plataforma possui um rigoroso mecanismo de seleção e gerenciamento de tradutores, acumulando mais de 3000 tradutores ativos de alta qualidade',
    text50: 'Velocidade rápida',
    text51: '1000 palavras em apenas 6 horas',
    text52: 'Custos elevados da mão-de-obra',
    text53: 'Confiando totalmente no trabalho manual;',
    text54:
      'Análise manual do projecto ->Atribuição manual da tradução ->Coordenação manual e revisão ->Coordenação manual e tipografia ->Arranjo manual da inspecção da qualidade ->Entrega',
    text55: 'Confiança profunda em conhecimentos manuais e atitude de trabalho',
    text56: 'Confiando no progresso do feedback de vendas, longo tempo de espera e ansiedade',
    text57: '100 pessoas',
    text58: '1000 palavras leva 48 horas',
    text59: 'Localização do Software',
    text60: 'Localização do site, UI, documentos de ajuda, cópia de marketing',
    text61: 'engenharia',
    text62: 'Engenharia civil, conservação da água, construção, encanamento, engenharia química, documentos de licitação',
    text63: 'computador',
    text64: 'Rede, Segurança da Informação, Internet das Coisas, Inteligência Artificial, Computação em Nuvem',
    text65: 'outros',
    text66: 'Estratégico e tático, terreno e geografia, aeroespacial, equipamentos, engenharia nuclear',
    text67: 'Transportes',
    text68: 'Transporte rodoviário, transporte ferroviário, transporte fluvial, transporte aéreo',
    text69: 'medicina',
    text70:
      'Saúde, Medicina Básica, Medicina Clínica, Estomatologia, Medicina Tradicional Chinesa, Farmácia, Tecnologia Médica, Enfermagem, Dispositivos Médicos',
    text71: 'Ciências Sociais Humanidades',
    text72: 'Literatura, Educação, Gestão, História, Psicologia, Jornalismo, Marketing',
    text73: 'Da Wen Yu',
    text74: 'Jogos, artigos online, quadrinhos, anime, dramas de cinema e televisão',
    text75: 'serviço público',
    text76: 'Tradução de documentos, documentos governamentais, pontos turísticos e atrações',
    text77: 'Política e Direito',
    text78: 'Política e Administração, Diplomacia, Política Internacional, Direito, Contratos',
    text79: 'Económica e financeira',
    text80: 'Economia, Finanças, Tributação, Comércio, Investimento, Seguros',
    text81: 'Máquinas e instrumentos',
    text82: 'Máquinas, automóveis, instrumentos, instrumentos',
    text83: 'Produção de energia e energia',
    text84: 'Carvão, petróleo, gás natural, electricidade, nova energia',
    text85: 'Electrónica e Electrónica',
    text86: 'Eletrônica, Circuito, Comunicação, Controle, Sinal, Automação',
    text87: 'Geologia&Mineração&Metalurgia',
    text88: 'Geologia, Mineração, Metalurgia',
    text89: 'Indústria têxtil e leve e artesanato',
    text90: 'Têxtil, vestuário, embalagem, impressão, tingimento, móveis, couro, relógios, alimentos',
    text91: 'Ciência e Segurança Ambiental',
    text92:
      'HSE, tratamento de esgotos, tratamento de lixo, conservação de energia e redução de emissões, avaliação de impacto ambiental, engenharia de segurança',
    text93: 'agricultura',
    text94: '1 - 2 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3 - 3',
    text95: 'desporto',
    text96:
      'Locais e equipamentos esportivos, técnicas esportivas, atletismo, ginástica, jogos de bola, artes marciais, esportes aquáticos, atividades culturais e esportivas',
    text97: 'Arte',
    text98: 'Música, dança, drama, cinema e televisão, arte, design',
    text99: 'Clientes de marcas cooperativas',
    text100: 'No ano passado, trabalhamos juntos',
    text101: 'cliente',
    text102: 'Casos de serviço',
    text103: 'Baidu',
    text104: 'Tecnologia de Dentes de Sabedoria',
    text105: 'Haooubo',
    text106:
      'Entre todos os fornecedores, a tradução da Youyi é rápida, precisa, fluente e natural, atendendo às nossas necessidades. Estamos muito satisfeitos com sua qualidade profissional e atitude de serviço, e estamos ansiosos para continuar nossa cooperação no futuro.',
    text107: 'Resposta atempada',
    text108: 'Boa qualidade de tradução',
    text109: 'Elevada relação custo-eficácia',
    text110: 'Boa atitude de serviço',
    text111: 'estudo de caso',
    text112:
      'Anteriormente, trabalhamos com outras empresas de tradução, mas elas não tinham conhecimento de localização. Depois de trabalhar com Youyi, Youyi forneceu várias tabelas de requisitos de localização. Fornecemos conteúdo de tradução de acordo com a tabela, que era preciso, profissional e eficiente, permitindo-nos aprender muito conhecimento de localização.',
    text113: 'Tradução precisa',
    text114: 'Profissional e eficiente',
    text115: 'Velocidade rápida de envio',
    text116: 'Boa atitude de serviço',
    text117:
      'Tanto o profissionalismo como a capacidade de resposta são excelentes, e as suas poderosas capacidades de processamento multilíngue têm-nos beneficiado grandemente. Além do suporte tradicional à tradução de documentos, também pode ser tecnicamente integrado aos nossos sistemas internos para obter e entregar atempadamente o conteúdo a traduzir, proporcionando-nos uma grande comodidade.',
    text118: 'Tradução major',
    text119: 'Muito autêntico',
    text120: 'Elevada relação custo-eficácia',
    text121: 'Boa atitude de serviço',
    text122: 'Peritos de Tradução Seleccionados',
    text123: 'Reúna especialistas em tradução de vários idiomas e áreas para lhe fornecer serviços de tradução profissionais e precisos!',
    text124: 'Qualificações honorárias',
    text125: 'Youyi Network é a plataforma de infraestrutura da indústria, capacitando toda a indústria',
    text126: 'serviço de consultoria',
    text127: 'Sobre nós',
    text128: 'Introdução da Empresa',
    text129: 'histórico de desenvolvimento',
    text130: 'cultura empresarial',
    text131: 'Honras Corporativas',
    text132: 'entre em contato conosco',
    text133: 'Clique para consultar o serviço ao cliente',
    text134: 'telefone',
    text135: 'telemóvel',
    text136: 'Verificar a Consulta do WeChat',
    text137: 'Operações de Novos Meios de Comunicação',
    text138: 'Indonésio',
    text139: 'Descendência indonésia chinesa,',
    text140:
      'Minha língua materna é indonésio, e atualmente resido na China. Estudei Língua Chinesa e Literatura na Universidade de Suzhou e obtive um mestrado em Chinês como Língua Estrangeira na Universidade de Zhejiang. Desde a graduação, prestei serviços de tradução para várias empresas de tradução, como Chuanshen Yulian Translation Company, Language Bridge e Translate to China. Minhas principais áreas são novas mídias e operações.',
    text141: 'Academia Chinesa de Ciências Médicas',
    text142: 'Inglês',
    text143: 'Faculdade de Medicina da China Ocidental da Universidade de Sichuan',
    text144:
      'Formado, atualmente lecionando na Faculdade de Medicina de Xangai, Universidade Fudan. Minha principal direção de pesquisa é biotecnologia farmacêutica, familiarizado com processos de produção e inspeção de medicamentos e informações médicas. Publiquei vários artigos médicos em inglês na Lancet e revistas estrangeiras, traduzi documentos de ensaios clínicos, instruções de medicamentos, relatórios de pesquisa diagnóstica, etc.',
    text145: 'Comércio externo e comércio',
    text146: 'Japonês N1',
    text147: 'Envio de estudantes japoneses para estudar no estrangeiro,',
    text148:
      'Depois de me formar, trabalhei em comércio exterior na Mitsubishi Corporation no Japão, e mais tarde mudei minha nacionalidade para japonês. Obtive um certificado japonês Nível 1 e tive intercâmbios e cooperação com a Itochu Corporation, Marubeni Corporation e Mitsui Corporation. Minha principal área de tradução é a tradução de comércio exterior chinês japonês.',
    text149: 'tradução do subtítulo',
    text150: 'Tailandês',
    text151:
      'Tenho sido um membro do grupo de legendas da Renren Film and Television, BTS subtitles group, FST subtitles group, Tianfu Thai drama subtitles group, and THE subtitles group, e tenho participado da tradução de versões tailandesas de legendas de filmes e televisão, como "Fragrant Honey Sinks Like Frost", "Chang\'an Twelve Hours" e "Beautiful Li Huizhen".',
    text152: 'Económica e financeira',
    text153: 'Francês',
    text154: 'Bacharelado em Economia Aplicada pela Universidade de Paris, França',
    text155:
      'Graduado em Economia Aplicada pela Tianjin University of Finance and Economics e doutorado em Economia e Comércio pela University of International Business and Economics. Durante meu estudo no exterior, estudei chinês e passei no Teste de Proficiência Chinesa Band 6. Atualmente, trabalho na Bolsa de Valores Francesa, principalmente envolvida em tradução para o francês chinês relacionada à economia e finanças.',
    text156: 'tradução jurídica',
    text157: 'Árabe',
    text158: 'Direito na Universidade do Cairo, Egito',
    text159:
      'Bacharelado, Mestre em Direito pela Universidade de Ciência Política e Direito da China, estudou chinês no Instituto Confúcio na Universidade do Cairo e na Universidade de Estudos Internacionais de Pequim, passou no Teste de Proficiência Chinesa Band 6. Atualmente trabalhando em um escritório de advocacia egípcio, fornecendo assistência jurídica e serviços de tradução jurídica para empresas e fábricas, com 8 anos de experiência em tradução jurídica.',
    text160: 'máquinas de construção',
    text161: 'Língua nativa russa',
    text162: 'Nacionalidade russa',
    text163:
      'Tradutor, formado em Língua Chinesa e Literatura pela Universidade Estadual de Moscou e Mestre em Engenharia Civil pela Universidade Wuhan. Desde 2006, atua na indústria da tradução e presta serviços de tradução a escritórios de tradução, escolas, empresas e editoras. Sua principal área de tradução é a engenharia de máquinas.',
    text164: 'Tradução de mangá',
    text165: 'Nível TOPIK6 coreano',
    text166: 'Estudante de graduação em Design de Animação na Universidade de Comunicação da China',
    text167:
      'Major: Mestre em coreano na Universidade de Seul, na Coreia do Sul, passou pela tradução Coreana CATTI Nível II e Coreano TOPIK6. Durante seu estudo na Coreia do Sul, ele estava envolvido em design de desenhos animados e tradução de desenhos animados a tempo parcial na DR Movie, Daewon Media, AKOM e outras empresas de animação, e era um entusiasta de anime.',
    text168: 'Certificado de notação de crédito empresarial',
    text169: 'Certificado de Certificação do Sistema de Gestão da Qualidade',
    text170: 'Certificação do Sistema de Gestão da Propriedade Intelectual',
    text171: 'Certificação do Sistema de Gestão de Serviços de Tecnologia da Informação',
    text172: 'Certificado de Certificação do Sistema de Gestão da Segurança da Informação',
    text173: 'Certificado de empresa de alta tecnologia',
    text174: 'Unidade Vice-Presidente da Associação de Tradução de Chengdu',
    text175: 'Unidade membro do Comité dos Serviços de Tradução da Associação de Tradução da China',
    text176: 'Certificado de Membro da Associação de Tradução da China',
    text177: 'Certificado de Graduação de Baidu AI Accelerator Terceiro Campo de Treinamento',
    only_customer: 'Sou o seu serviço exclusivo ao cliente',
    free_quote: 'Digitalize o código QR para obter uma cotação gratuita',
    good_quote: 'Obter cotações de alta qualidade gratuitamente',
    phone: 'Linha directa de consulta',
    hot_service1: 'Serviços populares',
    hot_service2: 'Tradução em papel',
    hot_service3:
      'Focando em fornecer serviços de tradução de papel de alta qualidade para estudantes, acadêmicos e profissionais, proficientes em vários estilos de terminologia e linguagem em várias áreas temáticas. Nós também fornecemos serviços rápidos, precisos e econômicos',
    hot_service4: 'Resume translation',
    hot_service5:
      'Se você está procurando oportunidades de emprego internacional ou se candidatando a uma posição em uma empresa estrangeira, podemos fornecer serviços de tradução de currículo de alta qualidade para entender e transmitir com precisão sua experiência de trabalho, habilidades e conquistas.',
    hot_service6: 'Tradução de materiais de candidatura para estudos no estrangeiro',
    hot_service7:
      'Quer se trate de transcrições acadêmicas, cartas de recomendação, declarações pessoais ou outros materiais de aplicação, podemos traduzi-los com precisão para o idioma de destino, prestar atenção aos detalhes, garantir precisão e tornar seus materiais de aplicação mais profissionais e persuasivos.',
    hot_service8: 'Preço baixo',
    hot_service9: '“Custo desempenho”',
    hot_service10: 'Através da tecnologia, forneça aos clientes',
    hot_service101: 'Translation service for',
    hot_service11: 'Garantia de qualidade',
    hot_service12: 'Rápido',
    hot_service13: 'Timely response',
    hot_service14: 'Processo de produção inteligente de tradução',
    hot_service15: 'Simplifica significativamente a gestão dos processos de tradução',
    hot_service16:
      'Comparado às empresas de tradução tradicionais, nossa distribuição de documentos, reciclagem e inspeção substituíram completamente os gerentes de projeto por robôs',
    hot_service17: 'Process Automation',
    hot_service18: 'Reduzir os custos de gestão',
    hot_service19: 'Redução de custos e melhoria da eficiência',
    hot_service20: 'Velocidade rápida',
    hot_service21: 'Motor de viragem vertical auto-desenvolvido e treinado',
    hot_service22: 'A qualidade da tradução pode atingir o nível de um tradutor profissional de língua estrangeira',
    hot_service23: 'Através da tecnologia, fornecemos serviços de tradução profissionais, precisos e econômicos!',
    hot_service24: 'AI translation',
    hot_service25: 'Revisão manual',
    hot_service26: 'Polimento manual',
    text178: 'Investimento da Comissão de Supervisão e Administração de Ativos Estatais de Chengdu, Jieshi, Deshang, Saichuang, Xinyi, etc.',
    text179: 'Investimento de Baidu, Chengdu State owned Assets Supervision and Administration Commission, Jieshi, Deshang, Saichuang, Xinyi, etc.'
  }
}
