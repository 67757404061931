import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from '@/i18n'
import store from '@/store'
import directives from '@/directive'
import startMicro from './micro'
import MetaInfo from 'vue-meta-info'
import { $bus } from '@/utils/const'
import Components from '@/components'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import '@/public/css/base.css'
import '@/public/css/reset.less'
import 'nprogress/nprogress.css'

// import "element-plus/theme-chalk/el-loading.css";
// import "element-plus/theme-chalk/el-message.css";
// import "element-plus/theme-chalk/el-notification.css";
// import "element-plus/theme-chalk/el-message-box.css";

const app = createApp(App)

// 全局声明
declare module 'vue' {
  export interface ComponentCustomProperties {
    $t: any
  }
}

// 启动微服务
startMicro({
  prefetch: false,
  excludeAssetFilter: (assetUrl) => {
    const whiteList: any = []
    const whiteWords = ['baidu', 'apiConfig']
    if (whiteList.includes(assetUrl)) {
      return true
    }
    return whiteWords.some((w) => {
      return assetUrl.includes(w)
    })
  }
})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

const myPlugin = {
  install(app: any) {
    document.dispatchEvent(new Event('render-event'))
  }
}

app.config.globalProperties.$bus = $bus

app.use(router).use(i18n).use(directives).use(Components).use(store).use(myPlugin).use(MetaInfo).mount('#app')
