/*
 * @Author: chenxin
 * @Date: 2023-06-06 14:29:07
 * @Description: CAT语言模块
 */
export default {
  zh: {
    text1: '全球领先的语言服务与语言技术',
    text2: '综合解决方案提供商',
    text3: '您好，请登录！',
    text4: '手机快捷登录',
    text5: '账号密码登录',
    text6: '手机号',
    text7: '请输入手机验证码',
    text8: '获取验证码',
    text9: '手机号',
    text10: '请输入登录密码',
    text11: '图文验证码',
    text12: '换一张',
    text13: '登录',
    text14: '没有帐号？',
    text15: '立即注册',
    text16: '忘记密码？',
    text17: '其他方式登录',
    text18: '您好，欢迎注册！',
    text19: '手机号',
    text20: '验证码',
    text21: '获取验证码',
    text22: '7-20位字符，大小写字母及数字',
    text23: '再次确认密码，7-20位字符，大小写字母及数字',
    text24: '我已阅读并同意',
    text25: '服务条款',
    text26: '注册',
    text27: '已有帐号？',
    text28: '立即登录'
  },
  en: {
    text1: 'Global leading language services and technology',
    text2: 'Comprehensive solution provider',
    text3: 'Hello, please log in!',
    text4: 'Quick login on mobile phone',
    text5: 'Account password login',
    text6: 'Mobile phone number',
    text7: 'Please enter your phone verification code',
    text8: 'Obtain verification code',
    text9: 'Mobile phone number',
    text10: 'Please enter your login password',
    text11: 'Graphic verification code',
    text12: 'Change one',
    text13: 'Login',
    text14: 'No account?',
    text15: 'Register Now',
    text16: 'Forgot password?',
    text17: 'Other login methods',
    text18: 'Hello, welcome to register!',
    text19: 'Mobile phone number',
    text20: 'Verification code',
    text21: 'Obtain verification code',
    text22: '7-20 characters, uppercase and lowercase letters and numbers',
    text23: 'Confirm password again, 7-20 characters, uppercase and lowercase letters and numbers',
    text24: 'I have read and agree',
    text25: 'Service Terms',
    text26: 'register',
    text27: 'Existing account?',
    text28: 'Log in now'
  },
  ja: {
    text1: '世界をリードする言語サービスと言語テクノロジー',
    text2: '統合ソリューションプロバイダ',
    text3: 'こんにちは、ログインしてください！',
    text4: '携帯電話のショートアクセス',
    text5: 'アカウントパスワードログイン',
    text6: '携帯番号',
    text7: '携帯電話の認証コードを入力してください',
    text8: '認証コードの取得',
    text9: '携帯番号',
    text10: 'ログインパスワードを入力してください',
    text11: '図文検証コード',
    text12: '1枚取り替える',
    text13: 'ログイン＃ログイン＃',
    text14: 'アカウントはありませんか？',
    text15: '今すぐ登録',
    text16: 'パスワードを忘れる？',
    text17: 'その他のログイン方法',
    text18: 'こんにちは、登録へようこそ！',
    text19: '携帯番号',
    text20: '認証コード',
    text21: '認証コードの取得',
    text22: '7-20ビット文字、大文字と小文字、数字',
    text23: 'パスワード、7～20ビット文字、大文字と小文字、数字を再確認する',
    text24: '読んで同意しました',
    text25: 'サービス条件',
    text26: '登録',
    text27: '既存のアカウント？',
    text28: '今すぐログイン'
  },
  ko: {
    text1: '세계 최고의 언어 서비스 및 언어 기술',
    text2: '종합적인 솔루션 공급업체',
    text3: '안녕하세요, 로그인하세요!',
    text4: '핸드폰 빠른 로그인',
    text5: '계정 비밀번호 로그인',
    text6: '핸드폰 번호',
    text7: '핸드폰 인증번호를 입력하세요.',
    text8: '인증 코드 가져오기',
    text9: '핸드폰 번호',
    text10: '로그인 암호를 입력하십시오.',
    text11: '그림 인증 코드',
    text12: '바꾸다',
    text13: '로그인',
    text14: '계좌번호 없어요?',
    text15: '지금 등록',
    text16: '비밀번호를 잊으셨습니까?',
    text17: '다른 방법으로 로그인',
    text18: '안녕하세요, 등록을 환영합니다!',
    text19: '핸드폰 번호',
    text20: '인증 코드',
    text21: '인증 코드 가져오기',
    text22: '7-20자리 문자, 대소문자 및 숫자',
    text23: '암호 다시 확인, 7-20자리, 대소문자 및 숫자',
    text24: '읽고 동의함',
    text25: '서비스 약관',
    text26: '등록',
    text27: '계정이 있습니까?',
    text28: '지금 로그인'
  },
  de: {
    text1: 'Weltweit führende Sprachdienstleistungen und -technologie',
    text2: 'Umfassender Lösungsanbieter',
    text3: 'Hallo, bitte einloggen!',
    text4: 'Schnelle Anmeldung über Mobiltelefon',
    text5: 'Konto Passwort Login',
    text6: 'Mobiltelefonnummer',
    text7: 'Bitte geben Sie den Bestätigungscode Ihres Telefons ein',
    text8: 'Überprüfungscode erhalten',
    text9: 'Mobiltelefonnummer',
    text10: 'Bitte geben Sie Ihr Login-Passwort ein',
    text11: 'Grafischer Prüfcode',
    text12: 'Einen ändern',
    text13: 'Login',
    text14: 'Kein Konto?',
    text15: 'Jetzt registrieren',
    text16: 'Passwort vergessen?',
    text17: 'Andere Anmeldemethoden',
    text18: 'Hallo, herzlich willkommen zur Anmeldung!',
    text19: 'Mobiltelefonnummer',
    text20: 'Prüfcode',
    text21: 'Überprüfungscode erhalten',
    text22: '7-20 Zeichen, Groß- und Kleinbuchstaben und Zahlen',
    text23: 'Passwort erneut bestätigen, 7-20 Zeichen, Groß- und Kleinbuchstaben und Zahlen',
    text24: 'Ich habe gelesen und stimme zu',
    text25: 'Servicebedingungen',
    text26: 'registrieren',
    text27: 'Bestehendes Konto?',
    text28: 'Jetzt anmelden'
  },
  fr: {
    text1: 'Leader mondial des services linguistiques et des technologies linguistiques',
    text2: 'Fournisseur de solutions intégrées',
    text3: 'Bonjour, veuillez vous connecter!',
    text4: 'Atterrissage rapide sur mobile',
    text5: 'Numéro de compte mot de passe login',
    text6: 'Numéro de téléphone portable',
    text7: 'Veuillez entrer le Code de vérification du téléphone portable',
    text8: 'Obtenir le Code de vérification',
    text9: 'Numéro de téléphone portable',
    text10: 'Veuillez entrer votre mot de passe de connexion',
    text11: 'Code de vérification de texte',
    text12: 'Changer une',
    text13: 'Se connecter',
    text14: 'Pas de compte?',
    text15: 'Inscrivez - vous maintenant',
    text16: 'Mot de passe oublié?',
    text17: 'Autres façons de se connecter',
    text18: 'Bonjour et bienvenue à vous inscrire!',
    text19: 'Numéro de téléphone portable',
    text20: 'Code de vérification',
    text21: 'Obtenir le Code de vérification',
    text22: '7 - 20 caractères, lettres majuscules et minuscules et chiffres',
    text23: 'Reconfirmez le mot de passe, 7 - 20 caractères, majuscules et minuscules et chiffres',
    text24: "J'ai lu et j'accepte",
    text25: 'Conditions de service',
    text26: 'Enregistrement',
    text27: 'Vous avez déjà un compte?',
    text28: 'Connectez - vous maintenant'
  },
  ru: {
    text1: 'Ведущие в мире языковые службы и языковые технологии',
    text2: 'Поставщики комплексных решений',
    text3: 'Здравствуйте, пожалуйста, войдите!',
    text4: 'Мобильник быстро приземлился',
    text5: 'Пароль учетной записи.',
    text6: 'Номер телефона',
    text7: 'Пожалуйста, введите код мобильного телефона.',
    text8: 'Получение кода проверки',
    text9: 'Номер телефона',
    text10: 'Введите пароль входа',
    text11: 'Код подтверждения диаграммы',
    text12: 'Смени.',
    text13: 'Регистрация',
    text14: 'Нет аккаунта?',
    text15: 'Немедленная регистрация',
    text16: 'Забыли пароль?',
    text17: 'Другие способы входа',
    text18: 'Здравствуйте, добро пожаловать регистрация!',
    text19: 'Номер телефона',
    text20: 'Код проверки',
    text21: 'Получение кода проверки',
    text22: '7 - 20 - битные символы, буквы и цифры',
    text23: 'Повторное подтверждение пароля, 7 - 20 символов, букв и цифр',
    text24: 'Я прочитал и согласился.',
    text25: 'Условия предоставления услуг',
    text26: 'Регистрация',
    text27: 'Уже есть аккаунт?',
    text28: 'Войти немедленно'
  },
  th: {
    text1: 'บริการภาษาและเทคโนโลยีภาษาชั้นนำระดับโลก',
    text2: 'ผู้ให้บริการโซลูชั่นครบวงจร',
    text3: 'สวัสดี กรุณาเข้าสู่ระบบ!',
    text4: 'เชื่อมโยงไปถึงโทรศัพท์มือถืออย่างรวดเร็ว',
    text5: 'หมายเลขบัญชี รหัสผ่าน เข้าสู่ระบบ',
    text6: 'หมายเลขโทรศัพท์มือถือ',
    text7: 'กรุณากรอกรหัสยืนยันโทรศัพท์มือถือ',
    text8: 'รับรหัสยืนยัน',
    text9: 'หมายเลขโทรศัพท์มือถือ',
    text10: 'กรุณากรอกรหัสผ่านเข้าสู่ระบบ',
    text11: 'รหัสยืนยันข้อความ',
    text12: 'เปลี่ยนใบ',
    text13: 'เข้าสู่ระบบ',
    text14: 'ไม่มีเลขที่บัญชี?',
    text15: 'ลงทะเบียนตอนนี้',
    text16: 'ลืมรหัสผ่าน?',
    text17: 'เข้าสู่ระบบด้วยวิธีอื่น',
    text18: 'สวัสดีและยินดีต้อนรับสู่การลงทะเบียน!',
    text19: 'หมายเลขโทรศัพท์มือถือ',
    text20: 'รหัสยืนยัน',
    text21: 'รับรหัสยืนยัน',
    text22: '7-20 หลักตัวอักษรกรณีและตัวเลข',
    text23: 'ยืนยันรหัสผ่านอีกครั้งด้วยตัวอักษร 7-20 หลักตัวพิมพ์ใหญ่และตัวเลข',
    text24: 'ฉันได้อ่านและยอมรับ',
    text25: 'เงื่อนไขการให้บริการ',
    text26: 'ลงทะเบียน',
    text27: 'มีเลขบัญชีอยู่แล้ว?',
    text28: 'เข้าสู่ระบบตอนนี้'
  },
  es: {
    text1: 'Los principales servicios lingüísticos y tecnologías lingüísticas del mundo',
    text2: 'Proveedor de soluciones integradas',
    text3: '¡Hola, por favor inicie sesión!',
    text4: 'Inicio de sesión rápido del teléfono móvil',
    text5: 'Iniciar sesión con la contraseña de la cuenta',
    text6: 'Número de teléfono móvil',
    text7: 'Por favor, introduzca el Código de verificación del teléfono móvil',
    text8: 'Obtener el Código de verificación',
    text9: 'Número de teléfono móvil',
    text10: 'Introduzca la contraseña de inicio de sesión',
    text11: 'Código de verificación gráfica',
    text12: 'Cambiar uno',
    text13: 'Iniciar sesión',
    text14: '¿No hay cuenta?',
    text15: 'Registrarse de inmediato',
    text16: '¿Olvidar la contraseña?',
    text17: 'Iniciar sesión de otras maneras',
    text18: '¡Hola, bienvenido a registrarse!',
    text19: 'Número de teléfono móvil',
    text20: 'Código de verificación',
    text21: 'Obtener el Código de verificación',
    text22: 'Caracteres de 7 - 20 dígitos, letras mayúsculas y minúsculas y números',
    text23: 'Confirme nuevamente la contraseña, 7 - 20 caracteres, letras mayúsculas y minúsculas y números',
    text24: 'He leído y estoy de acuerdo',
    text25: 'Cláusulas de servicio',
    text26: 'Registro',
    text27: '¿¿ ya tienes una cuenta?',
    text28: 'Iniciar sesión ahora'
  },
  pt: {
    text1: 'Líderes globais em serviços linguísticos e tecnologia',
    text2: 'Provedor de soluções abrangentes',
    text3: 'Olá, por favor faça o login!',
    text4: 'Login rápido no telemóvel',
    text5: 'Senha de autenticação da conta',
    text6: 'Número de telemóvel',
    text7: 'Introduza o código de verificação do telefone',
    text8: 'Obter código de verificação',
    text9: 'Número de telemóvel',
    text10: 'Por favor, introduza a sua senha de acesso',
    text11: 'Código de verificação gráfico',
    text12: 'Mudar um',
    text13: 'Login',
    text14: 'Sem conta?',
    text15: 'Registe-se Agora',
    text16: 'Esqueceste-te da senha?',
    text17: 'Outros métodos de autenticação',
    text18: 'Olá, bem-vindo ao registo!',
    text19: 'Número de telemóvel',
    text20: 'Código de verificação',
    text21: 'Obter código de verificação',
    text22: '7-20 caracteres, letras maiúsculas e minúsculas e números',
    text23: 'Confirme a senha novamente, 7-20 caracteres, letras maiúsculas e minúsculas e números',
    text24: 'Li e concordo',
    text25: 'Termos do Serviço',
    text26: 'registo',
    text27: 'Conta existente?',
    text28: 'Entrar agora'
  }
}
