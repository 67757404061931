import type { App } from 'vue'
import Footer from './Footer/index.vue'
import { GlobalHeader } from './GlobalHeader'
import SingleCutterText from './SingleCutterText/index.vue'
import AdvisoryService from './advisoryService/index.vue'

const components: { [key: string]: any } = {
  Footer,
  GlobalHeader,
  SingleCutterText,
  AdvisoryService
}

export default {
  install: (app: App<Element>) => {
    Object.keys(components).forEach((key) => {
      // 在app上进行扩展，app提供 component directive 函数
      // 如果要挂载原型 app.config.globalProperties 方式
      app.component(key, components[key])
    })
  }
}
