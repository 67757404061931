export default {
  zh: {
    text1: '地矿冶金行业翻译解决方案',
    text2:
      '我们的优译信息地矿冶金行业翻译解决方案专注于为地质、矿业和冶金领域的客户提供专业的翻译和本地化服务。无论是地质报告、勘探数据、冶金工艺说明还是矿产资源管理文档，我们都能够为您提供精准的翻译，确保信息在全球范围内传达一致和准确。',
    text3: '咨询服务',
    text4: '特点',
    text5: '行业专业知识',
    text6: '我们拥有地质、矿业和冶金领域的专业翻译人员，了解行业术语和领域知识，确保翻译内容准确无误',
    text7: '地质报告翻译',
    text8: '提供地质勘探报告、地质调查数据等翻译服务，确保地质信息在不同语言中的一致性和准确性',
    text9: '冶金工艺翻译',
    text10: '为冶金工艺说明、流程图等提供翻译支持，帮助您的工艺信息在全球范围内传达',
    text11: '矿产资源管理翻译',
    text12: '提供矿产资源管理文件、矿权合同等翻译服务，支持全球矿产项目管理',
    text13: '术语管理',
    text14: '管理地矿冶金领域术语数据库，确保在不同语言版本中使用一致的行业术语',
    text15: '价值',
    text16: '全球合作',
    text17: '通过翻译，您可以与国际合作伙伴和投资者分享地矿冶金信息，促进合作和发展。',
    text18: '行业传播',
    text19: '准确的翻译有助于将地质、矿业和冶金信息传播给全球同行，促进知识共享。',
    text20: '项目管理',
    text21: '提供矿产资源管理文件的翻译，帮助您进行国际矿产项目管理和合作。',

    text22: '市场拓展',
    text23: '通过翻译，您可以将您的地矿冶金产品和技术推广到全球市场，吸引更多潜在客户。',
    text24: '优势',
    text25: '行业专业团队',
    text26: '我们的团队由地矿冶金领域的专业翻译人员组成，能够确保行业翻译的准确性。',
    text27: '我们管理地矿冶金领域术语数据库，确保在不同语言版本中使用一致的行业术语。',
    text28: '本地化支持',
    text29: '考虑不同地区文化和技术背景的差异，确保翻译内容适应各种背景。',
    text30: '保密协议',
    text31: '在处理敏感技术信息时，我们严格遵守保密协议，确保信息安全。',
    text32: '紧急支持',
    text33: '我们的团队能够在紧急情况下提供快速的修复和支持，确保您的信息传达及时。',
    text34: '我们致力于为地矿冶金领域客户提供优秀的翻译支持，满足您的全球传播需求。',
    text35: '通过选择我们的地矿冶金行业翻译解决方案，您将获得专业、准确、保密性高的服务，有助于您的地质、矿业和冶金信息在全球范围内成功传播。'
  },
  en: {
    text1: 'Translation Solutions for the Mining and Metallurgy Industry',
    text2:
      "Our Youyi Information Mining and Metallurgy Industry Translation Solution focuses on providing professional translation and localization services to clients in the geological, mining, and metallurgical fields.Whether it's geological reports, exploration data, metallurgical process descriptions, or mineral resource management documents, we can provide you with accurate translations to ensure that information is communicated consistently and accurately on a global scale.",
    text3: 'consulting service',
    text4: 'characteristic',
    text5: 'Industry expertise',
    text6:
      'We have professional translators in the fields of geology, mining, and metallurgy, who understand industry terminology and domain knowledge to ensure the accuracy of translation content',
    text7: 'Translation of geological reports',
    text8:
      'Provide translation services such as geological exploration reports and geological survey data to ensure the consistency and accuracy of geological information in different languages',
    text9: 'Metallurgical Process Translation',
    text10: 'Provide translation support for metallurgical process instructions, flow charts, etc., to help your process information be communicated globally',
    text11: 'Translation of Mineral Resource Management',
    text12:
      'Provide translation services for mineral resource management documents, mining rights contracts, etc., to support global mineral project management',
    text13: 'Term management',
    text14:
      'Manage the terminology database in the field of geology, mining, and metallurgy to ensure consistent use of industry terminology across different language versions',
    text15: 'value',
    text16: 'Global cooperation',
    text17:
      'Through translation, you can share geological and metallurgical information with international partners and investors, promoting cooperation and development.',
    text18: 'Industry Communication',
    text19: 'Accurate translation helps to disseminate geological, mining, and metallurgical information to global peers and promote knowledge sharing.',
    text20: 'project management',
    text21: 'Provide translation of mineral resource management documents to assist you in international mineral project management and cooperation.',

    text22: 'Market expansion',
    text23:
      'Through translation, you can promote your geological and metallurgical products and technologies to the global market, attracting more potential customers.',
    text24: 'advantage',
    text25: 'Industry Professional Team',
    text26: 'Our team is composed of professional translators in the field of geology, mining, and metallurgy, ensuring the accuracy of industry translations.',
    text27:
      'We manage a terminology database in the field of geology, mining, and metallurgy to ensure consistent use of industry terminology across different language versions.',
    text28: 'Localization support',
    text29:
      'Consider the differences in cultural and technological backgrounds among different regions to ensure that the translated content adapts to various backgrounds.',
    text30: 'Confidentiality Agreement',
    text31: 'When handling sensitive technical information, we strictly adhere to confidentiality agreements to ensure information security.',
    text32: 'Emergency support',
    text33: 'Our team is able to provide rapid repair and support in emergency situations, ensuring timely communication of your information.',
    text34:
      'We are committed to providing excellent translation support to clients in the field of geology, mining, and metallurgy to meet your global communication needs.',
    text35:
      'By choosing our geological, mining, and metallurgical industry translation solution, you will receive professional, accurate, and highly confidential services that help your geological, mining, and metallurgical information to be successfully disseminated globally.'
  },
  ja: {
    text1: '地鉱冶金業界翻訳ソリューション',
    text2:
      'デルの優訳情報地鉱冶金業界翻訳ソリューションは、地質、鉱業、冶金分野のお客様に専門的な翻訳とローカライズサービスを提供することに重点を置いています。地質レポート、探査データ、冶金プロセスの説明、鉱物資源管理ドキュメントのいずれにおいても、情報が世界的に一貫して正確に伝達されるように、正確な翻訳を提供することができます。',
    text3: 'コンサルティングサービス',
    text4: '特徴',
    text5: '業界専門知識',
    text6: '地質、鉱業、冶金分野の専門翻訳者を擁し、業界用語や分野の知識を理解し、翻訳内容が正確であることを保証しています',
    text7: '地質報告書の翻訳',
    text8: '地質探査報告書、地質調査データなどの翻訳サービスを提供し、異なる言語における地質情報の一致性と正確性を確保する',
    text9: '冶金技術翻訳',
    text10: '冶金プロセスの説明、フローチャートなどの翻訳サポートを提供し、世界中のプロセス情報の伝達を支援します',
    text11: '鉱物資源管理翻訳',
    text12: '鉱物資源管理文書、鉱権契約などの翻訳サービスを提供し、世界の鉱物プロジェクト管理をサポートする',
    text13: '用語管理',
    text14: '地鉱冶金分野の用語データベースを管理し、異なる言語バージョンで一貫した業界用語を使用できるようにする',
    text15: 'の価値判断を',
    text16: 'グローバル・コラボレーション',
    text17: '翻訳を通じて、国際的なパートナーや投資家と地鉱冶金情報を共有し、協力と発展を促進することができます。',
    text18: '業界伝播',
    text19: '正確な翻訳は、地質、鉱業、冶金の情報を世界の同業者に伝え、知識の共有を促進するのに役立ちます。',
    text20: 'プロジェクト管理',
    text21: '鉱物資源管理ファイルの翻訳を提供し、国際鉱物プロジェクトの管理と協力を支援します。',
    text22: '市場開拓',
    text23: '翻訳を通じて、あなたの地鉱冶金製品と技術を世界市場に広め、より多くの潜在顧客を引き付けることができます。',
    text24: '利点',
    text25: '業界専門チーム',
    text26: '私たちのチームは地鉱冶金分野の専門翻訳者で構成されており、業界翻訳の正確性を確保することができます。',
    text27: '地鉱冶金分野の用語データベースを管理し、異なる言語バージョンで一貫した業界用語を使用することを確認します。',
    text28: 'ローカリゼーションのサポート',
    text29: '地域によって文化や技術的背景の違いを考慮し、翻訳内容がさまざまな背景に適応するようにします。',
    text30: '秘密保持契約',
    text31: '機密技術情報を処理する際には、機密保持契約を厳守し、情報の安全を確保します。',
    text32: '緊急サポート',
    text33: 'デルのチームは、緊急時に迅速な修復とサポートを提供し、お客様の情報をタイムリーに伝えることができます。',
    text34: 'デルは、世界的な普及ニーズに対応するために、鉱山冶金分野のお客様に優れた翻訳サポートを提供することに力を入れています。',
    text35:
      'デルの地鉱冶金業界翻訳ソリューションを選択することで、地質、鉱業、冶金情報の世界的な普及に役立つ、専門的で正確で機密性の高いサービスが提供されます。'
  },
  ko: {
    text1: '지광 야금 업계 번역 솔루션',
    text2:
      'Google의 우수한 번역 정보 광산 야금 산업 번역 솔루션은 지질, 광업 및 야금 분야의 고객에게 전문적인 번역 및 현지화 서비스를 제공하는 데 중점을 둡니다.지질 보고서, 탐사 데이터, 야금 공정 설명서 또는 광물 자원 관리 문서에 관계없이 우리는 정확한 번역을 제공하여 정보가 전 세계적으로 일관되고 정확하도록 보장할 수 있습니다.',
    text3: '컨설팅 서비스',
    text4: '특징',
    text5: '업계 전문 지식',
    text6: '우리는 지질, 광업 및 야금 분야의 전문 번역 인원을 보유하고 있으며, 업계 용어와 분야 지식을 이해하여 번역 내용이 정확하고 틀림없음을 확보한다',
    text7: '지질 보고서 번역',
    text8: '지질 탐사 보고서, 지질 조사 데이터 등 번역 서비스를 제공하여 서로 다른 언어에서 지질 정보의 일치성과 정확성을 확보한다',
    text9: '야금 공예 번역',
    text10: '야금 공정 설명, 공정도 등에 대한 번역 지원을 제공하여 귀하의 공정 정보가 전 세계적으로 전달되도록 돕습니다.',
    text11: '광산 자원 관리 번역',
    text12: '광물자원관리문건, 광권계약 등 번역서비스를 제공하고 글로벌광물프로젝트관리를 지원한다',
    text13: '용어 관리',
    text14: '지광야금 분야 용어 데이터베이스를 관리하여 서로 다른 언어 버전에서 일관된 업계 용어를 사용하도록 보장',
    text15: '가치',
    text16: '글로벌 협력',
    text17: '번역을 통해 국제 파트너 및 투자자와 광산 야금 정보를 공유하고 협력과 발전을 촉진 할 수 있습니다.',
    text18: '업계 전파',
    text19: '정확한 번역은 지질, 광업 및 야금 정보를 전 세계 동료들에게 전파하고 지식 공유를 촉진하는 데 도움이됩니다.',
    text20: '프로젝트 관리',
    text21: '광물 자원 관리 문서의 번역을 제공하여 국제 광물 프로젝트 관리와 협력을 도울 수 있습니다.',
    text22: '시장 확장',
    text23: '번역을 통해, 당신은 당신의 광산 야금 제품과 기술을 전 세계 시장에 보급하여 더 많은 잠재 고객을 끌어들일 수 있습니다.',
    text24: '이점',
    text25: '업계 전문가 팀',
    text26: '우리 팀은 산업 번역의 정확성을 보장 할 수있는 지광 야금 분야의 전문 번역가로 구성됩니다.',
    text27: '우리는 지광야금 분야의 용어 데이터베이스를 관리하여 서로 다른 언어 버전에서 일치하는 업계 용어를 사용하도록 확보한다.',
    text28: '현지화 지원',
    text29: '지역별 문화와 기술 배경의 차이를 고려하여 번역 내용이 다양한 배경에 적응할 수 있도록 한다.',
    text30: '비밀 유지 계약',
    text31: '중요한 기술 정보를 처리할 때 기밀 유지 계약을 철저히 준수하여 정보 보안을 보장합니다.',
    text32: '긴급 지원',
    text33: '우리 팀은 긴급 상황에서 신속한 복구와 지원을 제공하여 귀하의 정보가 적시에 전달되도록 보장할 수 있습니다.',
    text34: '우리는 광야금 분야의 고객에게 우수한 번역 지원을 제공하여 당신의 글로벌 전파 수요를 만족시키기 위해 노력합니다.',
    text35:
      '당사의 광산 야금 산업 번역 솔루션을 선택함으로써 귀하는 전문적이고 정확하며 비밀이 높은 서비스를 받게 될 것이며 귀하의 지질, 광업 및 야금 정보가 전 세계적으로 성공적으로 전파되는 데 도움이 될 것입니다.'
  },
  de: {
    text1: 'Übersetzungslösungen für den Bergbau und die Metallurgie',
    text2:
      'Unsere Youyi Information Mining and Metallurgy Industry Translation Solution konzentriert sich auf die Bereitstellung professioneller Übersetzungs- und Lokalisierungsdienstleistungen für Kunden aus den Bereichen Geologie, Bergbau und Metallurgie.Ob es sich um geologische Berichte, Explorationsdaten, metallurgische Prozessbeschreibungen oder Mineralressourcenmanagementdokumente handelt, wir können Ihnen genaue Übersetzungen liefern, um sicherzustellen, dass Informationen weltweit konsistent und genau kommuniziert werden.',
    text3: 'Beratung',
    text4: 'Charakteristik',
    text5: 'Branchenkenntnis',
    text6:
      'Wir verfügen über professionelle Übersetzer in den Bereichen Geologie, Bergbau und Metallurgie, die Branchenterminologie und Fachwissen verstehen, um die Genauigkeit der Übersetzungsinhalte sicherzustellen',
    text7: 'Übersetzung geologischer Berichte',
    text8:
      'Bereitstellung von Übersetzungsdiensten wie geologische Explorationsberichte und geologische Vermessungsdaten, um die Konsistenz und Genauigkeit geologischer Informationen in verschiedenen Sprachen sicherzustellen',
    text9: 'Metallurgische Prozessübersetzung',
    text10:
      'Übersetzungsunterstützung für metallurgische Prozessanweisungen, Flussdiagramme usw., damit Ihre Prozessinformationen global kommuniziert werden können',
    text11: 'Übersetzung des Mineralressourcenmanagements',
    text12: 'Übersetzungsdienste für Mineralressourcenmanagementdokumente, Bergbaurechtsverträge usw. zur Unterstützung des globalen Mineralprojektmanagements',
    text13: 'Terminverwaltung',
    text14:
      'Verwaltung der Terminologiedatenbank im Bereich Geologie, Bergbau und Metallurgie, um eine konsistente Verwendung der Branchenterminologie in verschiedenen Sprachversionen zu gewährleisten',
    text15: 'Wert',
    text16: 'Globale Zusammenarbeit',
    text17:
      'Durch Übersetzungen können Sie geologische und metallurgische Informationen mit internationalen Partnern und Investoren teilen und die Zusammenarbeit und Entwicklung fördern.',
    text18: 'Mitteilung der Industrie',
    text19:
      'Präzise Übersetzungen tragen dazu bei, geologische, bergbauliche und metallurgische Informationen an globale Kollegen zu verbreiten und den Wissensaustausch zu fördern.',
    text20: 'Projektmanagement',
    text21:
      'Bieten Sie Übersetzungen von Dokumenten zum Mineralressourcenmanagement an, um Sie beim internationalen Mineralprojektmanagement und der Zusammenarbeit zu unterstützen.',

    text22: 'Markterweiterung',
    text23:
      'Durch Übersetzungen können Sie Ihre geologischen und metallurgischen Produkte und Technologien auf dem globalen Markt bewerben und so mehr potenzielle Kunden gewinnen.',
    text24: 'Vorteil',
    text25: 'Industrie Professional Team',
    text26:
      'Unser Team besteht aus professionellen Übersetzern in den Bereichen Geologie, Bergbau und Metallurgie, die die Genauigkeit der Branchenübersetzungen sicherstellen.',
    text27:
      'Wir verwalten eine Terminologiedatenbank in den Bereichen Geologie, Bergbau und Metallurgie, um eine konsistente Verwendung der Branchenterminologie in verschiedenen Sprachversionen zu gewährleisten.',
    text28: 'Unterstützung bei der Lokalisierung',
    text29:
      'Berücksichtigen Sie die Unterschiede in kulturellen und technologischen Hintergründen zwischen den verschiedenen Regionen, um sicherzustellen, dass sich die übersetzten Inhalte an verschiedene Hintergründe anpassen.',
    text30: 'Vertraulichkeitsvereinbarung',
    text31:
      'Beim Umgang mit sensiblen technischen Informationen halten wir uns strikt an Vertraulichkeitsvereinbarungen, um die Informationssicherheit zu gewährleisten.',
    text32: 'Nothilfe',
    text33:
      'Unser Team ist in der Lage, eine schnelle Reparatur und Unterstützung in Notsituationen zu bieten, um sicherzustellen, dass Ihre Informationen rechtzeitig kommuniziert werden.',
    text34:
      'Wir sind bestrebt, Kunden aus den Bereichen Geologie, Bergbau und Metallurgie exzellente Übersetzungsunterstützung zu bieten, um Ihre globalen Kommunikationsanforderungen zu erfüllen.',
    text35:
      'Wenn Sie sich für unsere Übersetzungslösung für die geologische, bergbauliche und metallurgische Industrie entscheiden, erhalten Sie professionelle, genaue und vertrauliche Dienstleistungen, die Ihnen helfen, Ihre geologischen, bergbaulichen und metallurgischen Informationen weltweit erfolgreich zu verbreiten.'
  },
  fr: {
    text1: 'Solutions de traduction pour le secteur de la métallurgie minière',
    text2:
      "Nos solutions de traduction pour l'industrie de la métallurgie minière se concentrent sur la fourniture de services professionnels de traduction et de localisation à nos clients dans les domaines de la géologie, des mines et de la métallurgie.Qu'il s'agisse de rapports géologiques, de données d'exploration, de descriptions de processus métallurgiques ou de documents de gestion des ressources minérales, nous sommes en mesure de vous fournir des traductions précises qui garantissent une communication cohérente et précise de l'information dans le monde entier.",
    text3: 'Services consultatifs',
    text4: 'Caractéristiques',
    text5: "Expertise de l'industrie",
    text6:
      "Nous avons des traducteurs spécialisés dans les domaines de la géologie, des mines et de la métallurgie qui connaissent la terminologie de l'industrie et la connaissance du domaine, garantissant que le contenu traduit est exact et sans erreur.",
    text7: 'Traduction de rapports géologiques',
    text8:
      "Fournir des services de traduction tels que des rapports d'exploration géologique, des données de relevés géologiques, etc., assurant la cohérence et l'exactitude des informations géologiques dans différentes langues",
    text9: 'Traduction de procédés métallurgiques',
    text10:
      'Soutien à la traduction pour les descriptions de processus métallurgiques, les diagrammes de processus et plus encore, pour aider à communiquer vos informations de processus dans le monde entier',
    text11: 'Traduction gestion des ressources minérales',
    text12:
      'Fournir des services de traduction tels que des documents de gestion des ressources minérales, des contrats de droits miniers, etc. pour soutenir la gestion de projets miniers dans le monde entier',
    text13: 'Gestion terminologique',
    text14:
      "Gestion d'une base de données terminologiques dans le domaine de la métallurgie minière, assurant l'utilisation d'une terminologie cohérente de l'industrie dans les différentes versions linguistiques",
    text15: 'Valeur',
    text16: 'Coopération mondiale',
    text17:
      'Grâce à la traduction, vous pouvez partager des informations sur la métallurgie minérale avec des partenaires et des investisseurs internationaux et promouvoir la coopération et le développement.',
    text18: "Diffusion de l'industrie",
    text19:
      'Des traductions précises aident à diffuser des informations géologiques, minières et métallurgiques à leurs homologues du monde entier, facilitant ainsi le partage des connaissances.',
    text20: 'Gestion de projet',
    text21:
      'Fournir des traductions de documents de gestion des ressources minérales pour vous aider dans la gestion et la collaboration de projets miniers internationaux.',

    text22: 'Expansion du marché',
    text23:
      'Grâce à la traduction, vous pouvez promouvoir vos produits et technologies de métallurgie minière sur le marché mondial et attirer plus de clients potentiels.',
    text24: 'Avantages',
    text25: "Équipe professionnelle de l'industrie",
    text26:
      "Notre équipe est composée de traducteurs spécialisés dans le domaine de la métallurgie minière et est en mesure d'assurer la précision des traductions de l'industrie.",
    text27:
      "Nous gérons une base de données terminologiques dans le domaine de la métallurgie minière, garantissant l'utilisation d'une terminologie cohérente de l'industrie dans les différentes versions linguistiques.",
    text28: 'Soutien à la localisation',
    text29:
      'Prenez en compte les différences entre les contextes culturels et techniques des différentes régions et assurez - vous que le contenu traduit est adapté à chaque contexte.',
    text30: 'Accord de confidentialité',
    text31:
      'Lorsque nous traitons des informations techniques sensibles, nous veillons à la sécurité des informations en respectant strictement les accords de confidentialité.',
    text32: "Soutien d'urgence",
    text33:
      "Notre équipe est en mesure de fournir une réparation rapide et un soutien en cas d'urgence, en veillant à ce que votre message soit transmis en temps opportun.",
    text34:
      'Nous nous engageons à fournir un excellent support de traduction à nos clients dans le domaine de la métallurgie minière pour vos besoins de diffusion mondiale.',
    text35:
      "En choisissant nos solutions de traduction pour l'industrie de la métallurgie minière, vous bénéficiez d'un service professionnel, précis et confidentiel qui contribue à la diffusion réussie de vos informations géologiques, minières et métallurgiques dans le monde entier."
  },
  ru: {
    text1: 'Переводческие решения для металлургической промышленности',
    text2:
      'Наши решения по переводу для геометаллургической промышленности ориентированы на предоставление профессиональных услуг по переводу и локализации для клиентов в области геологии, горнодобывающей промышленности и металлургии.Независимо от того, является ли это геологическим отчетом, данными разведки, описанием металлургических процессов или документацией по управлению минеральными ресурсами, мы можем предоставить вам точный перевод, чтобы информация передавалась последовательно и точно по всему миру.',
    text3: 'Консультативные услуги',
    text4: 'Особенности',
    text5: 'Профессиональный опыт',
    text6:
      'У нас есть профессиональные переводчики в области геологии, горнодобывающей промышленности и металлургии, которые знают отраслевые термины и области знаний, чтобы обеспечить точный перевод',
    text7: 'Перевод геологических отчетов',
    text8:
      'Предоставление услуг по переводу геологоразведочных отчетов, геологоразведочных данных и т.д. для обеспечения согласованности и точности геологической информации на разных языках',
    text9: 'Металлургический перевод',
    text10:
      'Поддержка перевода для описания металлургического процесса, блок - схем и т. Д. Чтобы помочь вашей технологической информации передаваться по всему миру',
    text11: 'Управление минеральными ресурсами Перевод',
    text12:
      'Предоставление услуг по переводу документов по управлению минеральными ресурсами, контрактов на права на добычу полезных ископаемых и т.д. в поддержку глобального управления минеральными проектами',
    text13: 'Терминология',
    text14:
      'Управление терминологической базой данных в области геометаллургии для обеспечения последовательного использования отраслевых терминов в различных языковых версиях',
    text15: 'Стоимость',
    text16: 'Глобальное сотрудничество',
    text17:
      'Благодаря переводу вы можете делиться информацией о геометаллургии с международными партнерами и инвесторами, способствуя сотрудничеству и развитию.',
    text18: 'Промышленное распространение',
    text19:
      'Точный перевод помогает распространять геологическую, горнодобывающую и металлургическую информацию среди коллег по всему миру и облегчает обмен знаниями.',
    text20: 'Управление проектами',
    text21:
      'Предоставьте перевод документов по управлению минеральными ресурсами, чтобы помочь вам в управлении международными минеральными проектами и сотрудничестве.',

    text22: 'Расширение рынка',
    text23: 'Благодаря переводу вы можете продвигать свои геометаллургические продукты и технологии на мировые рынки, привлекая больше потенциальных клиентов.',
    text24: 'Преимущество',
    text25: 'Отраслевая профессиональная команда',
    text26: 'Наша команда состоит из профессиональных переводчиков в области геометаллургии, способных обеспечить точность отраслевых переводов.',
    text27:
      'Мы управляем терминологической базой данных в области геометаллургии, обеспечивая последовательное использование отраслевых терминов в различных языковых версиях.',
    text28: 'Поддержка локализации',
    text29:
      'Принимая во внимание различия в культурном и техническом контексте в разных регионах, убедитесь, что содержание перевода адаптировано к различным контекстам.',
    text30: 'Соглашение о конфиденциальности',
    text31:
      'При работе с конфиденциальной технической информацией мы строго соблюдаем соглашение о конфиденциальности и обеспечиваем информационную безопасность.',
    text32: 'Экстренная поддержка',
    text33: 'Наша команда может обеспечить быстрое восстановление и поддержку в чрезвычайных ситуациях, гарантируя своевременную передачу вашей информации.',
    text34:
      'Мы стремимся предоставить отличную переводческую поддержку клиентам в области геометаллургии, чтобы удовлетворить ваши глобальные коммуникационные потребности.',
    text35:
      'Выбрав наше решение для перевода в геометаллургической промышленности, вы получите профессиональные, точные и конфиденциальные услуги, которые помогут вам успешно распространять геологическую, горнодобывающую и металлургическую информацию по всему миру.'
  },
  th: {
    text1: 'โซลูชั่นการแปลสำหรับอุตสาหกรรมธรณีวิทยาและโลหะ',
    text2:
      'ข้อมูลการแปลที่ยอดเยี่ยมของเราโซลูชันการแปลอุตสาหกรรมธรณีวิทยาและโลหะวิทยามุ่งเน้นการให้บริการแปลภาษาระดับมืออาชีพและการแปลภาษาสำหรับลูกค้าในสาขาธรณีวิทยาเหมืองแร่และโลหะวิทยาไม่ว่าจะเป็นรายงานทางธรณีวิทยา ข้อมูลการสำรวจ คำอธิบายกระบวนการทางโลหิตวิทยา หรือเอกสารการจัดการทรัพยากรแร่ เราสามารถจัดหาคำแปลที่ถูกต้องแม่นยำ เพื่อให้มั่นใจว่าข้อมูลจะถูกสื่อสารอย่างสม่ำเสมอและถูกต้องทั่วโลก',
    text3: 'บริการให้คำปรึกษา',
    text4: 'คุณสมบัติ',
    text5: 'ความเชี่ยวชาญในอุตสาหกรรม',
    text6:
      'เรามีนักแปลมืออาชีพในสาขาธรณีวิทยาเหมืองแร่และโลหะวิทยาที่มีความรู้ความเข้าใจเกี่ยวกับคำศัพท์อุตสาหกรรมและสาขาเพื่อให้แน่ใจว่าเนื้อหาการแปลมีความถูกต้องและถูกต้อง',
    text7: 'แปลรายงานทางธรณีวิทยา',
    text8:
      'ให้บริการแปลเอกสาร เช่น รายงานการสำรวจทางธรณีวิทยา ข้อมูลการสำรวจทางธรณีวิทยา เพื่อให้มั่นใจว่าข้อมูลทางธรณีวิทยามีความสอดคล้องและแม่นยำในภาษาต่างๆ',
    text9: 'การแปลกระบวนการทางโลหะวิทยา',
    text10: 'การสนับสนุนการแปลสำหรับคำอธิบายกระบวนการทางโลหะแผนภูมิการไหลและอื่น ๆ เพื่อช่วยให้ข้อมูลกระบวนการของคุณสื่อสารไปทั่วโลก',
    text11: 'การแปลการจัดการทรัพยากรแร่',
    text12: 'ให้บริการแปลเอกสารการจัดการทรัพยากรแร่ สัญญาให้สิทธิแร่ ฯลฯ เพื่อสนับสนุนการจัดการโครงการแร่ทั่วโลก',
    text13: 'การจัดการคำศัพท์',
    text14: 'จัดการฐานข้อมูลคำศัพท์ในสาขาธรณีวิทยาและโลหกรรมเพื่อให้แน่ใจว่ามีการใช้คำศัพท์อุตสาหกรรมที่สอดคล้องกันในเวอร์ชันภาษาที่แตกต่างกัน',
    text15: 'ความคุ้มค่า',
    text16: 'ความร่วมมือระดับโลก',
    text17: 'ด้วยการแปลคุณสามารถแบ่งปันข้อมูลเกี่ยวกับธรณีวิทยาและโลหะวิทยากับคู่ค้าระหว่างประเทศและนักลงทุนเพื่อส่งเสริมความร่วมมือและการพัฒนา',
    text18: 'การสื่อสารอุตสาหกรรม',
    text19: 'การแปลที่ถูกต้องช่วยในการเผยแพร่ข้อมูลทางธรณีวิทยาเหมืองแร่และโลหะวิทยาไปยังเพื่อนร่วมงานทั่วโลกส่งเสริมการแบ่งปันความรู้',
    text20: 'การจัดการโครงการ',
    text21: 'ให้บริการแปลเอกสารการจัดการทรัพยากรแร่เพื่อช่วยเหลือคุณในการจัดการโครงการแร่ระหว่างประเทศและความร่วมมือ',

    text22: 'การขยายตลาด',
    text23: 'ด้วยการแปลคุณสามารถเผยแพร่ผลิตภัณฑ์และเทคโนโลยีโลหะวิทยาธรณีวิทยาของคุณไปยังตลาดโลกและดึงดูดลูกค้าที่มีศักยภาพมากขึ้น',
    text24: 'ข้อดี',
    text25: 'ทีมงานมืออาชีพในอุตสาหกรรม',
    text26: 'ทีมงานของเราประกอบด้วยนักแปลมืออาชีพในสาขาธรณีวิทยาและโลหกรรมซึ่งสามารถรับประกันความถูกต้องของการแปลในอุตสาหกรรม',
    text27: 'เราจัดการฐานข้อมูลคำศัพท์ในสาขาธรณีวิทยาและโลหกรรมเพื่อให้แน่ใจว่ามีการใช้คำศัพท์อุตสาหกรรมที่สอดคล้องกันในเวอร์ชันภาษาที่แตกต่างกัน',
    text28: 'การสนับสนุนการแปล',
    text29: 'พิจารณาความแตกต่างของภูมิหลังทางวัฒนธรรมและเทคโนโลยีของภูมิภาคต่าง ๆ เพื่อให้แน่ใจว่าเนื้อหาที่แปลจะปรับให้เข้ากับบริบทที่หลากหลาย',
    text30: 'ข้อตกลงการรักษาความลับ',
    text31: 'เมื่อจัดการกับข้อมูลทางเทคนิคที่ละเอียดอ่อนเราปฏิบัติตามข้อตกลงการรักษาความลับอย่างเคร่งครัดเพื่อความปลอดภัยของข้อมูล',
    text32: 'การสนับสนุนฉุกเฉิน',
    text33: 'ทีมงานของเราสามารถให้การแก้ไขและการสนับสนุนที่รวดเร็วในกรณีฉุกเฉินเพื่อให้แน่ใจว่าข้อความของคุณได้รับการสื่อสารอย่างทันท่วงที',
    text34: 'เรามุ่งมั่นที่จะให้การสนับสนุนการแปลที่ยอดเยี่ยมแก่ลูกค้าในสาขาธรณีวิทยาและโลหะวิทยาเพื่อตอบสนองความต้องการด้านการสื่อสารทั่วโลกของคุณ',
    text35:
      'ด้วยการเลือกโซลูชันการแปลอุตสาหกรรมธรณีวิทยาและโลหะวิทยาของเราคุณจะได้รับบริการที่เป็นมืออาชีพถูกต้องและเป็นความลับซึ่งจะช่วยให้ข้อมูลทางธรณีวิทยาเหมืองแร่และโลหะวิทยาของคุณประสบความสำเร็จในการเผยแพร่ไปทั่วโลก'
  },
  es: {
    text1: 'Soluciones de traducción para la industria geológica, minera y metalúrgica',
    text2:
      'Nuestras soluciones de traducción de la industria geológica, minera y metalúrgica de Youyi Information se centran en proporcionar servicios profesionales de traducción y localización a clientes en los campos de la geología, la minería y la metalurgia.Ya sea informes geológicos, datos de exploración, instrucciones de procesos metalúrgicos o documentos de gestión de recursos minerales, podemos proporcionarle una traducción precisa para garantizar que la información se transmita de manera coherente y precisa en todo el mundo.',
    text3: 'Servicios de consultoría',
    text4: 'Características',
    text5: 'Experiencia en la industria',
    text6:
      'Tenemos traductores profesionales en los campos de la geología, la minería y la metalurgia, entendemos la terminología de la industria y el conocimiento del campo, y garantizamos que el contenido de la traducción sea preciso.',
    text7: 'Traducción del informe geológico',
    text8:
      'Proporcionar servicios de traducción como informes de exploración geológica y datos de estudios geológicos para garantizar la coherencia y precisión de la información geológica en diferentes idiomas.',
    text9: 'Traducción de procesos metalúrgicos',
    text10:
      'Soporte de traducción para instrucciones de proceso metalúrgico, diagrama de flujo, etc., para ayudar a transmitir su información de proceso en todo el mundo',
    text11: 'Traducción de gestión de recursos minerales',
    text12:
      'Proporcionar servicios de traducción como documentos de gestión de recursos minerales y contratos de derechos mineros para apoyar la gestión global de proyectos minerales',
    text13: 'Gestión de la terminología',
    text14:
      'Gestionar una base de datos de términos en el campo de la geología, minería y metalurgia para garantizar el uso de términos industriales consistentes en diferentes versiones lingüísticas',
    text15: 'Valor',
    text16: 'Cooperación Mundial',
    text17:
      'A través de la traducción, puede compartir información geológica, minera y metalúrgica con socios e inversores internacionales para promover la cooperación y el desarrollo.',
    text18: 'Comunicación de la industria',
    text19:
      'La traducción precisa ayuda a difundir información geológica, minera y metalúrgica a sus homólogos mundiales y promueve el intercambio de conocimientos.',
    text20: 'Gestión de proyectos',
    text21:
      'Proporcionar traducción de documentos de gestión de recursos minerales para ayudarle a gestionar y cooperar en proyectos minerales internacionales.',

    text22: 'Expansión del mercado',
    text23:
      'A través de la traducción, puede extender sus productos y tecnologías geomineras y metalúrgicas al mercado global para atraer a más clientes potenciales.',
    text24: 'Ventaja',
    text25: 'Equipo profesional de la industria',
    text26:
      'Nuestro equipo está compuesto por traductores profesionales en el campo de la geología, la minería y la metalurgia, que pueden garantizar la precisión de la traducción de la industria.',
    text27:
      'Gestionamos una base de datos de términos en el campo de la geología, la minería y la metalurgia para garantizar el uso de términos industriales consistentes en diferentes versiones lingüísticas.',
    text28: 'Soporte localizado',
    text29:
      'Considerar las diferencias en los antecedentes culturales y técnicos de las diferentes regiones para garantizar que el contenido de la traducción se adapte a los diversos antecedentes.',
    text30: 'Acuerdo de confidencialidad',
    text31:
      'Al procesar información técnica sensible, cumplimos estrictamente con los protocolos de confidencialidad para garantizar la seguridad de la información.',
    text32: 'Apoyo urgente',
    text33: 'Nuestro equipo puede proporcionar reparaciones y apoyo rápidos en situaciones de emergencia para garantizar que su mensaje se transmita a tiempo.',
    text34:
      'Nos comprometemos a proporcionar un excelente apoyo de traducción a nuestros clientes en el campo de la geología, la minería y la metalurgia para satisfacer sus necesidades de comunicación global.',
    text35:
      'Al elegir nuestras soluciones de traducción para la industria geológica, minera y metalúrgica, obtendrá servicios profesionales, precisos y confidenciales que ayudarán a que su información geológica, minera y metalúrgica se difunda con éxito en todo el mundo.'
  },
  pt: {
    text1: 'Soluções de tradução para a indústria de mineração e metalurgia',
    text2:
      'Nossa solução de tradução Youyi Information Mining and Metalurgy Industry concentra-se em fornecer serviços profissionais de tradução e localização para clientes nos campos geológico, mineiro e metalúrgico.Seja relatórios geológicos, dados de exploração, descrições de processos metalúrgicos ou documentos de gestão de recursos minerais, podemos fornecer traduções precisas para garantir que as informações sejam comunicadas de forma consistente e precisa em escala global.',
    text3: 'serviço de consultoria',
    text4: 'característica',
    text5: 'Peritos especializados na indústria',
    text6:
      'Temos tradutores profissionais nas áreas de geologia, mineração e metalurgia, que entendem terminologia da indústria e conhecimento de domínio para garantir a precisão do conteúdo de tradução',
    text7: 'Tradução de relatórios geológicos',
    text8:
      'Fornecer serviços de tradução, como relatórios de exploração geológica e dados de levantamento geológico, para garantir a consistência e precisão das informações geológicas em diferentes idiomas',
    text9: 'Tradução de Processos Metalúrgicos',
    text10:
      'Fornecer suporte de tradução para instruções de processo metalúrgico, fluxogramas, etc., para ajudar suas informações de processo a serem comunicadas globalmente',
    text11: 'Tradução de Gestão de Recursos Minerais',
    text12:
      'Fornecer serviços de tradução para documentos de gestão de recursos minerais, contratos de direitos de mineração, etc., para apoiar a gestão global de projetos minerais',
    text13: 'Gestão de prazos',
    text14:
      'Gerencie o banco de dados terminológicos no campo de geologia, mineração e metalurgia para garantir o uso consistente da terminologia do setor em diferentes versões linguísticas',
    text15: 'valor',
    text16: 'Cooperação global',
    text17:
      'Através da tradução, você pode compartilhar informações geológicas e metalúrgicas com parceiros internacionais e investidores, promovendo cooperação e desenvolvimento.',
    text18: 'Comunicação industrial',
    text19:
      'A tradução precisa ajuda a disseminar informações geológicas, mineradoras e metalúrgicas para pares globais e promove o compartilhamento de conhecimento.',
    text20: 'gestão de projectos',
    text21: 'Fornecer tradução de documentos de gestão de recursos minerais para ajudá-lo na gestão de projetos minerais internacionais e cooperação.',

    text22: 'Expansão do mercado',
    text23:
      'Através da tradução, você pode promover seus produtos e tecnologias geológicos e metalúrgicos para o mercado global, atraindo mais clientes potenciais.',
    text24: 'vantagem',
    text25: 'Equipa Profissional da Indústria',
    text26:
      'Nossa equipe é composta por tradutores profissionais nas áreas de geologia, mineração e metalurgia, garantindo a precisão das traduções da indústria.',
    text27:
      'Gerenciamos um banco de dados terminológicos no campo de geologia, mineração e metalurgia para garantir o uso consistente da terminologia do setor em diferentes versões linguísticas.',
    text28: 'Suporte à localização',
    text29:
      'Considere as diferenças de origens culturais e tecnológicas entre as diferentes regiões para garantir que o conteúdo traduzido se adapte a várias origens.',
    text30: 'Acordo de confidencialidade',
    text31: 'Ao lidar com informações técnicas sensíveis, aderimos estritamente a acordos de confidencialidade para garantir a segurança da informação.',
    text32: 'Apoio de emergência',
    text33: 'Nossa equipe é capaz de fornecer reparos rápidos e suporte em situações de emergência, garantindo a comunicação oportuna de suas informações.',
    text34:
      'Estamos comprometidos em fornecer excelente suporte de tradução para clientes no campo da geologia, mineração e metalurgia para atender às suas necessidades de comunicação global.',
    text35:
      'Ao escolher nossa solução de tradução para a indústria geológica, mineira e metalúrgica, você receberá serviços profissionais, precisos e altamente confidenciais que ajudam sua informação geológica, mineira e metalúrgica a ser disseminada globalmente com sucesso.'
  }
}
