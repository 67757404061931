import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.symbol.description.js";
import { computed, ref, provide, nextTick, defineComponent } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import { CommonStore } from '@/store/modules/common';
import { ApiConfig } from '@/utils/apiConfig';
import { useI18n } from 'vue-i18n';
import { loadScript, removeScript } from '@/utils/util';
import Socket from '@/utils/socket';
import zhCN from 'element-plus/dist/locale/zh-cn.mjs';
import en from 'element-plus/dist/locale/en.mjs';
import ja from 'element-plus/dist/locale/ja.mjs';
import ko from 'element-plus/dist/locale/ko.mjs';
import de from 'element-plus/dist/locale/de.mjs';
import fr from 'element-plus/dist/locale/fr.mjs';
import ru from 'element-plus/dist/locale/ru.mjs';
import th from 'element-plus/dist/locale/th.mjs';
import es from 'element-plus/dist/locale/es.mjs';
import pt from 'element-plus/dist/locale/pt.mjs';
const langs = {
  'zh-cn': zhCN,
  en,
  ja,
  ko,
  de,
  fr,
  'ru-RU': ru,
  th,
  es,
  pt
};
const localLang = localStorage.getItem('languageKey') || 'zh-cn';
export default defineComponent({
  metaInfo() {
    var _common$metaInfo, _common$metaInfo2, _common$metaInfo3;
    const common = CommonStore();
    const isMeta = common.metaInfo ? JSON.stringify(common.metaInfo) != '{}' : false;
    return isMeta ? {
      //改变当前路由的title
      title: ((_common$metaInfo = common.metaInfo) === null || _common$metaInfo === void 0 ? void 0 : _common$metaInfo.title) || '',
      //改变当前路由的link
      meta: [{
        name: 'keywords',
        content: ((_common$metaInfo2 = common.metaInfo) === null || _common$metaInfo2 === void 0 ? void 0 : _common$metaInfo2.keywords) || ''
      }, {
        name: 'description',
        content: ((_common$metaInfo3 = common.metaInfo) === null || _common$metaInfo3 === void 0 ? void 0 : _common$metaInfo3.description) || ''
      }]
    } : {};
  },
  setup() {
    const common = CommonStore();
    const route = useRoute();
    const {
      locale
    } = useI18n();
    const currentLang = ref(langs[localLang]);

    // 切换语言
    const changeLang = key => {
      locale['value'] = key;
      currentLang['value'] = langs[key];
      localStorage.setItem('languageKey', key);
    };
    provide('changeLang', changeLang);
    const initSys = computed(() => {
      return common.getInitSys;
    });
    return {
      route,
      currentLang,
      initSys
    };
  },
  mounted() {
    // 创建Socket连接
    if (window.env) {
      window.$Socket = new Socket({
        url: ApiConfig[window.env]['OrderPortalSocket']
      });
    }
    const debounce = (fn, delay) => {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    };

    // 解决ResizeObserver loop limit exceeded报错
    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    };

    // setTimeout(() => {
    //   if (this.route.name) {
    //     // 动态加载咨询框
    //     loadScript('https://hm.baidu.com/hm.js?891c0a2123fb3f82caeeeb6483f11701')
    //   } else {
    //     // 子应用删除咨询框
    //     removeScript('https://hm.baidu.com/hm.js?891c0a2123fb3f82caeeeb6483f11701')
    //   }
    // }, 3000)
  }
});