import Home from './home/index.vue'

// 百度路由列表
export const Router = [
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      title: 'menu.text4',
      hidden: false,
      metaInfo: {
        title: '优译网_专业多语种翻译 | 人工翻译 | 文档翻译 | 图纸翻译 | 本地化翻译',
        keywords: '人工翻译，在线翻译，英文翻译，专业翻译，文档翻译，音视频翻译，本地化翻译，医药翻译，图纸翻译，网页翻译，机辅翻译，机器翻译',
        description:
          '为用户提供多语种翻译、审校、质检、排版、盖章及润色服务，支持文档、图纸等多格式，涵盖游戏、医药等多领域，流程一体化，质量有保障，服务更放心。'
      }
    }
  }
]
