import { defineStore } from 'pinia'

type State = {
  initSys: boolean
  socketId: string
  soloModuleName: string
  metaInfo: { title?: string; keywords?: string; description?: string }
  registerPhone: number | string
}

export const CommonStore = defineStore('common', {
  // 状态库
  state: (): State => {
    return {
      initSys: true, // 初始化系统
      socketId: '', // 全局socketId
      soloModuleName: '', // 单独显示导航模块
      metaInfo: {},
      registerPhone: '' // 注册手机号
    }
  },
  getters: {
    getInitSys: (state) => state.initSys,
    getSocketId: (state) => state.socketId,
    getSoloModuleName: (state) => state.soloModuleName,
    getRegisterPhone: (state) => state.registerPhone
  },
  actions: {
    setInitSys() {
      this.initSys = false
      setTimeout(() => {
        this.initSys = true
      }, 0)
    },
    setMetaInfo(data: any) {
      this.metaInfo = data
    },
    setRegisterPhone(data: any) {
      this.registerPhone = data
    },
    // 获取初始化Scoket数据
    initScoket(data: { code: number; result: { socketId: string } }) {
      const { code, result } = data
      if (code == 200) {
        this.socketId = result?.socketId
        window.socketId = result?.socketId
      }
    },
    // 单独显示导航名
    setSoloModuleName(name: string) {
      this.soloModuleName = name
    }
  }
})
