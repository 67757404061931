export default {
  zh: {
    text1: '登录',
    text2: '注册',
    text3: '找回密码',
    text4: '首页',
    text5: '解决方案',
    text6: '医药行业翻译解决方案',
    text7: '人工智能行业翻译解决方案',
    text8: '地矿冶金行业翻译解决方案',
    text9: '成功案例',
    text10: '海创药业股份有限公司',
    text11: '关于我们',
    text12: '软件本地化解决方案',
    text13: '订单中心',
    text14: '我的钱包',
    text15: '账号安全',
    text16: '退出',
    text17: '会员条款',
    text18: '服务价格',
    text19: '北京智齿科技有限公司',
    text20: '医疗医药翻译',
    text21: '软件游戏本地化',
    text22: '电子科大出版社 - 图书翻译',
    text23: '行业解决方案',
    text24: '行业成功案例',
    text25: '海创药业股份有限公司 - 医药翻译',
    text26: '北京智齿科技 - 软件本地化',
    text27: '上海极塔客 - 本地化',
    text28: '阿里影业大麦网 - 本地化',
    text29: 'Ienglish - 本地化',
    text30: '浙江中车 - 新能源',
    text31: '汇宇制药 - 医药翻译',
    text32: '快手《信任经济》',
    text33: '快手《被看见的力量》',
    text34: '快手《直播时代》',
  },
  en: {
    text1: 'login',
    text2: 'register',
    text3: 'Retrieve password',
    text4: 'home page',
    text5: 'Solution',
    text6: 'Translation solutions for the pharmaceutical industry',
    text7: 'Translation solutions for the artificial intelligence industry',
    text8: 'Translation Solutions for the Mining and Metallurgy Industry',
    text9: 'success cases',
    text10: 'Haichuang Pharmaceutical Co., Ltd',
    text11: 'About us',
    text12: 'Software Localization Solution',
    text13: 'Order Center',
    text14: 'My wallet',
    text15: 'Account security',
    text16: 'quit',
    text17: 'Membership Terms',
    text18: 'Service Price',
    text19: 'Beijing Zhichi Technology Co., Ltd.',
    text20: 'Medical translation',
    text21: 'Localization of software games',
    text22: 'University of Electronic Science and Technology Press - Book Translation',
    text23: 'Industry Solutions',
    text24: 'Industry Success Cases',
    text25: 'Haichuang Pharmaceutical Co., Ltd. - Pharmaceutical Translation',
    text26: 'Beijing Zhichi Technology - Software Localization',
  },
  ja: {
    text1: 'ログイン＃ログイン＃',
    text2: '登録',
    text3: 'パスワードを取り戻す',
    text4: 'トップページ',
    text5: 'ソリューション',
    text6: '医薬業界翻訳ソリューション',
    text7: '人工知能業界翻訳ソリューション',
    text8: '地鉱冶金業界翻訳ソリューション',
    text9: '成功事例',
    text10: '海創薬業株式会社',
    text11: '私たちについて',
    text12: 'ソフトウェアのローカライズソリューション',
    text13: '受注センター',
    text14: '私の財布',
    text15: 'アカウントセキュリティ',
    text16: '終了',
    text17: '会員規約',
    text18: 'サービス価格',
    text19: '北京智歯科技有限公司',
    text20: '医薬翻訳',
    text21: 'ソフトウェアゲームのローカライズ',
    text22: '電子科学大出版社-図書翻訳',
    text23: '業界ソリューション',
    text24: '業界の成功事例',
    text25: '海創薬業株式会社-医薬翻訳',
    text26: '北京智歯科学技術-ソフトウェアローカライズ',
  },
  ko: {
    text1: '로그인',
    text2: '등록',
    text3: '비밀번호 찾기',
    text4: '첫 페이지',
    text5: '솔루션',
    text6: '의약업계 번역 솔루션',
    text7: '인공지능 업계 번역 솔루션',
    text8: '지광 야금 업계 번역 솔루션',
    text9: '성공 사례',
    text10: '해창약업주식유한회사',
    text11: '우리에 대해',
    text12: '소프트웨어 현지화 솔루션',
    text13: '주문 센터',
    text14: '내 지갑',
    text15: '계정 보안',
    text16: '종료',
    text17: '회원 약관',
    text18: '서비스 가격',
    text19: '북경사랑치과학기술유한공사',
    text20: '의약번역',
    text21: '소프트웨어 게임 현지화',
    text22: '전자과대 출판사-도서번역',
    text23: '업계 솔루션',
    text24: '업계 성공 사례',
    text25: '해창약업주식유한공사-의약번역',
    text26: '베이징 사랑니 과학기술-소프트웨어 현지화',
  },
  de: {
    text1: 'Login',
    text2: 'registrieren',
    text3: 'Passwort abrufen',
    text4: 'Startseite',
    text5: 'Lösung',
    text6: 'Übersetzungslösungen für die Pharmaindustrie',
    text7: 'Übersetzungslösungen für die Künstliche Intelligenz',
    text8: 'Übersetzungslösungen für den Bergbau und die Metallurgie',
    text9: 'Erfolgsfälle',
    text10: 'Haichuang Pharmaceutical Co., Ltd.',
    text11: 'Über uns',
    text12: 'Softwarelokalisierungslösung',
    text13: 'Bestellcenter',
    text14: 'Meine Brieftasche',
    text15: 'Kontosicherheit',
    text16: 'Beenden',
    text17: 'Mitgliedschaftsbedingungen',
    text18: 'Servicepreis',
    text19: 'Beijing Zhichi Technology Co., Ltd.',
    text20: 'Medizinische Übersetzung',
    text21: 'Lokalisierung von Softwarespielen',
    text22: 'University of Electronic Science and Technology Press-Book Translation',
    text23: 'Industry Solutions',
    text24: 'Industry Success Cases',
    text25: 'Haichuang Pharmaceutical Co., Ltd ĎPharmaceutical Translation',
    text26: 'Beijing Zhichi Technology with Software Localization',
  },
  fr: {
    text1: 'Se connecter',
    text2: 'Enregistrement',
    text3: 'Récupérer le mot de passe',
    text4: 'Accueil',
    text5: 'La solution',
    text6: "Solutions de traduction pour l'industrie pharmaceutique",
    text7: "Solutions de traduction pour l'industrie de l'intelligence artificielle",
    text8: 'Solutions de traduction pour le secteur de la métallurgie minière',
    text9: 'Cas de réussite',
    text10: 'Haijung Pharmaceutical Co., Ltd',
    text11: 'À propos de nous',
    text12: 'Solutions de localisation de logiciels',
    text13: 'Centre de commande',
    text14: 'Mon portefeuille',
    text15: 'Numéro de compte sécurité',
    text16: 'Quitter',
    text17: "conditions d'adhésion",
    text18: 'prix des services',
    text19: 'Beijing Zhitong Technology Co., Ltd.',
    text20: 'traduction médicale',
    text21: 'localisation de jeux logiciels',
    text22: "grande maison d'édition de la section Electronique - traduction de livres",
    text23: 'solutions pour l’industrie',
    text24: "Histoires de réussite de l'industrie",
    text25: 'Heineken Pharmaceutical GmbH - traduction pharmaceutique',
    text26: 'technologie des dents de sagesse de Beijing - localisation de logiciels',
  },
  ru: {
    text1: 'Регистрация',
    text2: 'Регистрация',
    text3: 'Вернуть пароль',
    text4: 'Главная страница',
    text5: 'Решения',
    text6: 'Переводческие решения для фармацевтической промышленности',
    text7: 'Решения для перевода в индустрии искусственного интеллекта',
    text8: 'Переводческие решения для металлургической промышленности',
    text9: 'Успешные случаи',
    text10: 'Хайчхон Фармацевтическая компания с ограниченной ответственностью',
    text11: 'О нас',
    text12: 'Локализация программного обеспечения',
    text13: 'Центр заказов',
    text14: 'Мой бумажник.',
    text15: 'Счет в безопасности',
    text16: 'Выход',
    text17: 'Условия членства',
    text18: 'Цена услуги',
    text19: 'Beijing Zizuo Technology Co., Ltd.',
    text20: 'Фармацевтический перевод',
    text21: 'Локализация программных игр',
    text22: 'Электронный научно - исследовательский институт Издательство - Перевод книг',
    text23: 'Отраслевые решения',
    text24: 'Случай успеха отрасли',
    text25: 'Хайчхон фармацевтика акционерное общество - фармацевтический перевод',
    text26: 'Beijing Intelligent Technology - локализация программного обеспечения',
  },
  th: {
    text1: 'เข้าสู่ระบบ',
    text2: 'ลงทะเบียน',
    text3: 'กู้คืนรหัสผ่าน',
    text4: 'หน้าหลัก',
    text5: 'วิธีการแก้ปัญหา',
    text6: 'โซลูชั่นการแปลสำหรับอุตสาหกรรมยา',
    text7: 'โซลูชั่นการแปลอุตสาหกรรมปัญญาประดิษฐ์',
    text8: 'โซลูชั่นการแปลสำหรับอุตสาหกรรมธรณีวิทยาและโลหะ',
    text9: 'เรื่องราวความสำเร็จ',
    text10: 'บริษัท ไฮชวง ฟาร์มาซูติคอล จำกัด (มหาชน)',
    text11: 'เกี่ยวกับเรา',
    text12: 'โซลูชันการแปลซอฟต์แวร์',
    text13: 'ศูนย์สั่งซื้อ',
    text14: 'กระเป๋าสตางค์ของฉัน',
    text15: 'ความปลอดภัยของบัญชี',
    text16: 'ออกจาก',
    text17: 'เงื่อนไขการเป็นสมาชิก',
    text18: 'ราคาบริการ',
    text19: 'ปักกิ่งฟันคุดเทคโนโลยี จำกัด',
    text20: 'การแปลยา',
    text21: 'การแปลเกมซอฟต์แวร์',
    text22: 'สำนักพิมพ์ใหญ่อิเล็กทรอนิกส์ - การแปลหนังสือ',
    text23: 'โซลูชันอุตสาหกรรม',
    text24: 'เรื่องราวความสำเร็จของอุตสาหกรรม',
    text25: 'บริษัท ไฮชวง ฟาร์มาซูติคอล จำกัด',
    text26: 'เทคโนโลยีฟันคุดปักกิ่ง - การแปลซอฟต์แวร์',
  },
  es: {
    text1: 'Iniciar sesión',
    text2: 'Registro',
    text3: 'Recuperar la contraseña',
    text4: 'Página de inicio',
    text5: 'Solución',
    text6: 'Soluciones de traducción para la industria farmacéutica',
    text7: 'Soluciones de traducción para la industria de inteligencia artificial',
    text8: 'Soluciones de traducción para la industria geológica, minera y metalúrgica',
    text9: 'Casos de éxito',
    text10: 'Haichuang Pharmaceutical co., Ltd.',
    text11: 'Sobre nosotros',
    text12: 'Soluciones de localización de software',
    text13: 'Centro de pedidos',
    text14: 'Mi billetera',
    text15: 'Seguridad de la cuenta',
    text16: 'Salida',
    text17: 'cláusula de membresía',
    text18: 'precio del servicio',
    text19: 'beijing Intelligent Tooth Technology co., ltd.',
    text20: 'traducción médica',
    text21: 'localización de juegos de software',
    text22: 'editorial de la Universidad de Ciencia y tecnología electrónica - traducción de libros',
    text23: 'soluciones de la industria',
    text24: 'casos de éxito de la industria',
    text25: 'haichuang Pharmaceutical co., Ltd. - Traducción farmacéutica',
    text26: 'beijing Intelligent Tooth Technology - localización de software',
  },
  pt: {
    text1: 'Login',
    text2: 'registo',
    text3: 'Obter a senha',
    text4: 'Página inicial',
    text5: 'Solução',
    text6: 'Soluções de tradução para a indústria farmacêutica',
    text7: 'Soluções de tradução para a indústria de inteligência artificial',
    text8: 'Soluções de tradução para a indústria de mineração e metalurgia',
    text9: 'casos de sucesso',
    text10: 'Haichuang Pharmaceutical Co., Ltd.',
    text11: 'Sobre nós',
    text12: 'Solução de localização de software',
    text13: 'Centro de Pedidos',
    text14: 'A minha carteira',
    text15: 'Segurança da conta',
    text16: 'sair',
    text17: 'Condições de adesão',
    text18: 'Preço de serviço',
    text19: 'Beijing Zhichi Technology Co., Ltd.',
    text20: 'Tradução médica',
    text21: 'Localização de jogos informáticos',
    text22: 'University of Electronic Science and Technology Press - Tradução de Livros',
    text23: 'Soluções industriais',
    text24: 'Casos de sucesso industrial',
    text25: 'Haichuang Pharmaceutical Co., Ltd. - Pharmaceutical Translation',
    text26: 'Beijing Zhichi Technology - Localização de Software',
  }
}
